import * as React from "react";

const EmailIcon = (props) => (
  <svg
    width={16} // Updated width
    height={16} // Updated height
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 8.21938C1.99976 8.22971 1.99976 8.24003 2 8.25033V16.4706C2 18.4198 3.67893 20 5.75 20H18.25C20.3211 20 22 18.4198 22 16.4706V8.25033C22.0002 8.24003 22.0002 8.22971 22 8.21938V7.52941C22 5.58017 20.3211 4 18.25 4H5.75C3.67893 4 2 5.58017 2 7.52941V8.21938ZM3.5 7.52941C3.5 6.35987 4.50736 5.41176 5.75 5.41176H18.25C19.4926 5.41176 20.5 6.35987 20.5 7.52941V7.78278L12.5219 11.2325C12.3582 11.3032 12.1802 11.3399 12 11.3399C11.8198 11.3399 11.6418 11.3033 11.4781 11.2325L3.5 7.78278V7.52941ZM20.5 9.3364V16.4706C20.5 17.6401 19.4926 18.5882 18.25 18.5882H5.75C4.50736 18.5882 3.5 17.6401 3.5 16.4706V9.3364L10.8519 12.5153C11.212 12.671 11.6037 12.7517 12 12.7517C12.3963 12.7517 12.788 12.671 13.1481 12.5153L20.5 9.3364Z"
      fill={props.color ?? "#3A3A41"}
    />
  </svg>
);

export default EmailIcon;