import SortIcon from "../../../global/components/SortIcon";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";

export const OrderItemColumn = (setParams, params, setIsModalOpen, setId) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "ItemNo",
      key: "ItemNo",
      sorter: (a, b) => a?.ItemNo - b?.ItemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="ItemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "ItemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      hidden: [4, 5, 6, 7, 8, 9, 10, 11, 12].includes(loggedInUser.role_value),
      sorter: (a, b) => a?.OemNo - b?.OemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerNo",
      key: "DealerNo",
      hidden: [7, 8, 9, 10, 11, 12].includes(loggedInUser.role_value),
      sorter: (a, b) => a?.DealerNo - b?.DealerNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    // {
    //   title: "Account",
    //   dataIndex: "AccountNo",
    //   key: "AccountNo",
    //   hidden: loggedInUser.role_name == "DealerUser",
    //   sorter: (a, b) => a?.AccountNo?.length - b?.AccountNo?.length,
    //   sortIcon: () => (
    //     <SortIcon
    //       sortOrder={params.order}
    //       columnKey="AccountNo"
    //       sortBy={params.sort_by}
    //     />
    //   ),
    //   onHeaderCell: () => ({
    //     onClick: () =>
    //       setParams((prev) => ({
    //         ...prev,
    //         sort_by: "AccountNo",
    //         order: prev.order === "desc" ? "asc" : "desc",
    //       })),
    //   }),
    // },
    {
      title: "Order",
      dataIndex: "OrderName",
      key: "OrderName",
      sorter: (a, b) => a?.OrderName?.length - b?.OrderName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Confirmed",
      dataIndex: "Paid",
      key: "Paid",
      sorter: (a, b) => a?.Paid?.length - b?.Paid?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Paid"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Paid",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Order Time",
      dataIndex: "OrderTime",
      key: "OrderTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.OrderTime) - new Date(b.OrderTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OrderTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OrderTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },

    {
      title: "Customer",
      dataIndex: "CustomerNo",
      key: "CustomerNo",
      hidden: [10, 11, 12].includes(loggedInUser.role_value),
      sorter: (a, b) => a?.CustomerNo?.length - b?.CustomerNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License",
      dataIndex: "LicenseNo",
      key: "LicenseNo",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              setIsModalOpen(true);
              setId(record?.ItemNo);
            }}
            className="text-customColor text-[14px] font-[Poppins] hover:underline"
          >
            {text}
          </a>
        );
      },
      sorter: (a, b) => a?.LicenseNo?.length - b?.LicenseNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      render: (text) => {
        return `$ ${text}`;
      },
      sorter: (a, b) => a?.Price?.length - b?.Price?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Price"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Price",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used",
      dataIndex: "Used",
      key: "Used",
      sorter: (a, b) => a?.Used?.length - b?.Used?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Used"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Used",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used MAC",
      dataIndex: "UsedMac",
      key: "UsedMac",
      sorter: (a, b) => a?.UsedMac?.length - b?.UsedMac?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UsedMac"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UsedMac",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Used Time",
      dataIndex: "UsedTime",
      key: "UsedTime",
      render: (text) => <div>{text ? formatUnixTime(text) : ""}</div>,
      sorter: (a, b) => new Date(a.UsedTime) - new Date(b.UsedTime),
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UsedTime"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UsedTime",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",
    },
  ];
};
