import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OemInfoColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "OemInfoNo",
      key: "OemInfoNo",
      sorter: (a, b) => a?.OemInfoNo - b?.OemInfoNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OemInfoName",
      key: "OemInfoName",
      sorter: (a, b) => a?.OemInfoName - b?.OemInfoName,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OemInfoDesc",
      key: "OemInfoDesc",
      sorter: (a, b) => a?.OemInfoDesc?.length - b?.OemInfoDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="absolute top-2 right-5">
            <div className=" duration-75 bottom-0 flex gap-2 items-center justify-center z-50">
              <div className="hover:bg-gray-400 px-[5px] py-[5px] rounded-[4px] mr-1 ">
                <Link
                  to={`/oeminfo/edit/${record?.OemInfoNo}`}
                  onClick={(e) => e.stopPropagation()}
                  className=" hover:text-[#636262ee]"
                >
                  <EditOutlined />
                </Link>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                  setSelectedRowKeys([record?.OemInfoNo]);
                }}
                className="cursor-pointer hover:bg-gray-400 px-[5px] py-[5px] rounded-[4px] "
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ) : null,
    },
  ];
};
