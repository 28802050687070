import { useNavigate } from "react-router-dom";
import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "../styles/breadcrumbs.css";
import Home from "../../assets/images/vectors/HomeIcon";

const CommonBreadcrumb = ({ breadcrumbItems, children }) => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { role_value } = userData;
  const handleClick = () => {
    if ([1, 2, 3].includes(role_value)) {
      navigate("/devicelist");
    } else if ([4, 5, 6, 7, 8, 9].includes(role_value)) {
      navigate("/orderlist");
    } else if ([10, 11, 12].includes(role_value)) {
      return;
    } else {
      navigate("/");
    }
  };

  console.log(children);

  return (
    <div
      className={`flex items-center justify-between ${
        children ? "overflow-x-auto" : ""
      } space-x-8`}
    >
      <Breadcrumb
        className="mt-2 flex-shrink-0"
        separator={
          <span className="font-bold rotate-90 inline-block "> | </span>
        }
      >
        <Home className="home-icon" onClick={handleClick} />
        <span className="font-bold text-[#000000] rotate-90 inline-block mx-4">
          {" "}
          |{" "}
        </span>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={item.href} className="breadcrumb-link">
              {item.label}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div className="flex items-center whitespace-nowrap">{children}</div>
    </div>
  );
};

export default CommonBreadcrumb;
