// import { useState, useEffect, useCallback } from "react";
// import axios from "axios";

// const useGet = (url, params) => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [trigger, setTrigger] = useState(0);

//   const token = JSON.parse(sessionStorage.getItem("userData"));
//   const access_token = token ? token.access_token : null;

//   const fetchData = useCallback(async () => {
//     if (!url) {
//       // Skip fetching if there's no URL
//       return;
//     }
//     try {
//       setLoading(true);
//       const response = await axios.get(url, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${access_token}`,
//         },
//         params,
//       });
//       setData(response?.data);
//     } catch (err) {
//       setError(err);
//     } finally {
//       setLoading(false);
//     }
//   }, [url, params]);

//   useEffect(() => {
//     if (url) {
//       fetchData();
//     } else {
//       return;
//     }
//   }, [url, fetchData, trigger]);

//   const refetch = () => setTrigger((prev) => prev + 1);

//   return { data, loading, error, refetch };
// };

// export default useGet;
import useSWR from "swr";
import axios from "axios";

const fetcher = async (url, access_token, params) => {
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    params,
  });
  return response.data;
};

const useGet = (url, params) => {
  const token = JSON.parse(sessionStorage.getItem("userData"));
  const access_token = token ? token.access_token : null;

  const { data, error, isLoading, mutate } = useSWR(
    url ? [url, access_token, params] : null,
    ([url, access_token, params]) => fetcher(url, access_token, params),
    {
      revalidateOnFocus: false, // Avoids refetching on tab switch
      revalidateOnReconnect: true, // Refetch on network reconnect
    }
  );

  return {
    data,
    loading: isLoading,
    error,
    refetch: mutate, // SWR uses `mutate()` to refetch
  };
};

export default useGet;
