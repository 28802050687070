import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Menu, Modal } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { openConfirmNotification, showToast } from "../utils/toast";
import ProfileIcon from "../../assets/images/vectors/ProfileIcon";
import { ProfileForm } from "./ProfileForm";
import MyPaymentForm from "./PaymentForm";

export const User = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [isModalOpen, setIsModalOpen] = useState();
  const [showCardModal, setShowCardModal] = useState(false);

  const handleMenuClick = (e) => {
    if (e.key === "profile") {
      setIsModalOpen(true);
      // navigate("/profile");
    } else if (e.key === "logout") {
      sessionStorage.removeItem("userData");
      openConfirmNotification("Success");
      navigate("/");
    } else if (e.key === "Info") {
      setShowCardModal(true);
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      className=" bg-white !opacity-1 rounded-sm border-2 border-gray-200  !py-4 !px-3 "
    >
      <div className="flex items-center justify-between px-4 ">
        {/* <ProfileIcon size={30} /> "#E9F1F8" */}
        <ProfileIcon className="h-[32px] p-1 w-[32px] bg-customColor_10 rounded-full mt-1" />

        <h4 className=" font-semibold font-['Poppins'] text-black text-[14px] mr-2 mt-0.5 p-2">
          {userData?.role_name}
        </h4>
      </div>

      {userData?.role_name !== "Customer" && (
        <Menu.Item key="profile" className=" !pl-20 !pt-[3px] profile ">
          Profile
        </Menu.Item>
      )}
      {/* <Menu.Item key="Info" icon={<CreditCardOutlined />}>
        Card Info
      </Menu.Item> */}
      <Menu.Item key="logout" className=" !pl-20 profile">
        Logout
      </Menu.Item>
    </Menu>
  );

  //For time formatting
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();

      // Format the date as YYYY-MM-DD
      const formattedDate = now.toISOString().split("T")[0];

      // Format the time as HH:mm
      const formattedTime = now.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use 24-hour format
      });

      setFormattedDateTime(`${formattedDate} ${formattedTime}`);
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="mr-4 ">
        <p className="text-[#000000] font-[Poppins] text-[14px]">
          {formattedDateTime}
        </p>
      </div>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className="h-[32px] w-[32px] customColor_10 "
      >
        <div className="flex items-center justify-center cursor-pointer bg-customColor_10 w-8 h-8 rounded-full">
          <ProfileIcon className="h-[24px] w-[24px]" />
        </div>
      </Dropdown>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        // className="my-modal"
        closeIcon={false}
        footer={null}
        width={520}
        centered
        motion={false}
      >
        <ProfileForm setIsModalOpen={setIsModalOpen} />
      </Modal>
      {/* <Modal
        open={showCardModal}
        onCancel={() => setShowCardModal(false)}
        footer={null}
        width={1000}
        centered
      >
        <div className="my-6">
          <MyPaymentForm />
        </div>
      </Modal> */}
    </div>
  );
};
