import * as React from "react";

const PasswordIcon = ({ ...props }) => {
  const oemData = JSON.parse(localStorage.getItem("oemData"));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}
    >
      <path
        fill={oemData?.color}
        d="M1.667 13.333c0-2.356 0-3.535.732-4.267.732-.733 1.911-.733 4.268-.733h6.666c2.357 0 3.536 0 4.268.733.732.732.732 1.91.732 4.267s0 3.536-.732 4.268c-.732.732-1.911.732-4.268.732H6.667c-2.357 0-3.536 0-4.268-.732-.732-.732-.732-1.911-.732-4.268Z"
        opacity={0.5}
      />
      <path
        fill={oemData?.color}
        d="M6.667 14.167a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Zm3.333 0a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Zm4.167-.834a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0ZM5.625 6.667a4.375 4.375 0 0 1 8.75 0v1.67c.473.004.887.015 1.25.041V6.667a5.625 5.625 0 1 0-11.25 0v1.712c.416-.027.833-.041 1.25-.042v-1.67Z"
      />
    </svg>
  );
};

export default PasswordIcon;
