import React, { useEffect, useState } from "react";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { User } from "./User";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import "../styles/sidebar.css";
import useOemData from "../hooks/useOemData";
import Sticky from "react-stickynode";

//Though the name is SideBar, actually it is a Layout component that has a sidebar also!
export const SideBar = ({ children, items }) => {
  const { Header, Content, Footer, Sider } = Layout;
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, colorBgBase, colorBgMask, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const { role_value } = userData;

  const handleImageClick = () => {
    if (role_value === 1 || role_value === 2 || role_value === 3) {
      navigate("/devicelist");
    } else if ([4, 5, 6, 7, 8, 9, 10, 11, 12].includes(role_value)) {
      navigate("/orderitemlist");
    } else {
      navigate("/");
    }
  };

  // Auto-collapse when screen size is small
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return oemDataLoading ? (
    <div className="w-full flex items-center justify-center h-screen">
      {/* <Spinner /> */}
    </div>
  ) : (
    <Layout style={{ minHeight: "100vh" }}>
      <Sticky innerZ={60}>
        <Header
          style={{
            height: "55px",
            padding: "0px 2px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // borderBottom: `1px solid rgba(212, 212, 212, 1)`,
          }}
          className="border-b"
        >
          <div
            style={{
              cursor: "pointer",
              marginLeft: "16px",
            }}
            onClick={handleImageClick}
          >
            <img
              src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
              style={{ height: "32px" }}
              alt="Logo"
            />
          </div>
          <div className="mr-4">
            <User />
          </div>
        </Header>
      </Sticky>
      <Layout style={{ backgroundColor: "white" }}>
        <Sider
          // collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            backgroundColor: "#FEFFFF",
            height: "100vh", // Full height
            position: "fixed", // Fix sidebar position
            left: 0, // Keep it at the left
            top: 55, // Stick to the top
            bottom: 0, // Stretch to bottom
            overflowY: "auto", // Allow sidebar scrolling if needed
            zIndex: 100,
          }}
        >
          <div className="" />
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items?.map((item) => ({
              ...item,
              icon: React.cloneElement(item.icon, {
                color: item.key === selectedKey ? "#ffffff" : "#3A3A41",
              }),
            }))}
            onClick={(item) => {
              navigate(item?.key);
              setSelectedKey(item.key);
            }}
          />
          <div
            style={{
              position: "fixed", // Keep it above everything
              top: "83px", // Center vertically (adjust for top padding)
              left: collapsed ? 80 : 200, // Position outside the sidebar
              transform: "translateX(-50%)", // Center the button horizontally
              padding: "10px",
              cursor: "pointer",
              zIndex: 200, // Higher than the sidebar to always stay on top
              transition: "left 0.3s ease", // Smooth transition
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? (
              <div className="bg-customColor p-[4px] rounded-full border border-white  focus:!bg-none">
                <FaAngleRight color="#FEFFFF" size={18} />
              </div>
            ) : (
              <div className="bg-customColor p-[4px] rounded-full border border-white  focus:!bg-none">
                <FaAngleLeft color="#FEFFFF" size={18} />
              </div>
            )}
          </div>
        </Sider>
        <Layout
          style={{
            backgroundColor: "#F4F5F9",
            marginLeft: collapsed ? 80 : 200, // Adjust based on collapse state
            transition: "margin-left 0.3s ease", // Smooth transition
          }}
        >
          <Content style={{ margin: "16px 22px" }}>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
