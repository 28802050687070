// import { Table } from "antd";
// import "../styles/globalTable.css";

// export const GlobalTable = ({
//   columns,
//   dataSource,
//   pagination,
//   rowSelection,
//   onRow,
// }) => {
//   return (
//     <div className="my-table">
//       <Table
//         columns={columns}
//         dataSource={dataSource}
//         pagination={pagination}
//         rowSelection={rowSelection}
//         onRow={onRow}
//         showSorterTooltip={false}
//       />
//     </div>
//   );
// };
import React from "react";
import { Table } from "antd";
import "../styles/globalTable.css";
// import PaginationTable from "./Pagination";
import TableLoader from "./tableLoader";
import PaginationTable from "./paginationTable";

export const GlobalTable = ({
  columns,
  dataSource,
  pagination,
  rowSelection,
  onRow,
  onChange,
  loading,
}) => {
  const tableHeaderClassName = "my-table-header";
  const modifiedRowSelection = rowSelection && {
    ...rowSelection,
    fixed: "left",
  };

  return (
    <div className="my-table">
      {/* Show TableLoader while loading */}
      {loading && <TableLoader isLoading={loading} rowCount={10} />}

      {/* Render table only when not loading */}
      {!loading && (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowSelection={modifiedRowSelection}
          onRow={onRow}
          headerClassName={tableHeaderClassName}
          showSorterTooltip={false}
          responsive
          onChange={onChange}
        />
      )}

      {/* Show pagination only when not loading and there is data */}
      {!loading && pagination !== false && pagination?.total > 0 && (
        <PaginationTable
          total={pagination.total}
          pagination={pagination}
          paginationConfig={pagination}
        />
      )}
    </div>
  );
};
