import { Row } from "antd";
import * as React from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import usePost from "../hooks/usePost";
import { openConfirmNotification, openErrorNotification } from "../utils/toast";
import { useState } from "react";
const MyPaymentForm = ({
  amount,
  setPaymentModal,
  InvoiceNo,
  OrderName,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);

  const postUrl = `${process.env.REACT_APP_API_URL}/api/payments/${InvoiceNo}/make_payment`;
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const handleSuccess = (token) => {
    setLoading(true);
    const payload = {
      Source_id: token?.token,
    };
    postAction(payload);
  };

  React.useEffect(() => {
    refetch();
  }, [postData, postLoading]);

  const handleError = (errors) => {
    console.error("Payment failed:", errors);
  };
  React.useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      setLoading(false);

      openConfirmNotification("Success");
      setPaymentModal(false);
    }
  }, [postError, postData, postLoading]);

  return (
    <>
      <p className="text-sm font-[Poppins] px-2  mb-6">
        Please provide your card information to proceed with your order, The
        total bill for your purchase is ={" "}
        <span className="font-medium">${amount}</span>
      </p>
      <PaymentForm
        applicationId={process.env.REACT_APP_APPLICATION_ID}
        locationId={process.env.REACT_APP_LOCATION_ID}
        cardTokenizeResponseReceived={(token, buyer) => {
          handleSuccess(token);
        }}
      >
        {/* Square's CreditCard Component for Tokenization */}
        <Row>
          <div className="w-full justify-center items-center px-9 ">
            <div id="card-container" className="space-y-2">
              <CreditCard
                buttonProps={{
                  css: {
                    backgroundColor: "var(--text-color)",
                    fontSize: "14px",
                    padding: "4px 4px",
                    height: "30px",
                    width: "120px",
                    borderRadius: "4px",
                    display: "block",
                    margin: "0 auto",
                  },
                }}
              />
            </div>
          </div>
        </Row>
      </PaymentForm>
    </>
  );
};

export default MyPaymentForm;
