import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { openErrorNotification, showToast } from "../../../global/utils/toast";
import {
  DesktopOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import DeviceLogIcon from "../../../assets/images/vectors/SideBarIcons/DeviceLogIcon";
import CommonButton from "../../../global/components/CommonButton";

export const DeviceLogInfo = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/devicelog/${id}`;

  const { data, error, refetch } = useGet(url);

  useEffect(() => {
    const getDeviceInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            LogTime: data?.data?.LogTime
              ? formatUnixTime(data?.data?.LogTime)
              : "",
            // AccountNo: data?.data?.AccountName
            //   ? String(data?.data?.AccountName)
            //   : "",
            Mac: data?.data?.Mac,
            Product: data?.data?.ProductName,
            Model: data?.data?.ModelName,
            Type: String(data?.data?.Type),
            OemNo: data?.data?.OemName,
            Options: data?.data?.Options,
            Camera:
              data?.data?.Camera != null ? String(data?.data?.Camera) : "",
            Channel:
              data?.data?.Channel != null ? String(data?.data?.Channel) : "",
            Lockset:
              data?.data?.Lockset != null ? String(data?.data?.Lockset) : "",
            Facegate:
              data?.data?.Facegate != null ? String(data?.data?.Facegate) : "",
            Subnode:
              data?.data?.Subnode != null ? String(data?.data?.Subnode) : "",
            ContLock:
              data?.data?.ContLock != null ? String(data?.data?.ContLock) : "",
            Intercom:
              data?.data?.Intercom != null ? String(data?.data?.Intercom) : "",
            Sicucom:
              data?.data?.Sicucom != null ? String(data?.data?.Sicucom) : "",
            LicenseKey: data?.data?.LicenseKey
              ? String(data?.data?.LicenseKey)
              : "",
            Expired: data?.data?.Expired === 0 ? "No" : "Yes",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        console.log({ error });
        openErrorNotification("Error fetching data!");
      }
    };

    getDeviceInfoById();
  }, [id, data, form]);

  const breadcrumbItems = [
    {
      label: "Device Log",
      href: "/deviceloglist",
    },
    {
      label: "Information",
      href: `/devicelog/info/${id}`,
    },
  ];
  const buttonsData = [
    {
      label: "List",
      icon: <UnorderedListOutlined />,
      onClick: () => {
        navigate("/deviceloglist");
      },
    },
  ];

  return (
    <div>
      {/* <CommonBreadcrumb breadcrumbItems={breadcrumbItems} /> */}
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
        <CommonButton buttonsData={buttonsData} />
      </CommonBreadcrumb>

      <div className="">
        <Form
          validateTrigger={"onChange"}
          form={form}
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-gray-200 p-2.5">
              <DeviceLogIcon className="text-[18px] text-black " />
              <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                Device
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Log Time"
                    name="LogTime"
                    disabled={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="MAC" name="Mac" disabled={true} />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Expired" name="Expired" disabled={true} />
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-gray-200 p-2.5">
              <InfoCircleOutlined className="text-[18px] text-black " />
              <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                License Information
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Product" name="Product" disabled={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Model" name="Model" disabled={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Type" name="Type" disabled={true} />
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="OEM" name="OemNo" disabled={true} />
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-gray-200 p-2.5">
              <SettingOutlined className="text-[18px] text-black " />
              <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                License Options
              </span>
            </div>
            <div className="p-2">
              <CustomInput label="Options" name="Options" disabled={true} />
            </div>
          </div>
          <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-gray-200 p-2.5">
              <DesktopOutlined className="text-[18px] text-black " />
              <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                License Devices
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Camera" name="Camera" disabled={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Channel" name="Channel" disabled={true} />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Lockset" name="Lockset" disabled={true} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Facegate"
                    name="Facegate"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="OSDP Device"
                    name="Subnode"
                    disabled={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Trilogy Lockset"
                    name="ContLock"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Intercom"
                    name="Intercom"
                    disabled={true}
                  />
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="License Key"
                      name="LicenseKey"
                      disabled={true}
                    />
                  </Col> */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput
                    label="Directory"
                    name="Sicucom"
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
