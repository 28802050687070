import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "antd";
import useGet from "../../../global/hooks/useGet";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import useOemData from "../../../global/hooks/useOemData";
import {
  DesktopOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";

export const OrderedItemForm = ({ itemNo }) => {
  const { oemNo } = useOemData();
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { mac } = customerInfo;
  const url = `${process.env.REACT_APP_API_URL}/api/orderitem/${itemNo}`;
  const { data, loading, error } = useGet(url);

  return (
    <div className="">
      <div className=" bg-white   rounded-md overflow-hidden">
        <div className="flex items-center bg-gray-200 p-2.5">
          <InfoCircleOutlined className="text-[18px] text-black" />
          <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
            Upgrade License Information
          </span>
        </div>

        <div className="p-1.5">
          {/* <div className="!bg-white">
          <p className="">Device</p>
          <Divider className="m-0" />
          <p className="text-sm p-2 my-4 flex items-center">
            <span className="bg-customColor_10 p-2 rounded-xl">
              <MacIcon />
            </span>
            <span className="text-black font-[Poppins] mx-2">MAC:</span> {mac}
          </p>
        </div> */}

          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {/* Left Column */}
              <div>
                <p className="text-sm p-2 flex items-center">
                  <span className="bg-customColor_10 p-1.5 rounded-xl">
                    <ProductIcon />
                  </span>
                  <span className="text-black font-[Poppins] mx-2">
                    Old Model :
                  </span>{" "}
                  {data?.data?.LicenseInfo?.OldModelName}
                </p>
                <p className="text-sm p-2 flex items-center">
                  <span className="bg-customColor_10 p-2 rounded-xl">
                    <ModelIcon />
                  </span>
                  <span className="text-black font-[Poppins] mx-2">
                    Old Type :
                  </span>{" "}
                  {data?.data?.LicenseInfo?.OldType}
                </p>
              </div>

              {/* Right Column */}
              <div>
                <p className="text-sm p-2 flex items-center">
                  <span className="bg-customColor_10 p-1.5 rounded-xl">
                    <OemIcon />
                  </span>
                  <span className="text-black font-[Poppins] mx-2">
                    Model :
                  </span>{" "}
                  {data?.data?.LicenseInfo?.ModelName}
                </p>
                <p className="text-sm p-2 flex items-center mb-4">
                  <span className="bg-customColor_10 p-2 rounded-xl">
                    <TypeIcon />
                  </span>
                  <span className="text-black font-[Poppins] mx-2">Type :</span>{" "}
                  {data?.data?.LicenseInfo?.Type}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-white  rounded-md pb-1.5 overflow-hidden">
          <div className="flex items-center bg-gray-200 p-2">
            <SettingOutlined className="text-[18px] text-black " />
            <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
              License Options
            </span>
          </div>
          {/* <Divider className="my-2" /> */}
          <div className="">
            <p className="text-sm px-2 flex items-center my-4">
              <span className="bg-customColor_10 p-2 rounded-xl">
                <OptionsIcon />
              </span>
              <span className="text-black font-[Poppins] mx-2">Options :</span>{" "}
              {data?.data?.LicenseInfo?.OptionNames_arr?.map((option) => {
                return (
                  <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                    {option}
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-gray-200 p-2 rounded-md flex items-center">
          <DesktopOutlined className="text-[18px] text-black " />
          <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
            License Devices
          </span>
        </div>

        <div className="py-1.5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2.5">
            {/* Left Column */}
            <div>
              <p className="text-sm p-2 flex items-center">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <CameraIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">Camera:</span>{" "}
                {data?.data?.LicenseInfo?.Camera}
              </p>
              <p className="text-sm p-2 flex items-center ">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <TrilogyIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">Lockset:</span>{" "}
                {data?.data?.LicenseInfo?.Lockset}
              </p>
              <p className="text-sm p-2 flex items-center ">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <SubnodeIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">
                  OSDP Device:
                </span>{" "}
                {data?.data?.LicenseInfo?.Subnode}
              </p>
              <p className="text-sm p-2 flex items-center">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <IntercomIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">
                  Intercom:
                </span>{" "}
                {data?.data?.LicenseInfo?.Intercom}
              </p>
            </div>

            {/* Right Column */}
            <div>
              <p className="text-sm p-2 flex items-center ">
                <span className="bg-customColor_10 p-1.5 rounded-xl">
                  <ChannelIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">Channel:</span>{" "}
                {data?.data?.LicenseInfo?.Channel}
              </p>
              <p className="text-sm p-2 flex items-center ">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <FacegateIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">
                  Facegate:
                </span>{" "}
                {data?.data?.LicenseInfo?.Facegate}
              </p>
              <p className="text-sm p-2 flex items-center ">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <TrilogyIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">
                  Trilogy Lockset:
                </span>{" "}
                {data?.data?.LicenseInfo?.ContLock}
              </p>
              <p className="text-sm p-2 flex items-center">
                <span className="bg-customColor_10 p-2 rounded-xl">
                  <IntercomIcon />
                </span>
                <span className="text-black font-[Poppins] mx-2">
                  Directory:
                </span>{" "}
                {data?.data?.LicenseInfo?.Sicucom}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
{
  /* <p className="text-sm p-2 flex items-center">
  <span className="bg-customColor_10 p-2 rounded-xl">
    <ProductIcon />
  </span>
  <span className="text-black font-[Poppins] font-light mx-2">Product :</span>
  {changedLicense?.product ? (
    <>
      <div className="flex gap-1.5 items-center">
        <span>{deviceData?.data?.ProductName}</span>
        <ArrowRightOutlined />
        <span className="bg-green-500 text-white px-1 py-0.5 rounded-md">
          {mergedLicenseNames?.product}
        </span>
      </div>
    </>
  ) : (
    mergedLicenseNames?.product
  )}
</p>; */
}

// import { Form, Input, Row, Col, Divider, Button } from "antd";
// import {
//   DesktopOutlined,
//   InfoCircleOutlined,
//   SettingOutlined,
// } from "@ant-design/icons";
// import ProductIcon from "../../../assets/images/vectors/ProductIcon";
// import ModelIcon from "../../../assets/images/vectors/ModelIcon";
// import OemIcon from "../../../assets/images/vectors/OemIcon";
// import TypeIcon from "../../../assets/images/vectors/TypeIcon";
// import CameraIcon from "../../../assets/images/vectors/CameraIcon";
// import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
// import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
// import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
// import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
// import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
// import useGet from "../../../global/hooks/useGet";
// import DeviceLogIcon from "../../../assets/images/vectors/SideBarIcons/DeviceLogIcon";

// export const OrderedItemForm = ({ itemNo }) => {
//   const { data, loading, error } = useGet(
//     `${process.env.REACT_APP_API_URL}/api/orderitem/${itemNo}`
//   );

//   return (
//     <div>
//       <Form layout="vertical" validateTrigger="onChange" autoComplete="off">
// <div className=" bg-white border  rounded-[4px] overflow-hidden">
//   <div className="flex items-center bg-gray-200 p-2.5">
//     <InfoCircleOutlined className="text-[18px] text-black" />
//     <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//       License Information
//     </span>
//   </div>

//   <div className="p-2.5">
//             <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
//               {/* <p className="text-[16px] font-medium">Upgrade Information</p> */}
//               <div className="flex items-center bg-gray-200 p-2.5">
//                 <DeviceLogIcon className="text-[18px] text-black " />
//                 <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                   Upgrade Information
//                 </span>
//               </div>
//               <div className="p-2">
//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Old Model:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.OldModel}
//                         disabled
//                         // prefix={<ProductIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Old Type:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.OldType}
//                         disabled
//                         // prefix={<ModelIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>

//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Model:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Model}
//                         disabled
//                         // prefix={<OemIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Type:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Type}
//                         disabled
//                         // prefix={<TypeIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//             <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
//               <div className="flex items-center bg-gray-200 p-2.5">
//                 <SettingOutlined className="text-[18px] text-black " />
//                 <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                   License Options
//                 </span>
//               </div>
//               {/* <Divider className="my-2" /> */}
//               <div className="p-2">
//                 <Form.Item label="Options: ">
//                   <div className="p-3">
//                     {data?.data?.LicenseInfo?.OptionNames_arr?.map(
//                       (option, index) => (
//                         <span
//                           key={index}
//                           className="px-2 py-1 bg-gray-200/55 rounded-md mr-1"
//                         >
//                           {option}
//                         </span>
//                       )
//                     )}
//                   </div>
//                 </Form.Item>
//               </div>
//             </div>

//             <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
//               <div className="flex items-center bg-gray-200 p-2.5">
//                 <DesktopOutlined className="text-[18px] text-black " />
//                 <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                   License Devices
//                 </span>
//               </div>
//               <div className="p-2">
//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Camera:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Camera}
//                         disabled
//                         // prefix={<CameraIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Lockset:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Lockset}
//                         disabled
//                         // prefix={<TrilogyIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Subnode:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Subnode}
//                         disabled
//                         // prefix={<SubnodeIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Intercom:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Intercom}
//                         disabled
//                         // prefix={<IntercomIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Channel:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Channel}
//                         disabled
//                         // prefix={<ChannelIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Facegate:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Facegate}
//                         disabled
//                         // prefix={<FacegateIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>

//                 {/* Added the missing fields for Trilogy Lockset and Sicunet Intercom */}
//                 <Row gutter={[16]}>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Trilogy Lockset:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.ContLock}
//                         disabled
//                         // prefix={<TrilogyIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col xs={24} sm={24} md={12} lg={12} xl={12}>
//                     <Form.Item label="Sicunet Intercom:">
//                       <Input
//                         value={data?.data?.LicenseInfo?.Sicucom}
//                         disabled
//                         // prefix={<IntercomIcon />}
//                       />
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </div>
//             </div>
//           </div>

//           {/* <div className="text-center mt-4">
//             <Button type="primary" htmlType="submit" disabled>
//               Submit
//             </Button>
//           </div> */}
//         </div>
//       </Form>
//     </div>
//   );
// };
