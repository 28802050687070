import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import useOemData from "../../../global/hooks/useOemData";
import WelcomeBar from "../../License/Components/WelcomeBar";
import { Button, Divider } from "antd";
import MacIcon from "../../../assets/images/vectors/MacIcon";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import SideCards from "../../License/Components/SideCards";
import { User } from "../../../global/components/User";
import { useLocation, useNavigate } from "react-router-dom";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import useGet from "../../../global/hooks/useGet";
import usePut from "../../../global/hooks/usePut";
import usePost from "../../../global/hooks/usePost";
import { LicenseContext, StepperContext } from "../Context/LicenseProvider";
import { openErrorNotification, showToast } from "../../../global/utils/toast";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import CheckIcon from "../../../assets/images/vectors/CheckIcon";
import { StepperComponent } from "../../../global/components/StepperComponent";
import DeviceIcon from "../../../assets/images/vectors/SideBarIcons/DeviceIcon";

const UpgradedLicense = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { oemNo } = useOemData();
  const { setSelectedStep } = useContext(StepperContext);
  const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { email, mac, product } = customerInfo;
  const [mergedLicenseNames, setMergedLicenseNames] = useState();
  const [changedLicense, setChangedLicense] = useState();
  const [mergedLicense, setMergedLicense] = useState({});
  const [isApplied, setIsApplied] = useState();
  // const [oldLicense, setOldLicense] = useState(null);
  // const [newSelectedLicenses, setNewSelectedLicenses] = useState([]);
  const { selectedLicense } = useContext(LicenseContext);

  const deviceUrl = `${process.env.REACT_APP_API_URL}/api/device/${mac}`;
  const usedLicenseUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/used`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;
  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(deviceUrl);

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(usedLicenseUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(deviceUrl);

  // useEffect(() => {
  //   if (deviceData?.data) {
  //     const data = {
  //       Mac: deviceData.data?.Mac,
  //       Product: deviceData.data?.Product,
  //       Oem: {
  //         OemName: deviceData.data?.OemName,
  //         OemNo: deviceData.data?.OemNo,
  //       },
  //       Model: deviceData.data?.Model,
  //       Type: deviceData.data?.Type,
  //       Options: deviceData.data?.Options.map((item, index) => ({
  //         name: deviceData.data?.OptionNames_arr[index],
  //         value: item,
  //       })),
  //       Camera: deviceData.data?.Camera,
  //       Channel: deviceData.data?.Channel,
  //       Lockset: deviceData.data?.Lockset,
  //       Facegate: deviceData.data?.Facegate,
  //       Subnode: deviceData.data?.Subnode,
  //       ContLock: deviceData.data?.ContLock,
  //       Subnode: deviceData.data?.Subnode,
  //       Intercom: deviceData.data?.Intercom,
  //       Sicucom: deviceData.data?.Sicucom,
  //     };
  //     setOldLicense(data);
  //   }
  // }, [deviceData]);

  // useEffect(() => {
  //   if (selectedLicense && deviceData?.data) {
  //     console.log(optionData);

  //     const data = selectedLicense.map((item, index) => ({
  //       Mac: mac,
  //       Product: item?.Product,
  //       Oem: {
  //         OemName: deviceData.data?.OemName,
  //         OemNo: deviceData.data?.OemNo,
  //       },
  //       Model: item?.Model,
  //       Type: item?.Type,
  //       Options: deviceData.data?.Options.map((item, index) => ({
  //         name: deviceData.data?.OptionNames_arr[index],
  //         value: item,
  //       })),
  //       Camera: deviceData.data?.Camera,
  //       Channel: deviceData.data?.Channel,
  //       Lockset: deviceData.data?.Lockset,
  //       Facegate: deviceData.data?.Facegate,
  //       Subnode: deviceData.data?.Subnode,
  //       ContLock: deviceData.data?.ContLock,
  //       Subnode: deviceData.data?.Subnode,
  //       Intercom: deviceData.data?.Intercom,
  //       Sicucom: deviceData.data?.Sicucom,
  //     }));
  //   }
  // }, [selectedLicense, deviceData?.data]);
  // console.log(oldLicense, selectedLicense);

  const handleApply = () => {
    const values = {
      Mac: mac,
      Product: mergedLicense.Product,
      Model: mergedLicense.Model,
      Type: mergedLicense.Type,
      Options: mergedLicense.Options,
      OemNo: deviceData?.data?.OemNo,
      Camera: mergedLicense.Camera,
      Channel: mergedLicense.Channel,
      Lockset: mergedLicense.Lockset,
      Facegate: mergedLicense.Facegate,
      Intercom: mergedLicense.Intercom,
      Sicucom: mergedLicense.Sicucom,
      Subnode: mergedLicense.Subnode,
      ContLock: mergedLicense.ContLock,
    };
    try {
      putAction(values);
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  // Function to merge licenses by finding the largest values and merging options
  const mergeLicenses = (licenses) => {
    console.log(licenses);

    const newLicense = {
      Product: Math.max(...licenses?.map((license) => license.Product)),
      Model: Math.max(...licenses?.map((license) => license.Model)),
      Type: Math.max(...licenses?.map((license) => license.Type)),
      OemNo: deviceData?.data?.OemNo,
      Camera: Math.max(...licenses?.map((license) => license.Camera)),
      Channel: Math.max(...licenses?.map((license) => license.Channel)),
      Lockset: Math.max(...licenses?.map((license) => license.Lockset)),
      Facegate: Math.max(...licenses?.map((license) => license.Facegate)),
      Subnode: Math.max(...licenses?.map((license) => license.Subnode)),
      ContLock: Math.max(...licenses?.map((license) => license.ContLock)),
      Intercom: Math.max(...licenses?.map((license) => license.Intercom)),
      Sicucom: Math.max(...licenses?.map((license) => license.Sicucom)),
      Options: [...new Set(licenses?.flatMap((license) => license.Options))],
    };

    return newLicense;
  };

  function areArraysEqual(arr1, arr2) {
    if (!arr1 || !arr2) return true;
    if (arr1.length !== arr2.length) return false;

    let sortedArr1 = [...arr1].sort((a, b) => a - b);
    let sortedArr2 = [...arr2].sort((a, b) => a - b);

    return sortedArr1.every((val, index) => val === sortedArr2[index]);
  }
  //function to compare which attributes are changed between old and new licenses
  const compareLicenses = (oldLicense, newLicense) => {
    console.log(oldLicense?.Options, newLicense?.Options);

    return {
      product: oldLicense?.Product !== newLicense?.Product,
      model: oldLicense?.Model !== newLicense?.Model,
      type: oldLicense?.Type !== newLicense?.Type,
      oemNo: oldLicense?.OemNo !== newLicense?.OemNo,
      camera: oldLicense?.Camera !== newLicense?.Camera,
      channel: oldLicense?.Channel !== newLicense?.Channel,
      lockset: oldLicense?.Lockset !== newLicense?.Lockset,
      facegate: oldLicense?.Facegate !== newLicense?.Facegate,
      subnode: oldLicense?.Subnode !== newLicense?.Subnode,
      contLock: oldLicense?.ContLock !== newLicense?.ContLock,
      intercom: oldLicense?.Intercom !== newLicense?.Intercom,
      sicucom: oldLicense?.Sicucom !== newLicense?.Sicucom,
      options: !areArraysEqual(oldLicense?.Options, newLicense?.Options),
      // JSON.stringify(oldLicense?.Options) !==
      // JSON.stringify(newLicense?.Options),
    };
  };

  useEffect(() => {
    if (location.pathname.includes("newLicense")) {
      setSelectedStep(3);
    }
  }, []);
  console.log(selectedLicense, deviceData?.data);

  useEffect(() => {
    if (isApplied) {
      setSelectedStep(4);
    }
  }, [isApplied]);

  useEffect(() => {
    const changedLicense = compareLicenses(deviceData?.data, mergedLicense);
    setChangedLicense(changedLicense);
  }, [mergedLicense, deviceData]);

  // useEffect to merge licenses when selectedLicense changes
  useEffect(() => {
    if (selectedLicense && deviceData && selectedLicense.length) {
      const licensesToCompare = [...selectedLicense, deviceData?.data];
      // console.log({ licensesToCompare });
      const newMergedLicense = mergeLicenses(licensesToCompare);
      setMergedLicense(newMergedLicense);
    } else {
      navigate("/orderedItems");
    }
  }, [selectedLicense, deviceData]);

  //send the order item no & mac
  useEffect(() => {
    if (putData && !putLoading && !putError) {
      const orderItemNumbers = selectedLicense
        .filter((item) => item?.ItemNo) // Filter out items that don't have OrderItemNo
        .map((item) => item.ItemNo); // Extract OrderItemNo

      const payload = {
        Mac: mac,
        ItemNo: orderItemNumbers,
      };
      postAction(payload);
    }
  }, [putData, putLoading]);

  //navigate to upgrade page after license is upgraded
  // useEffect(() => {
  //   if (postData && !postLoading && !postError) {
  //     sessionStorage.removeItem("customerInfo");
  //     navigate(`/upgrade?mac=${mac}&product=${product}`);
  //   }
  // }, [postData, postLoading]);

  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      // showToast(putData?.message, "success", faCheckCircle);
      setIsApplied(true);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    const product = optionData?.data?.products?.find(
      (item) => item.value === mergedLicense?.Product
    )?.name;
    const model = optionData?.data?.models?.find(
      (item) => item.value === mergedLicense?.Model
    )?.name;
    const options = optionData?.data?.options
      ?.filter((item) => mergedLicense?.Options?.includes(item.value))
      .map((item) => item.name);
    setMergedLicenseNames({ product, model, options });
  }, [mergedLicense, optionData]);

  const optionsData = deviceData?.data?.OptionNames_arr?.map((option) => {
    return (
      <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-2">{option}</span>
    );
  });
  console.log(
    deviceData?.data?.ModelName,
    mergedLicenseNames?.model,
    changedLicense
  );

  return (
    <div className="min-h-screen bg-[#F2F2F2]">
      {/* TopBar */}
      <div className="flex justify-between items-center h-[55px] bg-white mb-2 border-b-[1px]">
        {/* <SicunetLogo className="ml-12" /> */}

        <div
          style={{
            height: "32px",
            width: "140px",
            cursor: "pointer",
            marginLeft: "15px",
          }}
        >
          <img
            src={logoPath}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="mr-4">
          <div className="ml-4">
            <User />
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      {/* <WelcomeBar /> */}

      {/* Summary Section */}
      <div className="flex flex-col justify-between px-8 py-4">
        {isApplied ? (
          <div className="w-[100%] bg-white px-5 pt-4 pb-3 rounded-sm">
            <div className="px-3 ">
              <StepperComponent />
            </div>
            <div className="  rounded-md flex flex-col justify-center items-center gap-4 h-[420px]">
              <CheckIcon />
              <p className="text-[#34B847] text-[20px] font-semibold">
                Your License Has Been Successfully Upgraded
              </p>
              <p className="text-center text-[#808080] text-[13px] font-semibold">
                {`Go back to the License page of Neptune 2.0 and click the "Get License Key" button to apply new license`}
              </p>
            </div>
            <div className="px-2 ">
              <SideCards />
            </div>
          </div>
        ) : (
          <div className="w-[100%]">
            <div className="bg-white px-4 p-2 rounded-md mb-5">
              <StepperComponent />
              <div className="flex justify-between pt-6 mb-4 rounded-md">
                <Button
                  // type="primary"
                  className="reset-button "
                  onClick={() => navigate("/orderedItems")}
                >
                  <span className="mr-1 mt-1">
                    <ArrowLeftOutlined />
                  </span>
                  <span className="text-[14px]">Previous</span>
                </Button>
                <Button
                  type="primary"
                  className="mr-2 w-[104px] h-[30px] font-medium"
                  onClick={handleApply}
                >
                  <span className="text-[14px]">Apply</span>
                  <span className="ml-1 mt-1">
                    <CheckCircleOutlined />
                  </span>
                </Button>
              </div>
            </div>
            <div>
              {/* <div className="w-1/2 bg-white py-4 mr-2 rounded-md px-4">
                <p className="text-black font-[Poppins] text-[20px] text-center pt-2">
                  Old License
                </p>
                <Divider />
                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <MacIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        MAC:
                      </span>{" "}
                      {mac}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <ProductIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Product:
                      </span>{" "}
                      {deviceData?.data?.ProductName}
                    </p>
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <ModelIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Model:
                      </span>{" "}
                      {deviceData?.data?.ModelName}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <OemIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2 ">
                        OEM:
                      </span>{" "}
                      {deviceData?.data?.OemName}
                    </p>
                    <p className="text-sm p-2 flex items-center ">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <TypeIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Type:
                      </span>{" "}
                      {deviceData?.data?.Type}
                    </p>
                  </div>
                </div>
                <Divider className="my-2" />

                <div className="flex">
                  <p className="text-sm p-2 flex items-center flex-wrap ">
                    <span className="bg-customColor_10 p-2 rounded-xl mb-1">
                      <OptionsIcon />
                    </span>
                    <span className="text-black font-[Poppins] font-light mx-2">
                      Options:
                    </span>{" "}
                    {deviceData?.data?.OptionNames_arr?.map((option) => {
                      return (
                        <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                          {option}
                        </span>
                      );
                    })}
                  </p>
                </div>

                <Divider className="my-2" />

                <div className="flex mb-9">
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <CameraIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Camera:
                      </span>{" "}
                      {deviceData?.data?.Camera}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Lockset:
                      </span>{" "}
                      {deviceData?.data?.Lockset}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <SubnodeIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Subnode:
                      </span>{" "}
                      {deviceData?.data?.Subnode}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Intercom:
                      </span>{" "}
                      {deviceData?.data?.Intercom}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <ChannelIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Channel:
                      </span>{" "}
                      {deviceData?.data?.Channel}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-4">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <FacegateIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Facegate:
                      </span>{" "}
                      {deviceData?.data?.Facegate}
                    </p>
                    <p className="text-sm p-2 flex items-center mb-2">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <TrilogyIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Trilogy Lockset:
                      </span>{" "}
                      {deviceData?.data?.ContLock}
                    </p>
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <IntercomIcon />
                      </span>
                      <span className="text-black font-[Poppins] font-light mx-2">
                        Sicunet Intercom:
                      </span>{" "}
                      {deviceData?.data?.Sicucom}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className=" ">
                {/* <p className="text-black font-[Poppins] text-[20px] text-center pt-2 relative">
                  New License{" "}
                  <span className="absolute top-0 ml-2 bg-[#34B847] text-white text-[10px] px-2 py-1 rounded-md">
                    Upgrade
                  </span>
                </p> */}
                <div className="flex items-center bg-gray-200 mt-4 p-2.5  mb-3 rounded-[4px]">
                  <DeviceIcon className="text-[18px] text-black" />

                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2 font-medium">
                    New License for :
                  </span>

                  <span className="text-[.935rem] text-customColor font-['Poppins'] ml-2 font-semibold">
                    {mac}
                  </span>
                </div>

                {/* <div className="mt-4 overflow-hidden border m-2 bg-white rounded-[4px]">
                  {" "}
                  <div className="flex items-center bg-gray-200 p-2.5">
                    <DeviceIcon className="text-[18px] text-black " />
                    <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                      Device
                    </span>
                  </div>
                  <p className="text-sm p-2 flex items-center">
                    <span className="bg-customColor_10 p-2 rounded-xl">
                      <MacIcon />
                    </span>
                    <span className="text-black font-[Poppins] font-light mx-2">
                      MAC :
                    </span>{" "}
                    {mac}
                  </p>
                </div> */}
                {/* <Divider className="my-2" /> */}

                <div className="bg-white mt-5 rounded-sm ">
                  <div className="flex items-center bg-gray-200 p-2 ">
                    <InfoCircleOutlined className="text-[18px] text-black" />
                    <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                      License Information
                    </span>
                  </div>

                  {/* Grid Layout for Items */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 pb-2 mt-2 px-1.5">
                    <p className="text-sm p-1 flex items-center">
                      <span className="bg-customColor_10 p-1.5 rounded-xl">
                        <ProductIcon />
                      </span>
                      <span className="text-black font-[Poppins]  mx-2">
                        Product :
                      </span>
                      {changedLicense?.product ? (
                        <>
                          <div className="flex gap-1.5 items-center">
                            <span>{deviceData?.data?.ProductName}</span>
                            <ArrowRightOutlined />
                            <span className="bg-green-500 text-white px-1 py-0 rounded-md">
                              {mergedLicenseNames?.product}
                            </span>
                          </div>
                        </>
                      ) : (
                        mergedLicenseNames?.product
                      )}
                    </p>

                    <p className="text-sm p-1 flex items-center">
                      <span className="bg-customColor_10 p-[7px] rounded-xl">
                        <ModelIcon />
                      </span>
                      <span className="text-black font-[Poppins]  mx-2">
                        Model :
                      </span>
                      {changedLicense?.model ? (
                        <div className="flex gap-1.5 items-center">
                          <span>{deviceData?.data?.ModelName}</span>
                          <ArrowRightOutlined />
                          <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                            {mergedLicenseNames?.model}
                          </span>
                        </div>
                      ) : (
                        <span>{deviceData?.data?.ModelName}</span>
                      )}
                    </p>

                    <p className="text-sm p-1 flex items-center">
                      <span className="bg-customColor_10 p-[5px] rounded-xl">
                        <OemIcon />
                      </span>
                      <span className="text-black font-[Poppins]  mx-2">
                        OEM :
                      </span>
                      {changedLicense?.OemName ? (
                        <div className="flex gap-1.5 items-center">
                          <span>{deviceData?.data?.OemName}</span>
                          <ArrowRightOutlined />
                          <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                            {mergedLicenseNames?.OemName}
                          </span>
                        </div>
                      ) : (
                        <span>{deviceData?.data?.OemName}</span>
                      )}
                      {/* {deviceData?.data?.OemName} */}
                    </p>

                    <p className="text-sm p-1 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <TypeIcon />
                      </span>
                      <span className="text-black font-[Poppins]  mx-2">
                        Type :
                      </span>
                      {changedLicense?.type ? (
                        <>
                          <div className="flex gap-1.5 items-center">
                            <span>{deviceData?.data?.Type}</span>
                            <ArrowRightOutlined />
                            <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                              {mergedLicense?.Type}
                            </span>
                          </div>
                        </>
                      ) : (
                        <span>{deviceData?.data?.Type}</span>
                      )}
                    </p>
                  </div>
                </div>

                <div className="mt-5  bg-white rounded-[4px]   overflow-hidden">
                  <div className="flex items-center bg-gray-200 p-2">
                    <SettingOutlined className="text-[18px] text-black " />
                    <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                      License Options
                    </span>
                  </div>{" "}
                  <p className="text-sm p-2.5 flex items-center flex-wrap">
                    <span className="bg-customColor_10 p-1.5 rounded-xl mb-1">
                      <OptionsIcon />
                    </span>
                    <span className="text-black font-[Poppins]  mx-2 mb-1">
                      Options :
                    </span>
                    {/* {mergedLicenseNames?.options?.map((option) => {
                      return (
                        <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                          {option}
                        </span>
                      );
                    })} */}
                    {changedLicense?.options ? (
                      <>
                        <div className="flex gap-1.5 items-center">
                          {optionsData}
                          {optionsData.length ? <ArrowRightOutlined /> : null}
                          {mergedLicenseNames?.options?.map((option) => {
                            return (
                              <span className="px-2 py-0.5 bg-green-500 text-white rounded-sm mr-2">
                                {option}
                              </span>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      optionsData
                    )}
                  </p>
                </div>
                {/* <Divider className="my-2" /> */}

                <div className="bg-white mt-5 rounded-sm ">
                  {/* Header */}
                  <div className="flex items-center bg-gray-200 p-2">
                    <DesktopOutlined className="text-[18px] text-black" />
                    <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                      License Devices
                    </span>
                  </div>

                  {/* Responsive Grid */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 p-2">
                    {/* Left Column */}
                    <div>
                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <CameraIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Camera :
                        </span>
                        {changedLicense?.camera ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Camera}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Camera}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Camera}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <TrilogyIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Lockset :
                        </span>
                        {changedLicense?.lockset ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Lockset}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Lockset}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Lockset}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <SubnodeIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Subnode :
                        </span>
                        {changedLicense?.subnode ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Subnode}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Subnode}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Subnode}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <IntercomIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Intercom :
                        </span>
                        {changedLicense?.intercom ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Intercom}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Intercom}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Intercom}</span>
                        )}
                      </p>
                    </div>

                    {/* Right Column */}
                    <div>
                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-1.5 rounded-xl">
                          <ChannelIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Channel :
                        </span>
                        {changedLicense?.channel ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Channel}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Channel}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Channel}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <FacegateIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Facegate :
                        </span>
                        {changedLicense?.facegate ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Facegate}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Facegate}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Facegate}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center ">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <TrilogyIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Trilogy Lockset :
                        </span>
                        {changedLicense?.contlock ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.ContLock}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.ContLock}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.ContLock}</span>
                        )}
                      </p>

                      <p className="text-sm p-2 flex items-center">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <IntercomIcon />
                        </span>
                        <span className="text-black font-[Poppins]  mx-2">
                          Directory :
                        </span>
                        {changedLicense?.sicucom ? (
                          <>
                            <div className="flex gap-1.5 items-center">
                              <span>{deviceData?.data?.Sicucom}</span>
                              <ArrowRightOutlined />
                              <span className="bg-green-500 text-white px-1 py-0 rounded-sm">
                                {mergedLicense?.Sicucom}
                              </span>
                            </div>
                          </>
                        ) : (
                          <span>{deviceData?.data?.Sicucom}</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* CARD SECTION STARTS HERE */}
      </div>
    </div>
  );
};

export default UpgradedLicense;
