import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const DeviceColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "DeviceNo",
      key: "DeviceNo",
      sorter: (a, b) => a?.DeviceNo - b?.DeviceNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DeviceNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DeviceNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "MAC",
      dataIndex: "Mac",
      key: "Mac",
      sorter: (a, b) => a?.Mac?.length - b?.Mac?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Mac"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Mac",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Product",
      dataIndex: "Product",
      key: "Product",
      sorter: (a, b) => a?.Product?.length - b?.Product?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Product"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Product",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Model",
      dataIndex: "Model",
      key: "Model",
      sorter: (a, b) => a?.Model?.length - b?.Model?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Model"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Model",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      sorter: (a, b) => a?.Type?.length - b?.Type?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Type"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Type",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo?.length - b?.OemNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Expired",
      dataIndex: "Expired",
      key: "Expired",
      sorter: (a, b) => a?.Expired?.length - b?.Expired?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Expired"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Expired",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="absolute top-2 right-5">
            <div className=" duration-75 bottom-0 flex gap-2 items-center justify-center z-50">
              <div className="hover:bg-gray-400 px-[5px] py-1 rounded-[4px] mr-1 ">
                <Link
                  to={`/device/edit/${record?.DeviceNo}`}
                  onClick={(e) => e.stopPropagation()}
                  className=" hover:text-[#636262ee]"
                >
                  <EditOutlined />
                </Link>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                  setSelectedRowKeys([record?.DeviceNo]);
                }}
                className="cursor-pointer hover:bg-gray-400 px-[5px] py-1 rounded-[4px] "
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ) : null,
    },
  ];
};
