import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import {
  CheckCircleOutlined,
  CloseOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  EditOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import LicenseOpIcon from "../../../assets/images/vectors/SideBarIcons/LicenseOpIcon";

export const LicenseOptionForm = ({ type }) => {
  const [nextNo, setNextNo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/licenseoption/${id}`;
  const listUrl = `${process.env.REACT_APP_API_URL}/api/licenseoption/all?limit=10&order=desc`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/licenseoption/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/licenseoption/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/licenseoption/delete/${id}`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: listData,
    loading: listDataLoading,
    error: listDataError,
  } = useGet(listUrl);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction(values);
      } else {
        await postAction(values);
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/licenseoptionlist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    const nextOp = Number(listData?.data[0]?.OptionNo);
    setNextNo(nextOp + 1);
  }, [listDataLoading]);

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OptionNo: String(nextNo),
      });
    }
  }, [nextNo]);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            OptionNo: data?.data?.OptionNo ? String(data?.data?.OptionNo) : "",
            OptionName: data.data.OptionName
              ? String(data.data.OptionName)
              : "",
            OptionDesc: data.data.OptionDesc
              ? String(data.data.OptionDesc)
              : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
      navigate("/licenseoptionlist");
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    } else {
      return;
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "License Option",
      href: "/licenseoptionlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/licenseoption/info/${id}`
          : `/licenseoption/edit/${id}`
        : "/licenseoption/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/licenseoption/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/licenseoptionlist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
              <CommonButton buttonsData={buttonsData} />
            </CommonBreadcrumb>

            {
              <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <LicenseOpIcon className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Option
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="No"
                        name="OptionNo"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Name"
                        name="OptionName"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Description"
                        name="OptionDesc"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>
        </Form>
      }
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </>
  );
};
