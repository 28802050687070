import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const OemColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "OemNo",
      key: "OemNo",
      sorter: (a, b) => a?.OemNo - b?.OemNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OemName",
      key: "OemName",
      sorter: (a, b) => a?.OemName?.length - b?.OemName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OemDesc",
      key: "OemDesc",
      sorter: (a, b) => a?.OemDesc?.length - b?.OemDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Domain",
      dataIndex: "Domain",
      key: "Domain",
      sorter: (a, b) => a?.Domain?.length - b?.Domain?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Domain"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Domain",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "License",
      dataIndex: "LicenseName",
      key: "LicenseName",
      sorter: (a, b) => a?.LicenseName?.length - b?.LicenseName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="LicenseName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "LicenseName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM Info",
      dataIndex: "OemInfoNo",
      key: "OemInfoNo",
      sorter: (a, b) => a?.OemInfoNo?.length - b?.OemInfoNo?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemInfoNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemInfoNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",
      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="absolute top-2 right-5">
            <div className=" duration-75 bottom-0 flex gap-2 items-center justify-center z-50">
              <div className="hover:bg-gray-400 px-[5px] py-[5px] rounded-[4px] mr-1 ">
                <Link
                  to={`/oem/edit/${record?.OemNo}`}
                  onClick={(e) => e.stopPropagation()}
                  className=" hover:text-[#636262ee]"
                >
                  <EditOutlined />
                </Link>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                  setSelectedRowKeys([record?.OemNo]);
                }}
                className="cursor-pointer hover:bg-gray-400 px-[5px] py-[5px] rounded-[4px] "
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ) : null,
    },
  ];
};
