import React from "react";
import useOemData from "../hooks/useOemData";
import login from "../../assets/images/logo/LoginBg.png";

const AuthLayout = ({ children }) => {
  const { oemNo, loading, oemData } = useOemData();
  const dynamicBgColor = oemData?.color ? `${oemData.color}` : "";

  return (
   
    !loading && (
      <div className="flex justify-center items-center">
      <div
        className={`lg:w-1/2 flex justify-center items-center h-screen bg-customColor lg:bg-white w-full`}
      >
        {children}
      </div>
      <div
        className={`hidden lg:flex lg:w-1/2  flex-col items-center justify-center h-screen`}
        style={{ backgroundColor: dynamicBgColor }}
      >
        <img
          src={login}
          alt="Decorative"
          className="object-cover rounded-[6px]" // Adjust size and position to overflow
        />
        <div className="text-white text-center pt-6 px-36">
          <p className="text-2xl font-medium pb-4">Access Control Solution</p>
          <p className="text-xs font-light">
            Experience seamless data control with our hybrid system, blending
            cloud flexibility with the security of on-premise management. Own
            your data and enjoy the best of both worlds without compromise.
          </p>
        </div>
      </div>
    </div>
    )
  );
};

export default AuthLayout;
