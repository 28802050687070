// import React from "react";
// import classNames from "classnames";

// const TABLE_ROW_HEIGHT = 36; // Match the row height of your table

// function createArray(length) {
//   return Array.from({ length }, (_, i) => i + 1);
// }

// const TableLoader = ({
//   isLoading,
//   rowCount = 10, // Number of loader rows to display
//   rowHeight = TABLE_ROW_HEIGHT,
//   sideBorder = true,
// }) => {
//   const skeletonAnimation = `
//     @keyframes skeleton-shimmer {
//       0% {
//         background-position: -200% 0; /* Start off-screen to the left */
//       }
//       100% {
//         background-position: 200% 0; /* Move across to the right */
//       }
//     }

//     @keyframes spin {
//       0% {
//         transform: rotate(0deg);
//       }
//       100% {
//         transform: rotate(360deg);
//       }
//     }
//   `;

//   const skeletonStyles = {
//     baseColor: "#f0f0f0", // Lighter gray base color to match Amazon's tone
//     // movingColor:
//     //   "linear-gradient(to left, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%)", // Lighter gradient with smooth transition
//   };

//   if (!isLoading) {
//     return null;
//   }

//   return (
//     <div className="relative overflow-hidden">
//       <style>{skeletonAnimation}</style>

//       {/* Center Spinner */}
//       <div className="absolute w-full h-full flex justify-center items-center z-10">
//         <div
//           className="spinner"
//           style={{
//             border: "2px solid #f3f3f3", // Lighter border around the spinner
//             borderTop: "2px solid #FFFFFF", // White color for the spinning part
//             borderRadius: "50%",
//             width: "40px",
//             height: "40px",
//             animation: "spin 2s linear infinite",
//           }}
//         />
//       </div>

//       {/* Skeleton Rows */}
//       <div className="z-20 w-full">
//         {createArray(rowCount).map((item, index) => (
//           <div
//             className={classNames(
//               "w-full border-t-2",
//               sideBorder && "border-l-4 border-r-4 border-gray-50"
//             )}
//             style={{
//               height: rowHeight,
//               backgroundColor: skeletonStyles.baseColor,
//               backgroundImage: skeletonStyles.movingColor,
//               backgroundSize: "200% 100%", // Ensure it's large enough for animation
//               backgroundPosition: "0 0", // Start the shimmer effect from the left
//               animation: "skeleton-shimmer infinite linear", // Adjusted duration for smoother animation
//             }}
//             key={index}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default TableLoader;

import React from "react";
import classNames from "classnames";

const TABLE_ROW_HEIGHT = 36; // Match the row height of your table

function createArray(length) {
  return Array.from({ length }, (_, i) => i + 1);
}

const TableLoader = ({
  isLoading,
  rowCount = 10, // Number of loader rows to display
  rowHeight = TABLE_ROW_HEIGHT,
  sideBorder = true,
}) => {
  const skeletonAnimation = `
    @keyframes skeleton-shimmer {
      0% {
        background-position: -200% 0; /* Start off-screen to the left */
      }
      100% {
        background-position: 200% 0; /* Move across to the right */
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  const skeletonStyles = {
    baseColor: "#f0f0f0", // Lighter gray base color to match Amazon's tone
  };

  if (!isLoading) {
    return null;
  }

  return (
    <div className="relative overflow-hidden">
      <style>{skeletonAnimation}</style>

      {/* Center Spinner */}
      <div className="absolute w-full h-full flex justify-center items-center z-10">
        <div
          className="spinner"
          style={{
            border: "4px solid #f3f3f3", // Lighter border around the spinner
            borderTop: "4px solid #FFFFFF", // White color for the spinning part
            borderRadius: "50%",
            width: "45px",
            height: "45px",
            animation: "spin 2s linear infinite",
          }}
        />
      </div>

      {/* Skeleton Rows */}
      <div className="z-20 w-full">
        {createArray(rowCount).map((item, index) => (
          <div
            className={classNames(
              "w-full border-t-2",
              sideBorder && "border-l-4 border-r-4 border-gray-50"
            )}
            style={{
              height: rowHeight,
              backgroundColor: skeletonStyles.baseColor,
              backgroundSize: "200% 100%", // Ensure it's large enough for animation
              backgroundPosition: "0 0", // Start the shimmer effect from the left
              animation: "skeleton-shimmer 2s infinite linear", // Adjusted duration for smoother animation
            }}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default TableLoader;
