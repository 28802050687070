import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { CloseOutlined } from "@ant-design/icons";
import SentIcon from "../../../assets/images/vectors/SentIcon";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export const InvitationForm = ({ setIsModalOpen }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [selectedOemNo, setSelectedOemNo] = useState();
  const [accountType, setAccountType] = useState(loggedInUser?.role_value);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const dealerOptionsUrl =
    selectedOemNo &&
    `${process.env.REACT_APP_API_URL}/api/getDealersByOem/${selectedOemNo}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/invite`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;
  const dealerNo = Form.useWatch("DealerNo", form);
  const getCustomersUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getCustomersByDealer/${dealerNo}`;
  const { data: customerData } = useGet(getCustomersUrl);
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const getType = getModifiedResponse(optionsData, "roles");
  const modifiedType = getType.filter((item) => item.label !== "Customer");
  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedOems = getModifiedResponse(optionsData, "oems");
  const modifiedCustomer = getModifiedResponse(optionsData, "customers");

  const {
    data: dealerOp,
    loading: delaerOpLoading,
    error: dealerOpError,
    refetch: delaerOpRefetch,
  } = useGet(dealerOptionsUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const onFinish = (values) => {
    try {
      if (loggedInUser.role_value === 10 || loggedInUser.role_value === 11) {
        const modifiedValues = {
          ...values,
          OemNo: loggedInUser.oem_no,
          DealerNo: loggedInUser.dealer_no,
          CustomerNo: loggedInUser.customer_no,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      } else if (
        loggedInUser.role_value === 7 ||
        loggedInUser.role_value == 8
      ) {
        const modifiedValues = {
          ...values,
          OemNo: loggedInUser.oem_no,
          DealerNo: loggedInUser.dealer_no,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      } else if (
        loggedInUser.role_value === 4 ||
        loggedInUser.role_value == 5
      ) {
        const modifiedValues = {
          ...values,
          OemNo: loggedInUser.oem_no,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      } else {
        const modifiedValues = {
          ...values,
          HostLink: window.location.origin,
        };
        postAction(modifiedValues);
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  //toat for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setIsModalOpen(false);
    }
  }, [postError, postData, postLoading]);

  return (
    <div>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            {
              <div className=" bg-white rounded-[4px] overflow-hidden">
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 0]}>
                    <Col span={24}>
                      <CustomInput
                        label="Email"
                        name="Email"
                        type="email"
                        isEmail={true}
                        required
                      />
                    </Col>
                    <Col span={24}>
                      <CustomSelect
                        label="Account Type"
                        name="AccountType"
                        options={modifiedType}
                        placeholder="Please select"
                        onChange={(value) => {
                          setAccountType(value);
                          form.setFieldsValue({
                            OemNo: "",
                            DealerNo: "",
                          });
                        }}
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 0]}>
                    {![1, 2, 3].includes(accountType) &&
                      [1, 2].includes(loggedInUser.role_value) && (
                        <Col span={24}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            onChange={(value) => {
                              setSelectedOemNo(value);
                              form.setFieldsValue({
                                DealerNo: "",
                              });
                            }}
                            required
                          />
                        </Col>
                      )}
                    {[7, 8, 9, 10, 11, 12].includes(accountType) &&
                      [1, 2, 4, 5].includes(loggedInUser.role_value) && (
                        <Col span={24}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={
                              dealerOp?.data?.map((item) => ({
                                label: item.name,
                                value: item.value,
                              })) || []
                            }
                            placeholder="Please select"
                            required
                          />
                        </Col>
                      )}
                    {[10, 11, 12].includes(accountType) &&
                      [1, 2, 6, 7].includes(loggedInUser.role_value) && (
                        <Col span={24}>
                          <CustomSelect
                            label="Customer"
                            name="CustomerNo"
                            options={
                              customerData?.data?.map((item) => ({
                                label: item.name,
                                value: item.value,
                              })) || []
                            }
                            placeholder="Please select"
                            required
                          />
                        </Col>
                      )}
                  </Row>
                </div>
                <div className="flex justify-center space-x-2 mt-2.5">
                  <Button
                    type="primary"
                    className="mr-2 min-w-[104px] h-[30px] py-1 px-[10px] font-medium flex items-center "
                    onClick={() => form.submit()}
                  >
                    <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
                    <span className="">Apply</span>
                  </Button>
                  <Button
                    className="reset-button"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <CloseOutlined />
                    <span className="">Cancel</span>
                  </Button>
                </div>
              </div>
            }
          </div>
        </Form>
      }
    </div>
  );
};
