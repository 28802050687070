import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import { getModifiedResponse } from "../utils/responseModifier";
import useGet from "../hooks/useGet";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../utils/toast";
import { useNavigate, useParams } from "react-router-dom";
import CommonBreadcrumb from "./CommonBreadcrumb";
import CommonButton from "./CommonButton";
import CustomInput from "./inputWidgets/CustomInput";
import usePut from "../hooks/usePut";
import {
  UnorderedListOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import CustomSwitch from "./inputWidgets/CustomSwitch";
import AccountIcon from "../../assets/images/vectors/SideBarIcons/AccountIcon";
import ProfileIcon from "./../../assets/images/vectors/ProfileIcon";

export const ProfileForm = ({ setIsModalOpen }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const loggedInUserData = JSON.parse(sessionStorage.getItem("userData"));
  const id = loggedInUserData.account_no;

  const url = `${process.env.REACT_APP_API_URL}/api/profile`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/profile`;

  const { data, loading, error } = useGet(url);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = (values) => {
    try {
      const { confirmPassword, ...payload } = values; // Exclude confirmPassword
      putAction(payload);
      setIsModalOpen(false);
    } catch (error) {
      openErrorNotification("Something went wrong");
      console.log(error);
    }
  };

  //toast for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");
      navigate("/");
    }
  }, [putData, putLoading, putError]);

  const onReset = () => {
    // form.resetFields();
    // navigate("/accountlist");
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      console.log(data.data);
      try {
        if (data?.data) {
          form.setFieldsValue({
            AccountNo: String(data?.data?.AccountNo),
            UserId: String(data?.data?.UserId),
            AccountName: String(data.data.AccountName),
            TwoFactor: data.data.TwoFactor ? 1 : 0,
            AccountDesc: data.data.AccountDesc
              ? String(data.data.AccountDesc)
              : "",
            Email: data.data.Email ? String(data.data.Email) : "",
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  const breadcrumbItems = [
    {
      label: "Account",
      href: "/accountlist",
    },
    {
      label: "Add",
      href: "account/add",
    },
  ];

  const buttonsData = [
    // {
    //   label: "List",
    //   icon: <UnorderedListOutlined />,
    //   onClick: () => {
    //     navigate("/accountlist");
    //   },
    // },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <Form
      validateTrigger={"onChange"}
      form={form}
      onFinish={onFinish}
      scrollToFirstError
      layout={"vertical"}
      autoComplete={"off"}
    >
      <div className=" bg-white rounded-[4px] overflow-hidden">
        <div className="bg-gray-200 py-[8px] px-[3px] rounded-sm flex items-center mb-2 p-2">
          <ProfileIcon />
          <h2 className="text-[14px] font-[Poppins] text-black ml-2">
            Profile
          </h2>
        </div>

        <div className="mt-4 px-2 ">
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <CustomInput label="Username" name="UserId" />
            </Col>
            <Col span={24}>
              <CustomInput
                label="Old Password"
                name="OldPassword"
                autoComplete="new-password"
                type="password"
              />
            </Col>
            <Col span={24}>
              <CustomInput
                label="New Password"
                name="password"
                autoComplete="new-password"
                type="password"
              />
            </Col>
            <Col span={24}>
              <CustomInput
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="new-password"
                type="password"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("password")) {
                        if (!value) {
                          return Promise.reject(
                            new Error("Confirm Password is required")
                          );
                        }
                        if (getFieldValue("password") !== value) {
                          return Promise.reject(
                            new Error("Passwords do not match!")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              />
            </Col>
            <Col span={24}>
              <CustomInput label="Description" name="AccountDesc" />
            </Col>
            <Col span={24}>
              <CustomInput label="Email" name="Email" required={true} />
            </Col>
            <Col span={24}>
              <CustomSwitch
                label="Two Factor Authentication"
                name="TwoFactor"
                defaultChecked={0}
              />
            </Col>
          </Row>
        </div>

        <div className="flex justify-center items-center ">
          <CommonButton buttonsData={buttonsData} />
        </div>
      </div>
    </Form>
  );
};
