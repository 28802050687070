// import { useContext, useEffect, useState } from "react";
// import { GlobalTable } from "../../../global/components/GlobalTable";
// import { useNavigate, useLocation } from "react-router-dom";
// import { Spinner } from "../../../global/components/Spinner";
// import ConfirmModal from "../../../global/components/ConfirmModal";
// import useDelete from "../../../global/hooks/useDelete";
// import {
//   openConfirmNotification,
//   openErrorNotification,
// } from "../../../global/utils/toast";
// import { OrderItemColumn } from "./Column";
// import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
// import usePost from "../../../global/hooks/usePost";
// import { LicenseContext, StepperContext } from "../Context/LicenseProvider";
// import useOemData from "../../../global/hooks/useOemData";
// import { User } from "../../../global/components/User";
// import { Button, Modal } from "antd";
// import { OrderedItemForm } from "../Components/OrderedItemForm";
// import { StepperComponent } from "../../../global/components/StepperComponent";

// export const OrderedItem = () => {
//   const { oemNo, oemData } = useOemData();
//   const location = useLocation();
//   const { setSelectedStep } = useContext(StepperContext);
//   const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);
//   const userInfo = sessionStorage.getItem("customerInfo");
//   const customerInfo = JSON.parse(userInfo);

//   const { setSelectedLicense } = useContext(LicenseContext);
//   const [listData, setListData] = useState([]);
//   const [pagination, setPagination] = useState({
//     current: 1,
//     pageSize: 10,
//     total: 0,
//   });
//   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [itemNo, setItemNo] = useState();
//   const [showModal, setShowModal] = useState(false);
//   const [params, setParams] = useState({
//     limit: 10,
//     sort_by: "ItemNo",
//     order: "desc",
//   });

//   // console.log({ selectedLicense });

//   const navigate = useNavigate();

//   // to get table data from API server
//   const query = new URLSearchParams(params).toString();
//   const url = query
//     ? `${process.env.REACT_APP_API_URL}/api/license/ordered?${query}`
//     : `${process.env.REACT_APP_API_URL}/api/license/ordered`;
//   const { data, loading, error, postData: orderedLicense } = usePost(url);

//   let deleteUrl;
//   selectedRowKeys.length > 1
//     ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitems/delete`)
//     : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/delete/${selectedRowKeys}`);

//   const {
//     data: deletedData,
//     loading: deletedDataLoading,
//     error: deletedDataError,
//     deleteData,
//     deleteMultiple,
//   } = useDelete(deleteUrl);

//   const paginationConfig = {
//     current: pagination.current, //current page
//     pageSize: pagination.pageSize, // items per page
//     total: data?.count, // total items
//     showSizeChanger: true,
//     pageSizeOptions: [10, 20, 50],
//     showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
//     onChange: (page, pageSize) => {
//       setPagination({
//         ...pagination,
//         current: page,
//         pageSize: pageSize,
//       });

//       const updatedParams = {
//         ...params,
//         limit: pageSize,
//         page: page,
//       };
//       setParams(updatedParams);
//     },
//   };

//   const onSelectChange = (newSelectedRowKeys, selectedRow) => {
//     setSelectedRowKeys(newSelectedRowKeys);
//     setSelectedLicense(selectedRow);
//   };

//   const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
//     renderCell: (checked, record, index, originNode) => {
//       if (record.Used === "Yes") {
//         return null;
//       }
//       return originNode;
//     },
//   };

//   const onRow = (record, rowIndex) => {
//     return {
//       onClick: (event) => {
//         // navigate(`/license/information/${record?.ItemNo}`);
//         setIsModalOpen(true);
//         setItemNo(record?.ItemNo);
//       },
//     };
//   };

//   const onDeleteConfirm = async () => {
//     try {
//       if (selectedRowKeys.length === 1) {
//         await deleteData();
//       } else {
//         await deleteMultiple({ ids: selectedRowKeys });
//       }
//     } catch (error) {
//       openErrorNotification("Something went wrong.");
//     }
//     setShowModal(false);
//     setSelectedRowKeys([]);
//   };

//   useEffect(() => {
//     if (location.pathname.includes("orderedItems")) {
//       setSelectedStep(2);
//     }
//   }, []);

//   useEffect(() => {
//     orderedLicense({ email: customerInfo?.email });
//   }, [params]);

//   useEffect(() => {
//     if (deletedData && !deletedDataError) {
//       openConfirmNotification("Success");
//     } else if (deletedDataError) {
//       openErrorNotification("Something went wrong.");
//     }
//   }, [deletedData, deletedDataError]);

//   useEffect(() => {
//     if (data && !loading) {
//       const newData = data?.data?.map((el) => ({
//         ...el,
//         key: el?.ItemNo,
//       }));
//       setListData(newData);
//     }
//   }, [data, loading]);

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return loading ? (
//     <div className="w-full flex items-center justify-center h-screen">
//       <Spinner />
//     </div>
//   ) : (
//     <div className="min-h-screen bg-[#F2F2F2]">
//       {/* TopBar */}
//       <div className="flex justify-between items-center h-[55px] bg-white mb-2 border-b-[1px]">
//         {/* <SicunetLogo className="ml-12" /> */}

//         <div
//           style={{
//             height: "32px",
//             width: "140px",
//             cursor: "pointer",
//             marginLeft: "16px",
//           }}
//         >
//           <img
//             src={logoPath}
//             alt="Logo"
//             style={{ height: "100%", width: "100%", objectFit: "contain" }}
//           />
//         </div>
//         <div className="mr-4">
//           <div className="ml-4">
//             <User />
//           </div>
//         </div>
//       </div>

//       {/* Welcome Section */}
//       {/* <WelcomeBar /> */}

//       <ConfirmModal
//         showModal={showModal}
//         setShowModal={setShowModal}
//         onDeleteConfirm={onDeleteConfirm}
//       />
//       <Modal
//         open={isModalOpen}
//         onCancel={handleCancel}
//         width={1000}
//         footer={null}
//         centered
//       >
//         <OrderedItemForm itemNo={itemNo} />
//       </Modal>
//       <div className="bg-white px-8 p-2 rounded-sm mx-3 mb-2">
//         <StepperComponent />
//         <div className="flex justify-between pt-8 rounded-md mb-5">
//           <Button
//             // type="primary"
//             onClick={() => navigate("/setUpgrade")}
//             className="px-4 py-1 rounded-md text-[#2270B8] bg-white hover:!text-[#2270B8] hover:!bg-white !border-[#2270B8] flex items-center
//               !box-sizing-border !transition-none"
//           >
//             <span id="arrowIcon" className="mr-1">
//               <ArrowLeftOutlined />
//             </span>
//             <span className="text-[14px]">Previous</span>
//           </Button>

//           <Button
//             // type="primary"
//             onClick={() => navigate("/newLicense")}
//             disabled={selectedRowKeys.length === 0}
//             className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!text-white hover:!bg-[#2270B8] flex items-center !border-none !box-sizing-border !transition-none disabled:hover:!cursor-not-allowed"
//           >
//             <span className="text-[14px]">Next</span>
//             <span id="arrowIcon" className="ml-1">
//               <ArrowRightOutlined />
//             </span>
//           </Button>
//         </div>
//       </div>
//       <div className="bg-white px-8 px-2 pb-12 rounded-sm mx-3">
//         <p className="text-center text-[#808080] text-[16px] py-6">
//           Your Ordered Licenses
//         </p>
//         <GlobalTable
//           columns={OrderItemColumn(
//             setParams,
//             params,
//             setIsModalOpen,
//             setItemNo
//           )}
//           dataSource={listData}
//           rowSelection={rowSelection}
//           pagination={paginationConfig}
//           // onRow={onRow}
//           loading={loading}
//         />
//       </div>
//     </div>
//   );
// };

import { useContext, useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import {
  openConfirmNotification,
  openErrorNotification,
} from "../../../global/utils/toast";
import { OrderItemColumn } from "./Column";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import usePost from "../../../global/hooks/usePost";
import { LicenseContext, StepperContext } from "../Context/LicenseProvider";
import useOemData from "../../../global/hooks/useOemData";
import { User } from "../../../global/components/User";
import { Button, Modal } from "antd";
import { OrderedItemForm } from "../Components/OrderedItemForm";
import { StepperComponent } from "../../../global/components/StepperComponent";

export const OrderedItem = () => {
  const { oemNo, oemData } = useOemData();
  const location = useLocation();
  const { setSelectedStep } = useContext(StepperContext);
  const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);
  const userInfo = sessionStorage.getItem("customerInfo");
  const customerInfo = JSON.parse(userInfo);
  const { email, mac, product } = customerInfo;

  const { setSelectedLicense } = useContext(LicenseContext);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemNo, setItemNo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "ItemNo",
    order: "desc",
  });

  // console.log({ selectedLicense });

  const navigate = useNavigate();

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/license/ordered?${query}`
    : `${process.env.REACT_APP_API_URL}/api/license/ordered`;
  const { data, loading, error, postData: orderedLicense } = usePost(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitems/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys, selectedRow) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedLicense(selectedRow);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    renderCell: (checked, record, index, originNode) => {
      if (record.Used === "Yes") {
        return null;
      }
      return originNode;
    },
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // navigate(`/license/information/${record?.ItemNo}`);
        setIsModalOpen(true);
        setItemNo(record?.ItemNo);
      },
    };
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (location.pathname.includes("orderedItems")) {
      setSelectedStep(2);
    }
  }, []);

  useEffect(() => {
    orderedLicense({ email: customerInfo?.email });
  }, [params]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
    } else if (deletedDataError) {
      openErrorNotification("Something went wrong.");
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.ItemNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen bg-[#F2F2F2]">
      {/* TopBar */}
      <div className="flex justify-between items-center h-[55px] bg-white mb-2 border-b-[1px]">
        {/* <SicunetLogo className="ml-12" /> */}

        <div
          style={{
            height: "32px",
            width: "140px",
            cursor: "pointer",
            marginLeft: "16px",
          }}
        >
          <img
            src={logoPath}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="mr-4">
          <div className="ml-4">
            <User />
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      {/* <WelcomeBar /> */}

      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={900}
        footer={null}
        closeIcon={
          <span
            style={{
              marginTop: "-22px",
              padding: "8px",
              fontSize: "16px",
              borderRadius: "50%",
              cursor: "pointer",
              transition: "background 0.3s",
            }}
          >
            ✖
          </span>
        }
        centered
      >
        <OrderedItemForm itemNo={itemNo} />
      </Modal>
      <div className="px-5 py-5">
        <div className="bg-white px-8 p-2 rounded-md mx-3 mb-2">
          <StepperComponent />
          <div className="flex justify-between pt-8 rounded-md mb-5">
            <Button
              // type="primary"
              onClick={() => navigate("/setUpgrade")}
              className="reset-button "
            >
              <span id="arrowIcon" className="mr-1">
                <ArrowLeftOutlined />
              </span>
              <span className="text-[14px]">Previous</span>
            </Button>

            <Button
              // type="primary"
              onClick={() => navigate("/newLicense")}
              disabled={selectedRowKeys.length === 0}
              className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!text-white hover:!bg-[#2270B8] flex items-center !border-none !box-sizing-border !transition-none disabled:hover:!cursor-not-allowed"
            >
              <span className="text-[14px]">Next</span>
              <span id="arrowIcon" className="ml-1">
                <ArrowRightOutlined />
              </span>
            </Button>
          </div>
        </div>
        <div className="bg-white  mt-4  rounded-sm mx-3 mb-2">
          {" "}
          {/* <p className="text-center text-[#808080] text-[16px] py-6">
          Your Ordered Licenses
        </p> */}
          <div className="flex items-center bg-gray-200 p-2.5  ">
            <DesktopOutlined className="text-[18px] text-black" />

            <span className="text-[.935rem] text-black font-['Poppins'] ml-2 font-medium">
              Ordered Licenses
            </span>
          </div>
          <div className="mt-4 px-4 pb-2 ">
            <GlobalTable
              columns={OrderItemColumn(
                setParams,
                params,
                setIsModalOpen,
                setItemNo
              )}
              dataSource={listData}
              rowSelection={rowSelection}
              pagination={paginationConfig}
              // onRow={onRow}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
