import React, { useContext, useEffect, useState } from "react";
import useOemData from "../../../global/hooks/useOemData";
import { User } from "../../../global/components/User";
import { Button, Divider, Modal } from "antd";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  DesktopOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import SideCards from "../Components/SideCards";
import WelcomeBar from "../Components/WelcomeBar";
import MacIcon from "../../../assets/images/vectors/MacIcon";
import ProductIcon from "../../../assets/images/vectors/ProductIcon";
import ModelIcon from "../../../assets/images/vectors/ModelIcon";
import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
import OemIcon from "../../../assets/images/vectors/OemIcon";
import TypeIcon from "../../../assets/images/vectors/TypeIcon";
import CameraIcon from "../../../assets/images/vectors/CameraIcon";
import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
import usePost from "../../../global/hooks/usePost";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { openErrorNotification, showToast } from "../../../global/utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import CheckIcon from "../../../assets/images/vectors/CheckIcon";
import { StepperContext } from "../../UpgradeLicense/Context/LicenseProvider";
import { StepperComponent } from "../../../global/components/StepperComponent";
import DeviceIcon from "../../../assets/images/vectors/SideBarIcons/DeviceIcon";

const InitialLicense = ({ type }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { oemNo } = useOemData();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
  const { email, mac } = customerInfo;
  const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);

  const initialLicenseUrl = `${process.env.REACT_APP_API_URL}/api/initial/info`;
  const deviceUrl =
    type == "upgrade" && `${process.env.REACT_APP_API_URL}/api/device/${mac}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/device/create`;
  const [isApplied, setIsApplied] = useState();

  const {
    data: deviceData,
    loading: deviceDataLoading,
    error: deviceDataError,
  } = useGet(deviceUrl);

  const {
    data: initialLicenseData,
    loading: initialLicenseLoading,
    error: initialLicenseError,
    postData: initialLicenseAction,
  } = usePost(initialLicenseUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const { setSelectedStep } = useContext(StepperContext);

  //User guide modal content
  const tabItems = [
    {
      key: 1,
      text: (
        <div className="text-center mt-4">
          <p className="font-medium text-[20px] ">
            Welcome to License Server!{" "}
          </p>
          <p className="text-[14px] text-[#606060] mt-2 mb-6">
            This guide will walk you through the steps. <br /> At first, You can
            see your current license details
          </p>
        </div>
      ),
    },
    {
      key: 2,
      text: (
        <div className="text-center mt-8">
          <p className="text-[14px] text-[#606060] mb-6">
            Choose the licenses you want to upgrade and you can proceed to the
            next step
          </p>
        </div>
      ),
    },
    {
      key: 3,
      text: (
        <div className="text-center mt-8">
          <p className="text-[14px] text-[#606060] mb-6">
            Review the comparison and click{" "}
            <span className="font-medium">"Apply"</span> to confirm the upgrade.
          </p>
        </div>
      ),
    },
  ];

  const handleApply = () => {
    const values = {
      Mac: mac,
      Product: initialLicenseData?.data[0].Product,
      Model: initialLicenseData?.data[0].Model,
      Type: initialLicenseData?.data[0].Type,
      Options: initialLicenseData?.data[0].Options,
      OemNo: initialLicenseData?.data[0].OemNo,
      Camera: initialLicenseData?.data[0].Camera,
      Channel: initialLicenseData?.data[0].Channel,
      Lockset: initialLicenseData?.data[0].Lockset,
      Facegate: initialLicenseData?.data[0].Facegate,
      Intercom: initialLicenseData?.data[0].Intercom,
      Subnode: initialLicenseData?.data[0].Subnode,
      ContLock: initialLicenseData?.data[0].ContLock,
    };
    try {
      postAction(values);
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleNext = () => {
    if (currentTabIndex < tabItems.length - 1) {
      setCurrentTabIndex(currentTabIndex + 1);
    } else {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (email && type == "initial") {
      initialLicenseAction({ email });
    }
  }, []);

  //toast for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      // showToast(postData?.message, "success", faCheckCircle);
      setIsApplied(true);
    }
  }, [postError, postData, postLoading]);

  useEffect(() => {
    if (location.pathname.includes("setUpgrade")) {
      setSelectedStep(1);
    }
  }, []);

  return (
    <div className="min-h-screen bg-[#F2F2F2] ">
      {/* TopBar */}
      <div className="flex justify-between items-center h-[55px] bg-white pl-4  border-b-[1px]">
        {/* <SicunetLogo className="ml-12" /> */}

        <div
          style={{
            height: "32px",
            width: "140px",
            cursor: "pointer",
          }}
        >
          <img
            src={logoPath}
            alt="Logo"
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div className="mr-4">
          <div className="ml-4">
            <User />
          </div>
        </div>
      </div>

      {/* Welcome Section */}
      {/* <WelcomeBar /> */}

      {/* Summary Section */}
      <div className="px-8 py-6">
        {isApplied ? (
          <div className="w-[100%] bg-white rounded-sm flex flex-col justify-center items-center gap-4">
            <CheckIcon />
            <p className="text-[#34B847] text-[20px] font-semibold">
              Your License Has Been Created Successfully
            </p>
            <p className="text-center text-[#808080] text-[13px] font-semibold">
              {`To retrieve your license key, go to Jupiter, navigate to System > License > Edit, and click Get License Key`}
            </p>
            <p className="text-center text-[#808080] text-[13px] font-semibold">
              {" "}
              Once retrieved, click Apply to update your license and see the
              changes
            </p>
          </div>
        ) : (
          <>
            {type === "initial" ? (
              <div className="flex py-4 justify-between items-center rounded-md">
                <p className="text-black text-[16px]">
                  Current License Information
                </p>
                <div className="flex space-x-2">
                  <Button
                    // type="primary"
                    className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!border-none hover:!bg-[#2270B8] flex items-center 
             !border-none !box-sizing-border !transition-none"
                    onClick={handleApply}
                  >
                    <span className="text-[14px]">Apply</span>
                    <span id="arrowIcon" className="ml-1 mt-1">
                      <CheckCircleOutlined />
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="w-[100%] bg-white px-6 py-3 rounded-md ">
                <StepperComponent />
                <div className="flex justify-end space-x-2 mt-8 mb-3">
                  <Button
                    type="primary"
                    className="mr-2 w-[104px] h-[30px] font-medium"
                    onClick={() => navigate("/orderedItems")}
                  >
                    <span className="text-[14px]">Next</span>
                    <span id="arrowIcon" className="ml-1">
                      <ArrowRightOutlined />
                    </span>
                  </Button>
                </div>
              </div>
            )}

            <div className="mt-4 overflow-hidden border bg-white rounded-[4px]">
              <div className="flex items-center bg-gray-200 p-2.5">
                <DeviceIcon className="text-[18px] text-black" />

                <span className="text-[.935rem] text-black font-['Poppins'] ml-2 font-medium">
                  Current License for :
                </span>

                <span className="text-[.935rem] text-customColor font-['Poppins'] ml-2 font-semibold">
                  {mac}
                </span>
              </div>
            </div>

            <div className="mt-4 overflow-hidden border bg-white  rounded-[4px]">
              <div className="bg-white   rounded-xl ">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <InfoCircleOutlined className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Information
                  </span>
                </div>
                <div className="p-2 ">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-1.5 rounded-xl">
                        <ProductIcon />
                      </span>
                      <span className="text-black font-[Poppins] mx-2">
                        Product :
                      </span>{" "}
                      {type === "initial"
                        ? initialLicenseData?.data[0].ProductName
                        : deviceData?.data.ProductName}
                    </p>

                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <ModelIcon />
                      </span>
                      <span className="text-black font-[Poppins] mx-2">
                        Model :
                      </span>{" "}
                      {type === "initial"
                        ? initialLicenseData?.data[0].ModelName
                        : deviceData?.data.ModelName}
                    </p>

                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-2 rounded-xl">
                        <TypeIcon />
                      </span>
                      <span className="text-black font-[Poppins] mx-2">
                        Type :
                      </span>{" "}
                      {type === "initial"
                        ? initialLicenseData?.data[0].Type
                        : deviceData?.data?.Type}
                    </p>

                    <p className="text-sm p-2 flex items-center">
                      <span className="bg-customColor_10 p-1.5 rounded-xl">
                        <OemIcon />
                      </span>
                      <span className="text-black font-[Poppins] mx-2">
                        OEM :
                      </span>{" "}
                      {type === "initial"
                        ? initialLicenseData?.data[0].OemName
                        : deviceData?.data?.OemName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
              <div className="flex items-center bg-gray-200 p-2.5">
                <SettingOutlined className="text-[18px] text-black " />
                <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                  License Options
                </span>
              </div>{" "}
              <div className="p-2">
                <p className="text-sm p-2 flex items-center">
                  <span className="bg-customColor_10 p-2 rounded-xl">
                    <OptionsIcon />
                  </span>
                  <span className="text-black font-[Poppins] mx-2">
                    Options :
                  </span>{" "}
                  {type === "initial"
                    ? initialLicenseData?.data[0].OptionNames?.map((option) => {
                        return (
                          <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1 ">
                            {option}
                          </span>
                        );
                      })
                    : deviceData?.data?.OptionNames_arr?.map((option) => {
                        return (
                          <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
                            {option}
                          </span>
                        );
                      })}
                </p>
              </div>
            </div>

            <div className="mt-4 overflow-hidden border bg-white  rounded-[4px]">
              <div className="bg-white   rounded-xl ">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <DesktopOutlined className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Devices
                  </span>
                </div>
                <div className="p-2 ">
                  <div className="grid grid-cols-1 pl-2 md:grid-cols-2 gap-2">
                    {/* Left Column */}
                    <div>
                      <p className="text-sm py-1 flex items-center">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <CameraIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Camera :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Camera
                          : deviceData?.data?.Camera}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <TrilogyIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Lockset :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Lockset
                          : deviceData?.data?.Lockset}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <SubnodeIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          OSDP Device :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Subnode
                          : deviceData?.data?.Subnode}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <IntercomIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Intercom :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Intercom
                          : deviceData?.data?.Intercom}
                      </p>
                    </div>

                    {/* Right Column */}
                    <div>
                      <p className="text-sm py-1 flex items-center">
                        <span className="bg-customColor_10 p-1.5 rounded-xl">
                          <ChannelIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Channel :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Channel
                          : deviceData?.data?.Channel}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <FacegateIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Facegate :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Facegate
                          : deviceData?.data?.Facegate}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <TrilogyIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Trilogy Lockset :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].ContLock
                          : deviceData?.data?.ContLock}
                      </p>

                      <p className="text-sm py-1 flex items-center mt-2">
                        <span className="bg-customColor_10 p-2 rounded-xl">
                          <IntercomIcon />
                        </span>
                        <span className="text-black font-[Poppins] mx-2">
                          Directory :
                        </span>{" "}
                        {type === "initial"
                          ? initialLicenseData?.data[0].Sicucom
                          : deviceData?.data?.Sicucom}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* CARD SECTION STARTS HERE */}
        {/* <SideCards /> */}

        {/* {type === "upgrade" && (
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            centered
            okText={currentTabIndex === tabItems.length - 1 ? "Done" : "Next"}
            cancelText="Skip"
            onOk={handleNext}
          >
            {tabItems[currentTabIndex].text}
          </Modal>
        )} */}
      </div>
    </div>
  );
};

export default InitialLicense;

// import React, { useContext, useEffect, useState } from "react";
// import useOemData from "../../../global/hooks/useOemData";
// import { User } from "../../../global/components/User";
// import { Button, Divider, Modal } from "antd";
// import {
//   ArrowRightOutlined,
//   CheckCircleOutlined,
//   DesktopOutlined,
//   InfoCircleOutlined,
//   SettingOutlined,
// } from "@ant-design/icons";
// import SideCards from "../Components/SideCards";
// import WelcomeBar from "../Components/WelcomeBar";
// import MacIcon from "../../../assets/images/vectors/MacIcon";
// import ProductIcon from "../../../assets/images/vectors/ProductIcon";
// import ModelIcon from "../../../assets/images/vectors/ModelIcon";
// import OptionsIcon from "../../../assets/images/vectors/OptionsIcon";
// import OemIcon from "../../../assets/images/vectors/OemIcon";
// import TypeIcon from "../../../assets/images/vectors/TypeIcon";
// import CameraIcon from "../../../assets/images/vectors/CameraIcon";
// import TrilogyIcon from "../../../assets/images/vectors/TrilogyIcon";
// import SubnodeIcon from "../../../assets/images/vectors/SubnodeIcon";
// import IntercomIcon from "../../../assets/images/vectors/IntercomIcon";
// import ChannelIcon from "../../../assets/images/vectors/ChannelIcon";
// import FacegateIcon from "../../../assets/images/vectors/FacegateIcon";
// import usePost from "../../../global/hooks/usePost";
// import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
// import { openErrorNotification, showToast } from "../../../global/utils/toast";
// import { useLocation, useNavigate } from "react-router-dom";
// import useGet from "../../../global/hooks/useGet";
// import CheckIcon from "../../../assets/images/vectors/CheckIcon";
// import { StepperContext } from "../../UpgradeLicense/Context/LicenseProvider";
// import { StepperComponent } from "../../../global/components/StepperComponent";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCamera,
//   faLock,
//   faSatelliteDish,
//   faPhone,
//   faBroadcastTower,
//   faUserShield,
//   faKey,
//   faPhoneVolume,
//   faHardHat,
//   faDesktop,
//   faTag,
//   faLaptop,
// } from "@fortawesome/free-solid-svg-icons";
// import DeviceLogIcon from "../../../assets/images/vectors/SideBarIcons/DeviceLogIcon";
// const InitialLicense = ({ type }) => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { oemNo } = useOemData();
//   const [isModalOpen, setIsModalOpen] = useState(true);
//   const [currentTabIndex, setCurrentTabIndex] = useState(0);
//   const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
//   const { email, mac } = customerInfo;
//   const logoPath = require(`../../../oem/${oemNo}/images/mainLogo.png`);

//   const initialLicenseUrl = `${process.env.REACT_APP_API_URL}/api/initial/info`;
//   const deviceUrl =
//     type == "upgrade" && `${process.env.REACT_APP_API_URL}/api/device/${mac}`;
//   const postUrl = `${process.env.REACT_APP_API_URL}/api/device/create`;
//   const [isApplied, setIsApplied] = useState();

//   const {
//     data: deviceData,
//     loading: deviceDataLoading,
//     error: deviceDataError,
//   } = useGet(deviceUrl);

//   const {
//     data: initialLicenseData,
//     loading: initialLicenseLoading,
//     error: initialLicenseError,
//     postData: initialLicenseAction,
//   } = usePost(initialLicenseUrl);

//   const {
//     data: postData,
//     loading: postLoading,
//     error: postError,
//     postData: postAction,
//   } = usePost(postUrl);

//   const { setSelectedStep } = useContext(StepperContext);

//   //User guide modal content
//   const tabItems = [
//     {
//       key: 1,
//       text: (
//         <div className="text-center mt-4">
//           <p className="font-medium text-[20px] ">
//             Welcome to License Server!{" "}
//           </p>
//           <p className="text-[14px] text-[#606060] mt-2 mb-6">
//             This guide will walk you through the steps. <br /> At first, You can
//             see your current license details
//           </p>
//         </div>
//       ),
//     },
//     {
//       key: 2,
//       text: (
//         <div className="text-center mt-8">
//           <p className="text-[14px] text-[#606060] mb-6">
//             Choose the licenses you want to upgrade and you can proceed to the
//             next step
//           </p>
//         </div>
//       ),
//     },
//     {
//       key: 3,
//       text: (
//         <div className="text-center mt-8">
//           <p className="text-[14px] text-[#606060] mb-6">
//             Review the comparison and click{" "}
//             <span className="font-medium">"Apply"</span> to confirm the upgrade.
//           </p>
//         </div>
//       ),
//     },
//   ];

//   const handleApply = () => {
//     const values = {
//       Mac: mac,
//       Product: initialLicenseData?.data[0].Product,
//       Model: initialLicenseData?.data[0].Model,
//       Type: initialLicenseData?.data[0].Type,
//       Options: initialLicenseData?.data[0].Options,
//       OemNo: initialLicenseData?.data[0].OemNo,
//       Camera: initialLicenseData?.data[0].Camera,
//       Channel: initialLicenseData?.data[0].Channel,
//       Lockset: initialLicenseData?.data[0].Lockset,
//       Facegate: initialLicenseData?.data[0].Facegate,
//       Intercom: initialLicenseData?.data[0].Intercom,
//       Subnode: initialLicenseData?.data[0].Subnode,
//       ContLock: initialLicenseData?.data[0].ContLock,
//     };
//     try {
//       postAction(values);
//     } catch (error) {
//       openErrorNotification("Something went wrong.");
//       console.log(error);
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleNext = () => {
//     if (currentTabIndex < tabItems.length - 1) {
//       setCurrentTabIndex(currentTabIndex + 1);
//     } else {
//       setIsModalOpen(false);
//     }
//   };

//   useEffect(() => {
//     if (email && type == "initial") {
//       initialLicenseAction({ email });
//     }
//   }, []);

//   //toast for create
//   useEffect(() => {
//     if (postError) {
//       openErrorNotification(
//         postError?.response?.data?.message || "Something went wrong"
//       );
//     } else if (!postLoading && !postError && postData) {
//       // showToast(postData?.message, "success", faCheckCircle);
//       setIsApplied(true);
//     }
//   }, [postError, postData, postLoading]);

//   useEffect(() => {
//     if (location.pathname.includes("setUpgrade")) {
//       setSelectedStep(1);
//     }
//   }, []);
//   const deviceDataList = [
//     {
//       label: "Camera",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Camera
//           : deviceData?.data?.Camera,
//       icon: faCamera,
//       color: "text-blue-500 bg-blue-100",
//     },
//     {
//       label: "Channel",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Channel
//           : deviceData?.data?.Channel,
//       icon: faBroadcastTower,
//       color: "text-yellow-500 bg-yellow-100",
//     },
//     {
//       label: "Lockset",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Lockset
//           : deviceData?.data?.Lockset,
//       icon: faLock,
//       color: "text-red-500 bg-red-100",
//     },

//     {
//       label: "Facegate",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Facegate
//           : deviceData?.data?.Facegate,
//       icon: faUserShield,
//       color: "text-indigo-500 bg-indigo-100",
//     },
//     {
//       label: "OSDP Device",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Subnode
//           : deviceData?.data?.Subnode,
//       icon: faSatelliteDish,
//       color: "text-purple-500 bg-purple-100",
//     },
//     {
//       label: "Trilogy Lockset",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].ContLock
//           : deviceData?.data?.ContLock,
//       icon: faKey,
//       color: "text-pink-500 bg-pink-100",
//     },
//     {
//       label: "Intercom",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Intercom
//           : deviceData?.data?.Intercom,
//       icon: faPhone,
//       color: "text-green-500 bg-green-100",
//     },
//     {
//       label: "Sicunet Intercom",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Sicucom
//           : deviceData?.data?.Sicucom,
//       icon: faPhoneVolume,
//       color: "text-teal-500 bg-teal-100",
//     },
//   ];

//   const deviceInfo = [
//     {
//       label: "Product",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].ProductName
//           : deviceData?.data?.ProductName,
//       icon: faLaptop, // Changed icon for Product
//       color: "text-blue-500 bg-blue-100", // Retained blue color
//     },
//     {
//       label: "Model",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].ModelName
//           : deviceData?.data?.ModelName,
//       icon: faDesktop, // Changed icon for Model
//       color: "text-gray-500 bg-gray-100", // Changed to gray
//     },
//     {
//       label: "Type",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].Type
//           : deviceData?.data?.Type,
//       icon: faTag, // Changed icon for Type
//       color: "text-yellow-500 bg-yellow-100", // Changed to yellow
//     },

//     {
//       label: "OEM",
//       value:
//         type === "initial"
//           ? initialLicenseData?.data[0].OemName
//           : deviceData?.data?.OemName,
//       icon: faHardHat, // Changed icon for OEM
//       color: "text-green-500 bg-green-100", // Retained green color
//     },
//   ];

//   return (
//     <div className="min-h-screen bg-[#F2F2F2] ">
//       {/* TopBar */}
//       <div className="flex justify-between items-center h-[55px] bg-white pl-4 mb-2 border-b-[1px]">
//         {/* <SicunetLogo className="ml-12" /> */}

//         <div
//           style={{
//             height: "32px",
//             width: "140px",
//             cursor: "pointer",
//           }}
//         >
//           <img
//             src={logoPath}
//             alt="Logo"
//             style={{ height: "100%", width: "100%", objectFit: "contain" }}
//           />
//         </div>
//         <div className="mr-4">
//           <div className="ml-4">
//             <User />
//           </div>
//         </div>
//       </div>

//       {/* Welcome Section */}
//       {/* <WelcomeBar /> */}

//       {/* Summary Section */}
//       <div className="px-3">
//         {isApplied ? (
//           <div className="w-[100%] bg-white rounded-sm flex flex-col justify-center items-center gap-4">
//             <CheckIcon />
//             <p className="text-[#34B847] text-[20px] font-semibold">
//               Your License Has Been Created Successfully
//             </p>
//             <p className="text-center text-[#808080] text-[13px] font-semibold">
//               {`To retrieve your license key, go to Jupiter, navigate to System > License > Edit, and click Get License Key`}
//             </p>
//             <p className="text-center text-[#808080] text-[13px] font-semibold">
//               {" "}
//               Once retrieved, click Apply to update your license and see the
//               changes
//             </p>
//           </div>
//         ) : (
//           <>
//             {type === "initial" ? (
//               <div className="flex py-4 justify-between items-center rounded-md">
//                 <p className="text-black text-[16px]">
//                   Current License Information
//                 </p>
//                 <div className="flex space-x-2">
//                   <Button
//                     // type="primary"
//                     className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!border-none hover:!bg-[#2270B8] flex items-center
//              !border-none !box-sizing-border !transition-none"
//                     onClick={handleApply}
//                   >
//                     <span className="text-[14px]">Apply</span>
//                     <span id="arrowIcon" className="ml-1 mt-1">
//                       <CheckCircleOutlined />
//                     </span>
//                   </Button>
//                 </div>
//               </div>
//             ) : (
//               <div className="w-[100%] bg-white px-6 p-4 rounded-lg mb-2">
//                 <StepperComponent />
//                 <div className="flex justify-end space-x-2 mt-8 mb-3">
//                   <Button
//                     // type="primary"
//                     className="px-4 py-1 rounded-md text-white bg-[#2270B8] hover:!text-white hover:!border-none hover:!bg-[#2270B8] flex items-center
//              !border-none !box-sizing-border !transition-none"
//                     onClick={() => navigate("/orderedItems")}
//                   >
//                     <span className="text-[14px]">Next</span>
//                     <span id="arrowIcon" className="ml-1">
//                       <ArrowRightOutlined />
//                     </span>
//                   </Button>
//                 </div>
//               </div>
//             )}
//             <div className="space-y-4">
//               {/* Device Card */}
//               <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
//                 <div className="flex items-center bg-gray-200 p-2.5">
//                   <DeviceLogIcon className="text-[18px] text-black " />
//                   <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                     Device
//                   </span>
//                 </div>

//                 <div className="bg-white p-4 rounded-lg shadow-md">
//                   <div className="flex items-center space-x-4">
//                     <span className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 p-2 rounded-full">
//                       <MacIcon className="text-white text-xl" />
//                     </span>
//                     <div className="flex items-center space-x-2">
//                       <span className="text-sm font-semibold text-black">
//                         MAC :
//                       </span>
//                       <span className="text-black text-sm font-medium">
//                         {mac}
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* License Information Card */}

//               {/* License Options Card */}

//               {/* License Devices Card */}
//               <div className="bg-white p-4 rounded-xl shadow-lg">
//                 <div className="flex items-center bg-gray-200 p-2.5">
//                   <InfoCircleOutlined className="text-[18px] text-black " />
//                   <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                     License Information
//                   </span>
//                 </div>
//                 <Divider className="my-4" />

//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                   {deviceInfo.map(({ label, value, icon, color }, index) => (
//                     <div
//                       key={index}
//                       className="p-3 bg-customColor_10 rounded-xl shadow-md border-2 border-customColor_40 flex items-center justify-center space-x-3 text-black transition-transform transform hover:scale-105"
//                     >
//                       {/* Icon inside a circular background */}
//                       <div
//                         className={`w-10 h-10 flex items-center justify-center rounded-full ${color} shadow-md`}
//                       >
//                         <FontAwesomeIcon icon={icon} className="w-6 h-6" />
//                       </div>

//                       {/* Label and Value on the same line */}
//                       <span className="font-poppins text-lg font-semibold">
//                         {label}:
//                       </span>
//                       <span className="font-poppins text-lg font-medium">
//                         {value}
//                       </span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               {/* <div className="bg-white p-6 rounded-xl shadow-lg">
//                 <div className="text-lg font-semibold text-gray-800 mb-4">
//                   License Devices
//                 </div>
//                 <Divider className="my-4" />
//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                   {[
//                     {
//                       label: "Camera",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Camera
//                           : deviceData?.data?.Camera,
//                       Icon: CameraIcon,
//                     },
//                     {
//                       label: "Lockset",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Lockset
//                           : deviceData?.data?.Lockset,
//                       Icon: TrilogyIcon,
//                     },
//                     {
//                       label: "Subnode",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Subnode
//                           : deviceData?.data?.Subnode,
//                       Icon: SubnodeIcon,
//                     },
//                     {
//                       label: "Intercom",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Intercom
//                           : deviceData?.data?.Intercom,
//                       Icon: IntercomIcon,
//                     },
//                     {
//                       label: "Channel",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Channel
//                           : deviceData?.data?.Channel,
//                       Icon: ChannelIcon,
//                     },
//                     {
//                       label: "Facegate",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Facegate
//                           : deviceData?.data?.Facegate,
//                       Icon: FacegateIcon,
//                     },
//                     {
//                       label: "Trilogy Lockset",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].ContLock
//                           : deviceData?.data?.ContLock,
//                       Icon: TrilogyIcon,
//                     },
//                     {
//                       label: "Sicunet Intercom",
//                       value:
//                         type === "initial"
//                           ? initialLicenseData?.data[0].Sicucom
//                           : deviceData?.data?.Sicucom,
//                       Icon: IntercomIcon,
//                     },
//                   ].map(({ label, value, Icon }, index) => (
//                     <div
//                       key={index}
//                       className="p-4 text-center justify-center bg-customColor_10 rounded-xl shadow-md border-2 border-customColor_20 flex items-center space-x-3 text-black transition-transform transform hover:scale-105"
//                     >
//                       <Icon className="w-8 h-8 text-black" />
//                       <span className="font-poppins text-lg font-semibold">
//                         {label}:
//                       </span>
//                       <span className="font-poppins text-lg font-medium">
//                         {value}
//                       </span>
//                     </div>
//                   ))}
//                 </div>
//               </div> */}
//               <div className="bg-white p-3 rounded-xl shadow-lg">
//                 <div className="flex items-center bg-gray-200 p-2.5">
//                   <DesktopOutlined className="text-[18px] text-black " />
//                   <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                     License Devices
//                   </span>
//                 </div>
//                 <Divider className="my-4" />
//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
//                   {deviceDataList.map(
//                     ({ label, value, icon, color }, index) => (
//                       <div
//                         key={index}
//                         className="p-3 bg-customColor_10 rounded-xl shadow-md border-2 border-customColor_40 flex items-center justify-center space-x-3 text-black transition-transform transform hover:scale-105"
//                       >
//                         {/* Icon inside a circular background */}
//                         <div
//                           className={`w-10 h-10 flex items-center justify-center rounded-full ${color} shadow-md`}
//                         >
//                           <FontAwesomeIcon icon={icon} className="w-6 h-6" />
//                         </div>

//                         {/* Label and Value on the same line */}
//                         <span className="font-poppins text-lg font-semibold">
//                           {label}:
//                         </span>
//                         <span className="font-poppins text-lg font-medium">
//                           {value}
//                         </span>
//                       </div>
//                     )
//                   )}
//                 </div>
//               </div>
//               <div className="mt-4 bg-white border rounded-[4px] overflow-hidden">
//                 <div className="bg-white p-4 rounded-lg shadow-md">
//                   <div className="flex items-center bg-gray-200 p-2.5">
//                     <SettingOutlined className="text-[18px] text-black " />
//                     <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
//                       License Options
//                     </span>
//                   </div>

//                   <div className="bg-white p-4 rounded-lg shadow-md">
//                     <div className="flex items-center space-x-3">
//                       <span className="bg-gradient-to-r from-blue-400 to-green-400 p-1.5 rounded-xl text-white">
//                         <OptionsIcon />
//                       </span>
//                       <span className="text-black font-semibold">
//                         Options:
//                       </span>
//                     </div>
//                     <div className="flex flex-wrap gap-2 mt-3">
//                       {type === "initial"
//                         ? initialLicenseData?.data[0].OptionNames?.map(
//                             (option, index) => (
//                               <span
//                                 key={index}
//                                 className="px-3 py-1 bg-gray-200/70 text-sm text-[#333] rounded-md shadow-sm hover:bg-gray-300 transition-all duration-200"
//                               >
//                                 {option}
//                               </span>
//                             )
//                           )
//                         : deviceData?.data?.OptionNames_arr?.map(
//                             (option, index) => (
//                               <span
//                                 key={index}
//                                 className="px-3 py-1 bg-gray-200/70 text-sm text-[#333] rounded-md shadow-sm hover:bg-gray-300 transition-all duration-200"
//                               >
//                                 {option}
//                               </span>
//                             )
//                           )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
//               <div className="text-[14px] font-semibold">Device</div>
//               <Divider className="my-3" />

//               <div className="flex">
//                 <p className="text-sm flex items-center py-1">
//                   <span className="bg-customColor_10 p-2 rounded-xl">
//                     <MacIcon />
//                   </span>
//                   <span className="text-black font-[Poppins] mx-2">MAC:</span>{" "}
//                   {mac}
//                 </p>
//               </div>
//             </div>

//             <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
//               <div className="text-[14px] font-semibold">
//                 License Information
//               </div>
//               <Divider className="my-3" />

//               <div className="flex">
//                 <div className="w-1/2">
//                   <p className="text-sm py-1 flex items-center">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <ProductIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Product:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].ProductName
//                       : deviceData?.data.ProductName}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <ModelIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Model:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].ModelName
//                       : deviceData?.data.ModelName}
//                   </p>
//                 </div>
//                 <div className="w-1/2">
//                   <p className="text-sm py-1 flex items-center">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <TypeIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Type:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Type
//                       : deviceData?.data?.Type}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <OemIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2 ">
//                       OEM:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].OemName
//                       : deviceData?.data?.OemName}
//                   </p>
//                 </div>
//               </div>
//             </div>

//             <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
//               <div className="text-[14px] font-semibold">License Options</div>
//               <Divider className="my-3" />
//               <div>
//                 <p className="text-sm p-2 flex items-center">
//                   <span className="bg-customColor_10 p-2 rounded-xl">
//                     <OptionsIcon />
//                   </span>
//                   <span className="text-black font-[Poppins] mx-2">
//                     Options:
//                   </span>{" "}
//                   {type == "initial"
//                     ? initialLicenseData?.data[0].OptionNames?.map((option) => {
//                         return (
//                           <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1 ">
//                             {option}
//                           </span>
//                         );
//                       })
//                     : deviceData?.data?.OptionNames_arr?.map((option) => {
//                         return (
//                           <span className="px-2 py-1 bg-gray-200/55 rounded-md mr-1">
//                             {option}
//                           </span>
//                         );
//                       })}
//                 </p>
//               </div>
//             </div>

//             <div className="w-[100%] bg-white px-6 p-4 rounded-sm mb-2">
//               <div className="text-[14px] font-semibold">License Devices</div>
//               <Divider className="my-3" />
//               <div className="flex">
//                 <div className="w-1/2">
//                   <p className="text-sm py-1 flex items-center">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <CameraIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Camera:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Camera
//                       : deviceData?.data?.Camera}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <TrilogyIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Lockset:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Lockset
//                       : deviceData?.data?.Lockset}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <SubnodeIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Subnode:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Subnode
//                       : deviceData?.data?.Subnode}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <IntercomIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Intercom:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Intercom
//                       : deviceData?.data?.Intercom}
//                   </p>
//                 </div>
//                 <div className="w-1/2">
//                   <p className="text-sm py-1 flex items-center">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <ChannelIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Channel:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Channel
//                       : deviceData?.data?.Channel}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <FacegateIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Facegate:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Facegate
//                       : deviceData?.data?.Facegate}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <TrilogyIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Trilogy Lockset:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].ContLock
//                       : deviceData?.data?.ContLock}
//                   </p>
//                   <p className="text-sm py-1 flex items-center mt-2">
//                     <span className="bg-customColor_10 p-2 rounded-xl">
//                       <IntercomIcon />
//                     </span>
//                     <span className="text-black font-[Poppins] mx-2">
//                       Sicunet Intercom:
//                     </span>{" "}
//                     {type == "initial"
//                       ? initialLicenseData?.data[0].Sicucom
//                       : deviceData?.data?.Sicucom}
//                   </p>
//                 </div>
//               </div>
//             </div> */}
//           </>
//         )}

//         {/* CARD SECTION STARTS HERE */}
//         {/* <SideCards /> */}

//         {/* {type === "upgrade" && (
//           <Modal
//             open={isModalOpen}
//             onCancel={handleCancel}
//             centered
//             okText={currentTabIndex === tabItems.length - 1 ? "Done" : "Next"}
//             cancelText="Skip"
//             onOk={handleNext}
//           >
//             {tabItems[currentTabIndex].text}
//           </Modal>
//         )} */}
//       </div>
//     </div>
//   );
// };

// export default InitialLicense;
