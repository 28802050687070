import { notification } from "antd";
import { toast } from "react-toastify";

export const showToast = (message, type = "default", icon = null) => {
  console.log("toast");
  const toastOptions = {
    icon,
  };

  switch (type) {
    case "success":
      toast.success(message, toastOptions);
      break;
    case "error":
      toast.error(message, toastOptions);
      break;
    case "info":
      toast.info(message, toastOptions);
      break;
    case "warning":
      toast.warn(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};
export const openErrorNotification = (message) => {
  notification.open({
    message: (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          whiteSpace: "nowrap", // Prevents text wrapping
        }}
      >
        ⚠️
        <span>{message}</span>
      </span>
    ),
    placement: "bottom",
    duration: 3,
    closeIcon: null,
    style: {
      width: "auto", // Ensures width adjusts based on content
      maxWidth: "100%", // Prevents overflow out of the viewport
      height: "auto", // Ensures height adjusts based on content
      padding: "10px 15px",
      fontSize: "14px",
      overflow: "visible", // Ensures content isn't clipped
    },
  });
};

export const openConfirmNotification = (message) => {
  notification.open({
    description: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px", // Reduced gap between icon and text
        }}
      >
        <span style={{ fontSize: "15px" }}>✅</span>
        <span style={{ fontSize: "16px" }}>{message}</span>
      </div>
    ),
    placement: "bottom",
    duration: 3,
    closeIcon: null,
    style: {
      padding: "0px 16px 8px 8px",
      width: "auto",
      maxWidth: "220px",
      borderRadius: "4px",
      textAlign: "center",
      margin: "0 auto",
    },
  });
};
