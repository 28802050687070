import { Button, Col, Input, Row, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import useGet from "../../../global/hooks/useGet";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";
import { MdOutlinePayments } from "react-icons/md";

export const OrderFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  allData,
  setFilter,
  setInvoiceModal,
}) => {
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const navigate = useNavigate();
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "items");
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const modifiedDealer = getModifiedResponse(optionData, "dealers");
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));

  const onSearch = (value) => {
    console.log("search:", value);
  };
  console.log(
    allData,
    allData?.some(
      (item) => selectedRowKeys.includes(item.key) && item.Paid === "Yes"
    )
  );

  return (
    <div className="">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderNo"
              placeholder="No"
              value={filter.OrderNo}
              onChange={handleFilterChange}
            />
          </Col>
          {![4, 5, 6, 7, 8, 9].includes(loggedInUser.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="OEM"
                optionFilterProp="OemNo"
                value={filter.OemNo || undefined}
                onChange={(value) => setFilter({ ...filter, OemNo: value })}
                onSearch={onSearch}
                options={modifiedOems}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {![7, 8, 9].includes(loggedInUser.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Dealer"
                optionFilterProp="DealerNo"
                value={filter.DealerNo || undefined}
                onChange={(value) => setFilter({ ...filter, DealerNo: value })}
                onSearch={onSearch}
                options={modifiedDealer}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {/* {loggedInUser.role_name != "DealerUser" && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Account"
                optionFilterProp="AccountNo"
                value={filter.AccountNo || undefined}
                onChange={(value) => setFilter({ ...filter, AccountNo: value })}
                // onSearch={onSearch}
                options={modifiedAccounts}
                style={{ width: "100%" }}
              />
            </Col>
          )} */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OrderName"
              placeholder="Name"
              value={filter.OrderName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Confirmed"
              optionFilterProp="Paid"
              value={filter.Paid || undefined}
              onChange={(value) => setFilter({ ...filter, Paid: value })}
              onSearch={onSearch}
              options={[
                {
                  value: "1",
                  label: "Yes",
                },
                {
                  value: "0",
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            {/* <Input
              className="input_text_3 mr10"
              placeholder="Order Time"
              value={filter.OrderTime}
              onChange={handleFilterChange}
              name="OrderTime"
            /> */}
            <CustomDateTimePicker
              name="OrderTime"
              placeholder="Order Time"
              format="YYYY-MM-DD HH:mm" // 24-hour format
              value={filter.OrderTime}
              onChange={(value) => setFilter({ ...filter, OrderTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
        </Row>
      </div>
      <div className="w-full overflow-x-auto">
        <div className="flex justify-between my-4 flex-nowrap min-w-[610px]">
          <div className="flex items-center">
            <Button
              type="primary"
              className="mr-2 w-[104px] h-[30px] font-medium"
              onClick={handleFilter}
            >
              <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
              Apply
            </Button>
            <Button className="reset-button " onClick={handleReset}>
              <HiArrowPath size={14} />
              Reset
            </Button>
            {[1, 2, 3].includes(loggedInUser.role_value) && (
              <Button
                type="primary"
                className="mr-2  h-[30px] font-medium"
                onClick={() => setInvoiceModal(true)}
                disabled={
                  selectedRowKeys?.length === 0 ||
                  allData?.some(
                    (item) =>
                      selectedRowKeys.includes(item.key) &&
                      item.Invoiced === "Yes"
                  )
                }
              >
                <MdOutlinePayments size={16} />
                Make Invoice
              </Button>
            )}
          </div>
          <div>
            <Button
              type="primary"
              className="mr-2 w-[32px] h-[32px]"
              onClick={() => navigate("/order/add")}
            >
              <PlusOutlined />
            </Button>
            <Button
              type="primary"
              className="w-[32px] h-[32px]"
              disabled={
                selectedRowKeys?.length === 0 ||
                allData?.some(
                  (item) =>
                    selectedRowKeys.includes(item.key) && item.Paid === "Yes"
                )
              }
              onClick={() => setShowModal(true)}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
