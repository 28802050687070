import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin, Modal } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../utils/toast";
import AuthLayout from "./AuthLayout";
// import "../styles/login.css";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import usePostLogin from "../hooks/usePostLogin";
import UserIcon from "../../assets/images/vectors/UserIcon";
import KeyIcon from "../../assets/images/vectors/KeyIcon";
import { LoadingOutlined } from "@ant-design/icons";
import { ResetPassword } from "./ResetPassword";
import Signup from "./Signup";
import LoginProfileIcon from "../../assets/images/vectors/LoginProfileIcon";
import PasswordIcon from "../../assets/images/vectors/PasswordIcon";

const Login = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/login/`;
  const securityCodeUrl = `${process.env.REACT_APP_API_URL}/api/auth/verify/`;
  const [isModalOpen, setIsModalOpen] = useState();
  const [isSecondModalOpen, setIsSecondModalOpen] = useState();
  const [registerModal, setRegisterModal] = useState(false);
  const [codePage, setCodePage] = useState(false);
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const [floading, setFloading] = useState(false);

  // Extract the token from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  localStorage.setItem("oemNo", oemNo);
  oemData && localStorage.setItem("oemData", JSON.stringify(oemData));
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const {
    data: codeData,
    loading: codeLoading,
    error: codeError,
    postData: sendCode,
  } = usePostLogin(securityCodeUrl);

  const navigate = useNavigate();

  //Forget password functionalities
  const handleForgetClick = async () => {
    try {
      // Get the userId field value from the form
      const userId = form.getFieldValue("userId");
      if (!userId || userId.trim() === "") {
        openErrorNotification(" Enter your username or email   first!");
        return;
      }
      // if (!userId) {
      //   showToast("Please enter your email or username!", "error", faWarning);
      //   return;
      // }

      setFloading(true);
      const postUrl = `${process.env.REACT_APP_API_URL}/api/sendemail`;

      // Send POST request
      const response = await fetch(postUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: userId }),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error("Failed to send email. Please try again.");
      }

      const data = await response.json();

      if (data?.message) {
        openConfirmNotification("Success");
        setIsModalOpen(true);
      } else {
        openErrorNotification(data?.Email || "Something went wrong");
      }
    } catch (error) {
      console.log(error);
      openErrorNotification(String(error) || "Something went wrong");
    }
    setFloading(false);
  };

  useEffect(() => {
    if (postData) {
      const { role_value, two_factor } = postData;
      if (two_factor) {
        setCodePage(true);
        setIsSecondModalOpen(true);
        // sessionStorage.setItem("userData", JSON.stringify(postData));
        openConfirmNotification("Success");
      } else {
        sessionStorage.setItem("userData", JSON.stringify(postData));
        openConfirmNotification("Success");
        if (role_value === 1 || role_value === 2 || role_value === 3) {
          navigate("/devicelist");
        } else if ([4, 5, 6, 7, 8, 9].includes(role_value)) {
          navigate("/orderlist");
        } else if ([10, 11, 12].includes(role_value)) {
          navigate("/orderitemlist");
        } else {
          openErrorNotification("Username or password is incorrect.");
          navigate("/");
        }
      }
    }
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [postData, postError]);

  useEffect(() => {
    if (codeData) {
      setCodePage(true);
      sessionStorage.setItem("userData", JSON.stringify(codeData));
      openConfirmNotification("Success");

      const { role_value } = codeData;

      if (role_value === 1 || role_value === 2 || role_value === 3) {
        navigate("/devicelist");
      } else if ([4, 5, 6, 7, 8, 9, 10, 11, 12].includes(role_value)) {
        navigate("/orderlist");
      } else {
        openErrorNotification("  Username or password is incorrect.");
        navigate("/");
      }
    }
    if (codeError) {
      openErrorNotification(
        codeError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [codeData, codeError]);

  const sendLocalTime = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeStamp = Math.floor(Date.now() / 1000);

    const url = `${process.env.REACT_APP_API_URL}/api/time/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        timestamp: timeStamp, // Send as ISO string
        timezone: timeZone,
      }),
    });

    const data = await response.json();
  };

  useEffect(() => {
    if (token) setRegisterModal(true);
    form.resetFields();
    sendLocalTime();
  }, []);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      await postAction(values);
    } catch (error) {
      openErrorNotification("  Username or password is incorrect.");
    }
  };

  // on submit handler.
  const onSubmitCodeHandler = async (values) => {
    try {
      const modifiedValues = {
        email: postData?.email,
        securityCode: values?.code,
      };
      await sendCode(modifiedValues);
    } catch (error) {
      openErrorNotification(
        "Login failed. Please check your security code and try again."
      );
    }
  };

  return (
    <AuthLayout>
      <div className="rounded rounded-lg  py-12 px-8 flex flex-col items-center !bg-white">
        <div className="w-full max-w-md flex justify-center ">
          <img
            // src={`oem/${oemNo}/images/mainLogo.png`}
            // src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
            src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
            alt={oemData?.name}
            className="w-[200px]  mx-auto"
          />
        </div>

        <>
          <p className="my-5 text-2xl text-center"></p>
          <Form
            form={form}
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
            onFinish={onSubmitHandler}
            className="w-full max-w-md"
          >
            <div className="flex flex-col gap-y-3">
              <Form.Item
                name="userId"
                className="my-input"
                rules={[
                  {
                    required: true,
                    message: "Username is required.",
                  },
                ]}
              >
                <Input
                  className="h-[38px] !bg-[#EEEEEF]"
                  placeholder="Username"
                  prefix={<LoginProfileIcon className="mr-2" />}
                  style={{ width: "292.92px" }}
                />
              </Form.Item>

              <Form.Item
                // label="Password"
                name="password"
                className="my-input"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
              >
                <Input.Password
                  className="!h-[38px] !bg-[#EEEEEF] "
                  placeholder="Password"
                  prefix={<PasswordIcon className="mr-2" />}
                  style={{ width: "292.92px" }}
                />
              </Form.Item>
              <div className="flex gap-x-3  items-center w-full">
                <div
                  onClick={handleForgetClick}
                  className="text-customColor text-sm font-[Poppins] cursor-pointer"
                >
                  Forget your Password
                </div>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="!mb-0"
                >
                  <Checkbox className="text-[#374151] text-sm font-medium">
                    Remember me
                  </Checkbox>
                </Form.Item>
              </div>
              {/* <p className="text-red-600 p-2 text-[15px] text-center">
              {inputValidate}
            </p> */}
              <Form.Item className="!mb-0 px-[2px] mt-2">
                <Button
                  htmlType="submit"
                  className="w-full bg-customColor text-white hover:!bg-white hover:!text-customColor border-none font-medium text-[15px] !rounded !py-4 h-[35px]"
                >
                  {postLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          spin
                          className="h-4 w-4"
                          style={{ color: "white" }}
                        />
                      }
                    />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </>

        <Modal
          open={isModalOpen}
          // onCancel={() => setIsModalOpen(false)}
          footer={null}
          // width={1000}
          closable={false} // Removes the cancel (close) icon in the header
          centered
        >
          <ResetPassword setIsModalOpen={setIsModalOpen} />
        </Modal>

        <Modal
          open={registerModal}
          // onCancel={() => setRegisterModal(false)}
          closable={false}
          footer={null}
          // width={1000}
          centered
        >
          <Signup setRegisterModal={setRegisterModal} token={token} />
        </Modal>
      </div>
      {codePage && (
        <Modal
          open={isSecondModalOpen}
          // onCancel={() => setIsSecondModalOpen(false)}
          footer={null}
          closable={false}
          // width={1000}
          centered
        >
          <>
            <div className="flex justify-center py-2 items-center w-full">
              <div className="bg-white  rounded-lg w-full max-w-md p-2">
                <div className="mb-6 text-center">
                  <h3 className="text-2xl font-semiboldtext-black font-[Poppins]">
                    Two Factor Authentication
                  </h3>
                  <p className="text-sm text-black font-[Poppins] mt-2">
                    Security Code has been sent to your Email.
                    <br />
                    Enter the Security Code to authenticate your login attempt.
                  </p>
                </div>
                <Form
                  autoComplete="off"
                  requiredMark={false}
                  layout="vertical"
                  onFinish={onSubmitCodeHandler}
                  className="w-full max-w-md"
                >
                  <Form.Item
                    name="code"
                    className="my-input !text-black font-['Poppins']"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your security code!",
                      },
                    ]}
                  >
                    <Input
                      className="h-[40px] !bg-white"
                      placeholder="Security Code"
                      prefix={<PasswordIcon className="mr-" />}
                    />
                  </Form.Item>
                  <Form.Item className="!mb-0 mt-5 ">
                    <div className="flex justify-between items-center gap-3 px-1">
                      <Button
                        htmlType="submit"
                        className="flex-1 h-[35px] text-sm font-medium bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
                      >
                        {codeLoading ? (
                          <span>
                            <Spin
                              indicator={<LoadingOutlined spin />}
                              size="default"
                              // className="mr-2"
                            />
                            Apply
                          </span>
                        ) : (
                          "Apply"
                        )}
                      </Button>

                      <Button
                        htmlType="submit"
                        className="flex-1 h-[35px] text-[12px]  bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                        onClick={() => setIsSecondModalOpen(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </>
        </Modal>
      )}
      {floading ? (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
          <div className="bg-white shadow-lg rounded-lg px-4 py-2 flex items-center">
            <Spin indicator={<LoadingOutlined spin />} size="default" />
            <span className="ml-2 text-gray-700 font-['Poppins'] font-medium">
              Loading
            </span>
          </div>
        </div>
      ) : null}
    </AuthLayout>
  );
};

export default Login;
