import React, { useContext, useState } from "react";
import { StepperContext } from "../../modules/UpgradeLicense/Context/LicenseProvider";

export const StepperComponent = () => {
  const { selectedStep } = useContext(StepperContext);

  return (
    <div className="flex w-[100%] pt-3">
      <div className="flex flex-col w-[33.33%]">
        <p className="text-[15px] text-black font-[Poppins] mb-1">
          1. Current Licesne
        </p>
        <div className="flex">
          <div className="h-[15px] w-[100%] bg-customColor"></div>
          <div
            className={`h-[15px]  ${
              selectedStep === 2 || selectedStep === 3 || selectedStep === 4
                ? "bg-customColor"
                : "bg-[#E7E7E7]"
            } `}
          ></div>
          {/* <div className="!bg-[#929292]  w-[0%]"></div> */}
        </div>
      </div>

      <div className="flex flex-col w-[33.33%]">
        <p className="text-[15px] text-black font-[Poppins] mb-1">
          2. Ordered License{" "}
        </p>
        <div className="flex">
          <div
            className={`h-[15px] w-[100%] ${
              selectedStep === 2 || selectedStep === 3 || selectedStep === 4
                ? "bg-customColor"
                : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div
            className={`h-[15px] ${
              selectedStep === 3 || selectedStep === 4
                ? "bg-customColor"
                : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div className="!bg-[#929292] w-[0%]"></div>
        </div>
      </div>
      <div className="flex flex-col w-[33.33%]">
        <p className="text-[15px] text-black font-[Poppins] mb-1">
          3. New License
        </p>
        <div className="flex">
          <div
            className={`h-[15px] w-[100%] ${
              selectedStep === 3 || selectedStep === 4
                ? "bg-customColor"
                : "bg-[#E7E7E7]"
            }`}
          ></div>
          <div
            className={`h-[15px]  ${
              selectedStep === 4 ? "bg-customColor" : "bg-[#E7E7E7]"
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};
