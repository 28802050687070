import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import DealerIcon from "../../../assets/images/vectors/SideBarIcons/DealerIcon";

export const DealerForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [showModal, setShowModal] = useState(false);

  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/dealer/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/dealer/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/dealer/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/dealer/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/dealer/getoptions`;

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const modifiedBillingCycles = getModifiedResponse(optionData, "bill_cycles");
  const modifiedAutoBilling = getModifiedResponse(optionData, "auto_billings");

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        if (loggedInUser.role_value == 4 || loggedInUser.role_value == 5) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          putAction(modifiedValues);
        } else {
          await putAction(values);
        }
      } else {
        if (loggedInUser.role_value == 4 || loggedInUser.role_value == 5) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          postAction(modifiedValues);
        } else {
          await postAction(values);
        }
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");

      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/dealerlist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
  };

  // Set default field value
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemNo: modifiedOems[0]?.value,
      });
    }
  }, [modifiedOems]);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            OemNo: data?.data?.OemNo,
            DealerName: String(data.data.DealerName),
            DealerDesc: data.data.DealerDesc
              ? String(data.data.DealerDesc)
              : "",
            BillingCycle: data.data.BillingCycle,
            AutoBilling: data.data.AutoBilling,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  //toat for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");
      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  //toast for delete
  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
      navigate("/dealerlist");
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    } else {
      return;
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Dealer",
      href: "/dealerlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/dealer/info/${id}`
          : `/dealer/edit/${id}`
        : "/dealer/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/dealer/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/dealerlist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
              <CommonButton buttonsData={buttonsData} />
            </CommonBreadcrumb>
            {
              <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <DealerIcon className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2 ">
                    Dealer
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 0]}>
                    {![4, 5, 6].includes(loggedInUser.role_value) && (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomSelect
                          label="OEM"
                          name="OemNo"
                          options={modifiedOems}
                          placeholder="Please select"
                          disabled={type === "info"}
                          required
                        />
                      </Col>
                    )}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Name"
                        name="DealerName"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    {/* </Row>
                  <Row gutter={[16, 16]}> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Description"
                        name="DealerDesc"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Billing Cycle"
                        name="BillingCycle"
                        options={modifiedBillingCycles}
                        placeholder="Please select"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    {/* </Row>
                  <Row gutter={[16, 16]}> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Auto Billing"
                        name="AutoBilling"
                        options={modifiedAutoBilling}
                        placeholder="Please select"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            }
          </div>
        </Form>
      }
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </>
  );
};
