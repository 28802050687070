import React from "react";
import { Pagination, Select, Input, Button } from "antd";

const PaginationTable = ({ total, paginationConfig }) => {
  const { current, pageSize, onChange, pageSizeOptions } = paginationConfig;

  const handlePageInput = (e) => {
    const page = Number(e.target.value);
    if (page > 0 && page <= Math.ceil(total / pageSize)) {
      onChange(page, pageSize);
    }
  };

  const handlePageSizeChange = (current, size) => {
    onChange(1, size);
  };

  return (
    <div>
      <div className="flex justify-between items-center mt-4">
        <Button
          disabled={current === 1}
          onClick={() => onChange(current - 1, pageSize)}
          className="font-[Poppins] pagination !text-[13px] font-medium text-black"
        >
          &larr; Previous
        </Button>
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChange}
          showSizeChanger={false}
          itemRender={(page, type) => {
            const totalPages = Math.ceil(total / pageSize);

            if (type === "prev" || type === "next") {
              return null;
            }

            if (
              type === "page" &&
              (page <= 5 ||
                page === totalPages ||
                Math.abs(current - page) <= 1)
            ) {
              return (
                <div
                  className={`w-8 h-8 flex items-center justify-center rounded-full cursor-pointer ${
                    page === current
                      ? "bg-gray-200 text-customColor font-medium"
                      : "hover:bg-gray-200 text-black font-medium"
                  }`}
                  onClick={() => onChange(page, pageSize)}
                >
                  {page}
                </div>
              );
            }

            if (type === "page" && page === 6) {
              return <span>...</span>;
            }

            return null;
          }}
        />
        <Button
          disabled={current === Math.ceil(total / pageSize)}
          onClick={() => onChange(current + 1, pageSize)}
          className="font-[Poppins] pagination !text-[13px] font-medium text-black"
        >
          Next &rarr;
        </Button>
      </div>
      <div className="flex justify-center items-center font-medium !text-[13px] text-black mt-4 gap-5">
        <span className="font-[Poppins] font-medium !text-[13px] text-black">
          Showing {(current - 1) * pageSize + 1} to{" "}
          {Math.min(current * pageSize, total)} out of {total} results
        </span>
        <div>
          <span className="font-[Poppins] !text-[13px] font-medium text-black  footer-btn">
            Rows per page:{" "}
          </span>
          <Select
          className="footer-btn"
            value={pageSize}
            onChange={(size) => handlePageSizeChange(current, size)}
          >
            {pageSizeOptions.map((size) => (
              <Select.Option key={size} value={size}>
                {size}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <span className="font-[Poppins] !text-[13px] font-medium text-black">
            Go to:{" "}
          </span>
          <Input
            type="number"
            className="footer-btn"
            value={current}
            min={1}
            max={Math.ceil(total / pageSize)}
            onChange={handlePageInput}
            style={{ width: 50, marginRight: 8 }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaginationTable;
