import React from "react";
const CameraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="var(--text-color)"
      fillRule="evenodd"
      d="M5.066 2.991A1.877 1.877 0 0 1 6.91 1.5h2.178c.89 0 1.667.618 1.846 1.491a.437.437 0 0 0 .413.35h.022c.936.041 1.654.156 2.254.55.378.248.704.566.957.939.315.463.454.995.52 1.637.066.629.066 1.416.066 2.414v.056c0 .998 0 1.786-.066 2.414-.066.642-.205 1.174-.52 1.638-.255.372-.58.691-.957.939-.469.307-1.007.443-1.659.508-.638.064-1.439.064-2.456.064H6.49c-1.017 0-1.817 0-2.456-.064-.652-.065-1.19-.2-1.659-.508a3.467 3.467 0 0 1-.956-.94c-.316-.463-.455-.995-.521-1.637-.066-.628-.066-1.416-.066-2.414v-.056c0-.998 0-1.785.066-2.414.066-.642.205-1.174.52-1.637.255-.373.58-.692.957-.94.6-.393 1.319-.508 2.254-.549h.022a.437.437 0 0 0 .413-.35ZM6.91 2.5a.877.877 0 0 0-.865.69 1.438 1.438 0 0 1-1.382 1.15c-.898.04-1.38.15-1.74.387a2.467 2.467 0 0 0-.678.666c-.184.27-.294.616-.353 1.178-.059.57-.06 1.306-.06 2.338s0 1.768.06 2.338c.058.562.17.908.354 1.179.179.263.409.49.678.666.278.182.634.292 1.21.35.582.057 1.333.058 2.384.058h2.963c1.05 0 1.8 0 2.384-.059.575-.057.931-.166 1.21-.349.269-.177.5-.403.678-.667.184-.27.295-.616.354-1.178.058-.57.059-1.306.059-2.338 0-1.031 0-1.768-.06-2.338-.058-.562-.169-.908-.353-1.178-.181-.264-.411-.49-.678-.667-.36-.235-.84-.345-1.74-.385a1.437 1.437 0 0 1-1.381-1.15.876.876 0 0 0-.866-.691H6.911ZM8 7.167a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm6-2a.5.5 0 0 1 .5-.5h.666a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CameraIcon;
