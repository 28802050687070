// import React from "react";
// import { Form, DatePicker } from "antd";

// const CustomDateTimePicker = ({
//   label,
//   name,
//   defaultValue,
//   errorMessage,
//   required = false,
//   onChange,
//   showTime = true, // Enable/disable time picker
//   format = "YYYY-MM-DD hh:mm",
//   ...rest
// }) => {
//   return (
//     <Form.Item
//       label={label}
//       name={name}
//       initialValue={defaultValue}
//       rules={[{ required: required, message: `${label} is required` }]}
//       validateStatus={errorMessage ? "error" : ""}
//       help={errorMessage}
//       labelCol={{ span: 24 }}
//       wrapperCol={{ span: 24 }}
//       style={{ marginBottom: "0" }}
//     >
//       <DatePicker
//         showTime={showTime}
//         format={format}
//         onChange={onChange}
//         style={{ width: "100%" }}
//         {...rest}
//       />
//     </Form.Item>
//   );
// };

// export default CustomDateTimePicker;
import React from "react";
import { Form, DatePicker } from "antd";

const CustomDateTimePicker = ({
  label,
  name,
  defaultValue,
  errorMessage,
  required = false,
  onChange,
  showTime = true, // Enable/disable time picker
  format = "YYYY-MM-DD HH:mm", // 24-hour format (HH instead of hh)
  ...rest
}) => {
  console.log(rest.value);
  
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={defaultValue}
      rules={[{ required: required, message: `${label} is required` }]}
      validateStatus={errorMessage ? "error" : ""}
      help={errorMessage}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      style={{ marginBottom: "0" }}
    >
      <DatePicker
        showTime={{ format: "HH:mm" }} // Ensures 24-hour format for time
        format={format}
        onChange={onChange}
        style={{ width: "100%" }}
        value={""}
        {...rest}
      />
    </Form.Item>
  );
};

export default CustomDateTimePicker;
