import { Button, Col, Input, Select, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";
import EmailIcon from "../../../assets/images/vectors/EmailIcon";

export const AccountFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowEmailModal,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const navigate = useNavigate();
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;

  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedOems = getModifiedResponse(optionsData, "oems");
  const modifiedType = getModifiedResponse(optionsData, "roles");
  const modifiedOp = getModifiedResponse(optionsData, "oems");
  const logData = JSON.parse(sessionStorage.getItem("userData"));

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div className="mb-4">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="InvoiceNo"
              placeholder="No"
              value={filter.CustomerNo}
              onChange={handleFilterChange}
            />
          </Col>
          {![4, 5, 6, 7, 8, 9, 10, 11, 12].includes(logData.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="OEM"
                optionFilterProp="OemNo"
                value={filter.OemNo || undefined}
                onChange={(value) => setFilter({ ...filter, OemNo: value })}
                onSearch={onSearch}
                options={modifiedOems}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          {![7, 8, 9].includes(logData.role_value) && (
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Select
                showSearch
                placeholder="Dealer"
                optionFilterProp="DealerNo"
                value={filter.DealerNo || undefined}
                onChange={(value) => setFilter({ ...filter, DealerNo: value })}
                onSearch={onSearch}
                options={modifiedDealer}
                style={{ width: "100%" }}
              />
            </Col>
          )}

          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="InvoiceTime"
              placeholder="Invoice Time"
              format="YYYY-MM-DD HH:mm" // 24-hour format
              value={filter.InvoiceTime}
              onChange={(value) => setFilter({ ...filter, InvoiceTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="EndDate"
              placeholder="End Date"
              format="YYYY-MM-DD hh:mm A"
              value={filter.EndDate}
              onChange={(value) => setFilter({ ...filter, EndDate: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Paid"
              optionFilterProp="Paid"
              value={filter.Paid || undefined}
              onChange={(value) => setFilter({ ...filter, Paid: value })}
              onSearch={onSearch}
              options={[
                {
                  value: 1,
                  label: "Yes",
                },
                {
                  value: 0,
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="PaymentTime"
              placeholder="Payment Time"
              format="YYYY-MM-DD HH:mm" // 24-hour format
              value={filter.PaymentTime }
              onChange={(value) => setFilter({ ...filter, PaymentTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
        </Row>
      </div>

      <div className="flex justify-between my-4 overflow-x-auto !items-center">
        <div className="flex items-center">
          <Button
            type="primary"
            className="mr-2 w-[104px] h-[32px] font-medium"
            onClick={handleFilter}
          >
            <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
            Apply
          </Button>
          <Button className="reset-button" onClick={handleReset}>
            <HiArrowPath className="!text-[14px] font-medium" />
            Reset
          </Button>
          <Button
            type="primary"
            className="mr-2 w-[104px] h-[32px] font-medium"
            onClick={() => setShowEmailModal(true)}
            disabled={selectedRowKeys?.length === 0 ? true : false}
          >
            <EmailIcon color="white" size={16} />
            Email
          </Button>
        </div>
        {/* <div>
          <Button
            type="primary"
            className="mr-2 w-[32px] h-[32px]"
            onClick={() => navigate("/customer/add")}
          >
            <PlusOutlined />
          </Button>
          <Button
            type="primary"
            disabled={selectedRowKeys?.length === 0 ? true : false}
            className="w-[32px] h-[32px]"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <DeleteOutlined />
          </Button>
        </div> */}
      </div>
    </div>
  );
};
