import React, { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { OrderItemColumns } from "../Page/Columns";
import useGet from "../../../global/hooks/useGet";
import OrderItemIcon from "../../../assets/images/vectors/SideBarIcons/OrderItemIcon";

export const OrderTable = ({ paymnetId, orderData }) => {
  const [listData, setListData] = useState([]);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "OrderNo",
    order: "desc",
  });

  const lisUrl = `${process.env.REACT_APP_API_URL}/api/payments/${paymnetId}/items/${orderData?.OrderNo}`;

  const {
    data: listInfo,
    loading: listInfoLoading,
    error: listDataError,
  } = useGet(lisUrl);

  useEffect(() => {
    if (listInfo && !listInfoLoading) {
      const newData = listInfo?.data?.map((el) => ({
        ...el,
        key: el?.OrderNo,
      }));
      setListData(newData);
    }
  }, [listInfo, listInfoLoading]);

  return (
    <div className="my-4">
      {/* <div className="flex items-center py-2 rounded-sm">
        <OrderItemIcon />
        <span className="text-[16px] text-[#414141] font-semibold ml-2">
          Ordered Items
        </span>
      </div> */}
      <GlobalTable
        columns={OrderItemColumns(setParams, params)}
        dataSource={listData}
        pagination={false}
        //   onRow={onRow}
      />
    </div>
  );
};
