import { useState } from "react";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

export const OrderItemAddForm = ({
  customerData,
  setShowForm,
  refetch,
  trigger,
  type,
  OrderItemNo,
}) => {
  const modifiedCustomerData = customerData?.map((item) => {
    return {
      label: item.name,
      value: item.value,
    };
  });
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const itemOptionUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/getoptions`;
  const url =
    OrderItemNo &&
    `${process.env.REACT_APP_API_URL}/api/orderitem/${OrderItemNo}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/orderitem/update/${OrderItemNo}`;

  const { data, loading, error } = useGet(url);

  const {
    data: itemOptionsData,
    loading: itemOptionDataLoading,
    error: itemOptionDataError,
  } = useGet(itemOptionUrl);

  const modifiedCustomers = getModifiedResponse(itemOptionsData, "customers");
  const modifiedLicenses = getModifiedResponse(itemOptionsData, "licenses");

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);

  const onFinish = async (values) => {
    try {
      if (type === "Add") {
        const modValues = { ...values, OrderNo: Number(id) };
        await postAction(modValues);
      } else {
        await putAction(values);
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  useEffect(() => {
    if (!OrderItemNo || loading) return;
    const getOrderItemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            CustomerNo: data?.data?.CustomerNo,
            LicenseNo: data?.data?.LicenseInfo?.LicenseNo,
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getOrderItemInfoById();
  }, [OrderItemNo, data, loading]);

  //toast for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        refetch();

        setShowForm(false);
      }, 200);
      setTimeout(() => {
        trigger();
      }, 500);
    }
  }, [postError, postData, postLoading]);

  //toast for edit
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        // navigate(`/orderinfo/info/${id}`);
        refetch();
        setShowForm(false);
      }, 200);
      setTimeout(() => {
        trigger();
      }, 500);
    }
  }, [putError, putData, putLoading]);

  const onReset = () => {
    // navigate("/orderlist");
    setShowForm(false);
  };

  const breadcrumbItems = [
    {
      label: "OrderItem",
      href: "/orderlist",
    },
    {
      label: "Add",
      href: "/orderitem/add",
    },
  ];

  const buttonsData = [
    // {
    //   label: "List",
    //   icon: <UnorderedListOutlined />,
    //   onClick: () => {
    //     navigate("/orderlist");
    //   },
    // },
    {
      label: "Apply",
      icon: <CheckCircleOutlined />,
      onClick: () => form.submit(),
    },
    {
      label: "Cancel",
      icon: <CloseOutlined />,
      onClick: onReset,
    },
  ];

  return (
    <div className="mb-4 border border-gray-200 py-4 px-2 rounded-md">
      <div className="flex justify-between">
        {/* <CommonBreadcrumb breadcrumbItems={breadcrumbItems} /> */}
        <div className="text-[16px] text-[#414141] font-semibold ml-2">
          Order Item{" "}
          <span className="font-bold rotate-90 inline-block mx-4"> | </span>{" "}
          {type}
        </div>
        <CommonButton buttonsData={buttonsData} />
      </div>

      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div className="mt-6 rounded-[4px] overflow-hidden">
            <div className="flex items-center bg-gray-200 p-2">
              <OrderedListOutlined className="text-[20px]" />
              <span className="text-[16px] text-[#414141] font-semibold ml-2">
                Order Item
              </span>
            </div>
            <div className="p-2">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Customer"
                    name="CustomerNo"
                    options={modifiedCustomerData}
                    placeholder="Please select"
                    required
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="License"
                    name="LicenseNo"
                    options={modifiedLicenses}
                    placeholder="Please select"
                    required
                  />
                </Col>
              </Row>
              {/* <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomInput label="Count" name="Count" type="number" />
                </Col>
              </Row> */}
            </div>
          </div>
        </Form>
      }
    </div>
  );
};
