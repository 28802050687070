// import React, { useState } from "react";
// import { Col, Modal, Row, Typography } from "antd";
// import { WarningOutlined } from "@ant-design/icons";

// const { Title } = Typography;
// const ConfirmModal = ({
//   showModal,
//   setShowModal,
//   onDeleteConfirm,
//   setSelectedRowKeys,
//   content,
// }) => {
//   const handleOk = () => {
//     onDeleteConfirm();
//     setShowModal(false);
//   };
//   const handleCancel = () => {
//     setShowModal(false);
//     setSelectedRowKeys && setSelectedRowKeys([]);
//   };
//   return (
//     <>
//       <Modal
//         // title="Are you sure you want to delete?"
//         open={showModal}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         closable={false}
//         cancelText="No"
//         okText="Yes"
//         centered
//         okButtonProps={{
//           className:
//             "inline-flex justify-center w-full px-6 py-1 text-[15px] rounded-[4px] hover:bg-white bg-[#007BFF] text-white hover:text-[#007BFF] hover:shadow-all-side sm:w-auto",
//         }}
//         cancelButtonProps={{
//           className:
//             "inline-flex justify-center w-full px-6 py-1 mt-3 text-[15px] text-white bg-red-600 hover:bg-white rounded-[4px] hover:text-red-600 hover:shadow-all-side sm:mt-0 sm:w-auto",
//         }}
//       >
//         <Row className="">
//           <Col>
//             <div className="text-[16px] font-medium flex">
//               <span className="flex items-center justify-center w-10 h-10 bg-yellow-100 text-yellow-600 rounded-full">
//                 <WarningOutlined />
//               </span>
//               <p className="ml- mt-2">
//                 {content ? content : "Do you want to Delete ?"}
//               </p>
//             </div>
//           </Col>
//         </Row>
//       </Modal>
//     </>
//   );
// };
// export default ConfirmModal;
import React from "react";
import { Col, Modal, Row, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ConfirmModal = ({
  showModal,
  setShowModal,
  onDeleteConfirm,
  setSelectedRowKeys,
  content,
}) => {
  const handleOk = () => {
    onDeleteConfirm();
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedRowKeys && setSelectedRowKeys([]);
  };

  return (
    <Modal
      open={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      width={420}
      centered
      footer={null} // Remove default buttons to use custom styling
    >
      <div className="flex pt-2 flex-col font-['Poppins'] items-center justify-center text-center w-full">
        {/* Icon + Text Container */}
        <div className="flex items-center gap-x-4 w-full px-4">
          {/* Warning Icon */}
          <span className="flex items-center justify-center w-10 h-10  bg-yellow-100 text-yellow-600 rounded-full flex-shrink-0">
            <WarningOutlined className="text-2xl" />
          </span>

          {/* Text with flexible width */}
          <p className="text-[16px] text-black font-[Poppins] flex-1 text-left">
            {content || "Do you want to Delete?"}
          </p>
        </div>

        {/* Buttons at the bottom */}
        <div className="flex justify-end space-x-3 mt-4 w-full px-1">
          <button
            onClick={handleOk}
            className="px-6 py-1 text-[14px] bg-[#007BFF] text-white rounded-md hover:bg-white hover:text-[#007BFF] border border-[#007BFF] transition"
          >
            Yes
          </button>
          <button
            onClick={handleCancel}
            className="px-6 py-1 text-[14px] text-white bg-red-600 rounded-md hover:bg-white hover:text-red-600 border border-red-600 transition"
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
