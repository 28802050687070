import { Button, Col, Input, Select, Row, Form } from "antd";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import CustomDateTimePicker from "../../../global/components/inputWidgets/CustomDatePicker";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";

export const DeviceFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/device/getoptions`;
  const [form] = Form.useForm();
  console.log(form.getFieldsValue());

  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedProducts = getModifiedResponse(optionsData, "products");
  const modifiedModels = getModifiedResponse(optionsData, "models");
  const modifiedOems = getModifiedResponse(optionsData, "oems");

  const onSearch = (value) => {};

  return (
    <Form className="" form={form}>
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="LogNo"
              placeholder="No"
              value={filter.LogNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <CustomDateTimePicker
              name="LogTime"
              placeholder="Log Time"
              format="YYYY-MM-DD HH:mm" // 24-hour format
              // value={filter.LogTime}
              onChange={(value) => setFilter({ ...filter, LogTime: value })}
              // onChange={(value) => console.log(value)}
            />
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="AccountName"
              placeholder="Account"
              value={filter.AccountName}
              onChange={handleFilterChange}
            />
          </Col> */}
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Mac"
              placeholder="MAC"
              value={filter.Mac}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Product"
              optionFilterProp="Product"
              value={filter.Product || undefined}
              onChange={(value) => setFilter({ ...filter, Product: value })}
              onSearch={onSearch}
              options={modifiedProducts}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Model"
              optionFilterProp="Model"
              value={filter.Model || undefined}
              onChange={(value) => setFilter({ ...filter, Model: value })}
              onSearch={onSearch}
              options={modifiedModels}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Type"
              placeholder="Type"
              value={filter.Type}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="OEM"
              value={filter.OemNo || undefined}
              optionFilterProp="OemNo"
              onChange={(value) => setFilter({ ...filter, OemNo: value })}
              onSearch={onSearch}
              options={modifiedOems}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
      <div className="flex justify-between my-4 overflow-x-auto !items-center">
        <div className="flex items-center">
          <Button
            type="primary"
            className="mr-2 w-[104px] h-[30px] font-medium"
            onClick={handleFilter}
          >
            <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
            Apply
          </Button>
          <Button
            className="reset-button"
            onClick={() => {
              form.resetFields();
              handleReset();
            }}
          >
            <HiArrowPath size={14} />
            Reset
          </Button>
        </div>
      </div>
    </Form>
  );
};
