import { Button, Col, Input, Select, Row } from "antd";
import { useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";

export const LicenseFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const navigate = useNavigate();
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedLicenseTypes = getModifiedResponse(optionData, "licensetypes");
  const modifiedProducts = getModifiedResponse(optionData, "products");
  const modifiedModels = getModifiedResponse(optionData, "models");
  const modifiedOldModels = getModifiedResponse(optionData, "oldmodels");
  const modifiedOems = getModifiedResponse(optionData, "oems");

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div className="mb-4">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="LicenseNo"
              placeholder="No"
              value={filter.LicenseNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="LicenseName"
              placeholder="Name"
              value={filter.LicenseName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="License Type"
              optionFilterProp="LicenseType"
              value={filter.LicenseType || undefined}
              onChange={(value) => setFilter({ ...filter, LicenseType: value })}
              onSearch={onSearch}
              options={modifiedLicenseTypes}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Product"
              optionFilterProp="Product"
              value={filter.Product || undefined}
              onChange={(value) => setFilter({ ...filter, Product: value })}
              onSearch={onSearch}
              options={modifiedProducts}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Old Model"
              optionFilterProp="OldModel"
              value={filter.OldModel || undefined}
              onChange={(value) => setFilter({ ...filter, OldModel: value })}
              onSearch={onSearch}
              options={modifiedOldModels}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Model"
              optionFilterProp="Model"
              value={filter.Model || undefined}
              onChange={(value) => setFilter({ ...filter, Model: value })}
              onSearch={onSearch}
              options={modifiedModels}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="OldType"
              placeholder="Old Type"
              value={filter.OldType}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Type"
              placeholder="Type"
              value={filter.Type}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="OEM"
              optionFilterProp="OemNo"
              value={filter.OemNo || undefined}
              onChange={(value) => setFilter({ ...filter, OemNo: value })}
              onSearch={onSearch}
              options={modifiedOems}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
      <div className="w-full overflow-x-auto">
        <div className="flex justify-between mt-4 flex-nowrap min-w-[610px]">
          <div>
            <Button
              type="primary"
              className="mr-2 w-[104px] h-[30px] font-medium"
              onClick={handleFilter}
            >
               <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
              Apply
            </Button>
            <Button className="reset-button" onClick={handleReset}>
            <HiArrowPath className="!text-[14px] font-medium" />
              Reset
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              className="mr-2 w-[32px] h-[32px]"
              onClick={() => navigate("/license/add")}
            >
              <PlusOutlined />
            </Button>
            <Button
              type="primary"
              disabled={selectedRowKeys?.length === 0 ? true : false}
              className="w-[32px] h-[32px]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
