import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { OrderColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import { OrderFilter } from "../Components/FilterComponent";
import { convertToUnix } from "../../../global/utils/unixToLocalTime";
import usePost from "../../../global/hooks/usePost";
import { Modal } from "antd";
import InvoiceForm from "../Components/InvoiceForm";
import OemIcon from "../../../assets/images/vectors/SideBarIcons/OemIcon";
import { MdOutlinePayments } from "react-icons/md";

const initialFilterState = {
  OrderNo: "",
  OemNo: "",
  DealerNo: "",
  AccountNo: "",
  OrderName: "",
  Paid: "",
  OrderTime: "",
};

export const Order = () => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "OrderNo",
    order: "desc",
  });

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "Order",
      href: "/orderlist",
    },
  ];

  const invoiceUrl = `${process.env.REACT_APP_API_URL}/api/order/make_invoice`;
  const {
    data: invoiceData,
    loading: invoiceDataLoading,
    error: invoiceDataError,
    postData: sentInvoice,
  } = usePost(invoiceUrl);

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/order/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/order/all`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/orders/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/order/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record) => ({
    //   disabled: record?.Paid === "Yes",
    // }),
    // fixed: true,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/orderinfo/info/${record?.OrderNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        if (key === "OrderTime") {
          filterDict[key] = convertToUnix(filter[key]);
        } else {
          filterDict[key] = filter[key];
        }
        // filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "OrderNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
  };

  //handle Invoice
  const handleInvoice = () => {
    sentInvoice({ ids: selectedRowKeys });
  };
  // const handleEmail = () => {
  //   sentEmail({ ids: selectedRowKeys });
  // };
  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  //toast for email
  useEffect(() => {
    if (invoiceDataError) {
      openErrorNotification(
        invoiceDataError?.response?.data?.message || "Something went wrong"
      );
    } else if (!invoiceDataLoading && !invoiceDataError && invoiceData) {
      openConfirmNotification("Success");
      refetch(invoiceData);
      // setTimeout(() => {
      //   navigate(-1);
      // }, 200);
    }
  }, [invoiceDataError, invoiceData, invoiceDataLoading]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.OrderNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-[4px] border border-gray-200">
        <OrderFilter
          filter={filter}
          handleFilterChange={handleFilterChange}
          handleFilter={handleFilter}
          handleReset={handleReset}
          setShowModal={setShowModal}
          selectedRowKeys={selectedRowKeys}
          setFilter={setFilter}
          allData={listData}
          setInvoiceModal={setInvoiceModal}
        />
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          onDeleteConfirm={onDeleteConfirm}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={OrderColumns(
            hoveredRow,
            setShowModal,
            setSelectedRowKeys,
            setParams,
            params
          )}
          dataSource={listData}
          rowSelection={rowSelection}
          pagination={paginationConfig}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
          loading={loading}
        />
        {/* <Modal
          title={
            <div className="flex items-center bg-gray-200 p-2 rounded-md">
              <MdOutlinePayments className="text-[18px] text-black" />
              <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                Invoice
              </span>
            </div>
          }
          open={invoiceModal}
          onCancel={() => setInvoiceModal(false)}
          footer={null}
          width={560}
          closable={false}
          centered
        >
          <InvoiceForm setInvoiceModal={setInvoiceModal} />
        </Modal> */}
        <ConfirmModal
          showModal={invoiceModal}
          setShowModal={setInvoiceModal}
          onDeleteConfirm={handleInvoice}
          content="Do you want to Invoice?"
        />
      </div>
    </div>
  );
};
