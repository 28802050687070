import { Button, Col, Input, Select, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useGet from "../../../global/hooks/useGet";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  SyncOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { HiArrowPath } from "react-icons/hi2";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

export const DeviceFilter = ({
  filter,
  handleFilterChange,
  handleFilter,
  handleReset,
  setShowModal,
  selectedRowKeys,
  setFilter,
}) => {
  const navigate = useNavigate();
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/device/getoptions`;

  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedProducts = getModifiedResponse(optionsData, "products");
  const modifiedModels = getModifiedResponse(optionsData, "models");
  const modifiedType = getModifiedResponse(optionsData, "type");
  const modifiedOems = getModifiedResponse(optionsData, "oems");

  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <div className="">
      <div className="mt-2">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="DeviceNo"
              placeholder="No"
              value={filter.DeviceNo}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Mac"
              placeholder="MAC"
              value={filter.Mac}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Product"
              optionFilterProp="Product"
              value={filter.Product || undefined}
              onChange={(value) => setFilter({ ...filter, Product: value })}
              onSearch={onSearch}
              options={modifiedProducts}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Model"
              optionFilterProp="Model"
              value={filter.Model || undefined}
              onChange={(value) => setFilter({ ...filter, Model: value })}
              onSearch={onSearch}
              options={modifiedModels}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Input
              className="input_text_3 mr10"
              name="Type"
              placeholder="Type"
              value={filter.Type}
              onChange={handleFilterChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="OEM"
              optionFilterProp="OemNo"
              value={filter.OemNo || undefined}
              onChange={(value) => setFilter({ ...filter, OemNo: value })}
              onSearch={onSearch}
              options={modifiedOems}
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Select
              showSearch
              placeholder="Expired"
              optionFilterProp="Expired"
              value={filter.Expired || undefined}
              onChange={(value) => setFilter({ ...filter, Expired: value })}
              onSearch={onSearch}
              options={[
                {
                  value: 1,
                  label: "Yes",
                },
                {
                  value: 0,
                  label: "No",
                },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </div>
      <div className="w-full overflow-x-auto">
        <div className="flex justify-between mt-4 flex-nowrap min-w-[610px]">
          <div className="flex items-center">
            <Button
              type="primary"
              className="mr-2 w-[104px] h-[30px] font-medium"
              onClick={handleFilter}
            >
              <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
              <span>Apply</span>
            </Button>
            <Button
              className="reset-button w-[104px] h-[30px] font-medium"
              onClick={handleReset}
            >
              <HiArrowPath className="!text-[14px] font-medium" />
              Reset
            </Button>

            <Button
              type="primary"
              className="mr-2  h-[30px] font-medium"
              onClick={() => navigate("/addDeviceByList")}
            >
              <PlusOutlined />
              Add by List
            </Button>
            <Button
              type="primary"
              className="mr-2 h-[30px] font-medium"
              onClick={() => navigate("/addDeviceByCount")}
            >
              <PlusOutlined />
              Add by Count
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              className="mr-2 w-[32px] h-[32px]"
              onClick={() => navigate("/devicelist/add")}
            >
              <PlusOutlined className="" />
            </Button>
            <Button
              type="primary"
              disabled={selectedRowKeys?.length === 0 ? true : false}
              className="w-[32px] h-[32px]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <DeleteOutlined className="" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
