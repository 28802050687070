import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/logo/mainLogo.png";
import usePost from "../hooks/usePost";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../utils/toast";
import AuthLayout from "./AuthLayout";
import "../styles/login.css";
import usePostLogin from "../hooks/usePostLogin";
import useOemData from "../hooks/useOemData";
import PasswordIcon from "../../assets/images/vectors/PasswordIcon";
import { passwordValidator } from "../utils/passwordValidator";

export const ResetPassword = ({ setIsModalOpen }) => {
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const postUrl = `${process.env.REACT_APP_API_URL}/api/resetpassword`;
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  useEffect(() => {
    if (postData) {
      openConfirmNotification("Success");

      setIsModalOpen(false);
    }
    if (postError) {
      openErrorNotification(
        "Login failed. Please check your credentials and try again."
      );
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const updatedValues = {
        SecurityCode: values?.SecurityCode,
        NewPassword: values?.NewPassword,
      };
      await postAction(updatedValues);
    } catch (error) {
      openErrorNotification(
        "Login failed. Please check your credentials and try again."
      );
    }
  };

  return (
    <div className="flex justify-center py-3 items-center w-full">
      <div className="bg-white  rounded-lg w-full max-w-md p-3">
        {/* Header Section */}
        <div className="mb-5 text-center">
          <h3 className="text-2xl font-semiboldtext-black font-[Poppins]">
            Password Reset
          </h3>
          <p className="text-sm text-black font-[Poppins] mt-2">
            Security code has been sent to your Email. <br />
            Enter the Security code and New Password.
          </p>
        </div>
        <Form
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          onFinish={onSubmitHandler}
          className="space-y-4"
        >
          <Form.Item
            name="SecurityCode"
            className="my-input"
            rules={[
              {
                required: true,
                message: " security code is required",
              },
            ]}
          >
            <Input
              placeholder=" Security code"
              className="h-[40px] bg-gray-100 rounded "
              prefix={<PasswordIcon className="mr-2" />}
            />
          </Form.Item>

          <Form.Item
            name="NewPassword"
            className="my-input"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject("Password is required");
                  }
                  if (!passwordValidator(value)) {
                    return Promise.reject(
                      "Password must be at least 8 characters, include a letter, a number, and a special character."
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="New Password"
              className="h-[40px] bg-gray-100 rounded"
              prefix={<PasswordIcon className="mr-2" />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            className="my-input"
            rules={[
              {
                required: true,
                message: "Please confirm your password.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("NewPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match.")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              className="h-[40px] bg-gray-100 rounded"
              prefix={<PasswordIcon className="mr-2" />}
            />
          </Form.Item>

          <Form.Item className="mt-7">
            <div className="flex justify-between items-center gap-3 px-0.5">
              <Button
                htmlType="submit"
                className="flex-1 h-[35px] text-[12px] !font-medium !font-[Poppins] px-3 border-none !text-[#fff] hover:!text-[#fff] hover:!bg-customColor !rounded mr-2 bg-customColor hover:!shadow-all-side "
              >
                Apply
              </Button>

              <Button
                htmlType="submit"
                className="flex-1 h-[35px] text-[12px]  bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
