import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { matchPath, useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DesktopOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
  LockOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import CustomCheckbox from "../../../global/components/inputWidgets/CustomCheckBox";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import LicenseIcon from "../../../assets/images/vectors/SideBarIcons/LicenseIcon";

export const LicenseForm = ({ type }) => {
  const [showModal, setShowModal] = useState(false);
  const [isInitial, setInitial] = useState(true);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/license/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/license/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/license/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/license/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/license/getoptions`;

  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedLicenseTypes = getModifiedResponse(optionData, "licensetypes");
  const modifiedProducts = getModifiedResponse(optionData, "products");
  const modifiedOldModels = getModifiedResponse(optionData, "oldmodels");
  const modifiedModels = getModifiedResponse(optionData, "models");
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const modifiedOp = getModifiedResponse(optionData, "options");

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = async (values) => {
    try {
      if (id) {
        await putAction(values);
      } else {
        const modifiedValue = { ...values, Price: 0 };
        await postAction(modifiedValue);
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/licenselist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
  };

  // Set default field value for create not a valid undefined field
  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        OemNo: modifiedOems[0]?.value,
        Product: modifiedProducts[0]?.value,
        Model: modifiedModels[0]?.value,
        Camera: "0",
        Channel: "0",
        Lockset: "0",
        Facegate: "0",
        Subnode: "0",
        ContLock: "0",
        Intercom: "0",
        Sicucom: "0",
        Price: "0",
        LicenseType: 0,
      });
    }
  }, [optionData]);

  useEffect(() => {
    if (!id) return;
    else {
      if (data?.data?.LicenseType == 0) {
        setInitial(true);
      } else {
        setInitial(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            LicenseName: String(data?.data?.LicenseName),
            LicenseDesc: data?.data?.LicenseDesc
              ? String(data.data.LicenseDesc)
              : "",
            LicenseType: data.data.LicenseType,
            Product: data.data.Product,
            OldModel: data.data.OldModel,
            Model: data.data.Model,
            OldType: String(data.data.OldType),
            Type: String(data.data.Type),
            OemNo: data.data.OemNo,
            Options: data.data.Options,
            Camera: String(data.data.Camera),
            Channel: String(data.data.Channel),
            Lockset: String(data.data.Lockset),
            Facegate: String(data.data.Facegate),
            Subnode: String(data.data.Subnode),
            ContLock: String(data.data.ContLock),
            Intercom: String(data.data.Intercom),
            Sicucom: String(data.data.Sicucom),
            Price: String(data.data.Price),
          });
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  //toat for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");

      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
      navigate("/licenselist");
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    } else {
      return;
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "License",
      href: "/licenselist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/license/info/${id}`
          : `/license/edit/${id}`
        : "/license/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/license/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/licenselist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <>
      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
      >
        <div>
          <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
            <CommonButton buttonsData={buttonsData} />
          </CommonBreadcrumb>
          {
            <>
              <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <LicenseIcon className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Name"
                        name="LicenseName"
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Description"
                        name="LicenseDesc"
                        disabled={type === "info"}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="License Type"
                        name="LicenseType"
                        options={modifiedLicenseTypes}
                        placeholder="Please select"
                        disabled={type === "info"}
                        onChange={(e) =>
                          e === 0 ? setInitial(true) : setInitial(false)
                        }
                      />
                    </Col>
                    {!isInitial && (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <CustomInput
                          label="Price"
                          name="Price"
                          disabled={type === "info"}
                          type="number"
                          required
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
              <div className="mt-3 border bg-white rounded-[4px] overflow-hidden">
                <div className="bg-gray-200 p-2.5 flex items-center">
                  <InfoCircleOutlined className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Information
                  </span>
                </div>
                <div>
                  {isInitial ? (
                    <div className="px-2 pt-0 pb-3">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Product"
                            name="Product"
                            options={modifiedProducts}
                            placeholder="Please select"
                            disabled={type === "info"}
                          />
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Model"
                            name="Model"
                            options={modifiedModels}
                            placeholder="Please select"
                            disabled={type === "info"}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomInput
                            label="Type"
                            name="Type"
                            disabled={type === "info"}
                            type="number"
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="px-2 pt-0 pb-3">
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Old Model"
                            name="OldModel"
                            options={modifiedOldModels}
                            placeholder="Please select"
                            disabled={type === "info"}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Model"
                            name="Model"
                            options={modifiedModels}
                            placeholder="Please select"
                            disabled={type === "info"}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomInput
                            label="Old Type"
                            name="OldType"
                            disabled={type === "info"}
                            type="number"
                            required
                          />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomInput
                            label="Type"
                            name="Type"
                            disabled={type === "info"}
                            type="number"
                            required
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-3 border bg-white rounded-[4px] overflow-hidden ">
                <div className="bg-gray-200 p-2.5 flex items-center">
                  <SettingOutlined className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Options
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <CustomCheckbox
                    label="Options"
                    name="Options"
                    options={modifiedOp}
                    disabled={type === "info"}
                  />
                </div>
              </div>

              <div className="mt-3 border bg-white rounded-[4px] overflow-hidden">
                <div className="bg-gray-200 p-2.5 flex items-center">
                  <DesktopOutlined className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    License Devices
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Camera"
                        name="Camera"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Channel"
                        name="Channel"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Lockset"
                        name="Lockset"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Facegate"
                        name="Facegate"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Subnode"
                        name="Subnode"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Trilogy Lockset"
                        name="ContLock"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Intercom"
                        name="Intercom"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Directory"
                        name="Sicucom"
                        disabled={type === "info"}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          }
        </div>
      </Form>
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </>
  );
};
