import React from "react";
import { Button } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonButton = ({ buttonsData }) => {
  return (
    <div className="mt-2">
      {buttonsData.map((button, index) => (
        <Button
          key={index}
          type={button.label === "Cancel" ? "" : "primary"}
          //   icon={<FontAwesomeIcon icon={button.icon} />}
          onClick={button.onClick}
          disabled={button.disabled}
          className={`mr-2 min-w-[104px] h-[30px] py-1 px-[10px] font-medium ${
            button.label === "Cancel"
              ? "!bg-[#E9E9E9] border  !text-customColor hover:!bg-white hover:!text-customColor"
              : ""
          }`}
        >
          {button.icon}
          {button.label}
        </Button>
      ))}
    </div>
  );
};

export default CommonButton;
