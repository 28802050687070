import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Form, Button, Col, Row, Modal } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { OrderInfo } from "../Page/OrderInfo";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import { formatUnixTime } from "../../../global/utils/unixToLocalTime";
import {
  CheckCircleOutlined,
  CloseOutlined,
  UnorderedListOutlined,
  DollarCircleOutlined,
  MailOutlined,
  EditOutlined,
  DeleteOutlined,
  ProductOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import OrderIcon from "../../../assets/images/vectors/SideBarIcons/OrderIcon";
import MyPaymentForm from "../../../global/components/PaymentForm";

export const OrderInfoForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [paidStatus, setPaidStatus] = useState();

  const [form] = Form.useForm();
  const dealerNo = Form.useWatch("DealerNo", form);
  const { id } = useParams();
  const navigate = useNavigate();

  const url = `${process.env.REACT_APP_API_URL}/api/order/${id}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/order/create`;
  const emailUrl = `${process.env.REACT_APP_API_URL}/api/order/email`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/order/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/order/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/order/getoptions`;
  const getOemUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getOemByDealer/${dealerNo}`;
  const getCustomersUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getCustomersByDealer/${dealerNo}`;

  const { data, loading, error, refetch } = useGet(url);
  const { data: oemData } = useGet(getOemUrl);
  const { data: customerData } = useGet(getCustomersUrl);

  // console.log({ customerData });
  const {
    data: optionData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);

  const modifiedAccounts = getModifiedResponse(optionData, "dealers");
  const modifiedOems = getModifiedResponse(optionData, "oems");
  const {
    data: emailData,
    loading: emailLoading,
    error: emailError,
    postData: sentEmail,
  } = usePost(emailUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);
  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const handleEmail = () => {
    if (id) {
      sentEmail({ OrderNo: Number(id) });
    }
  };

  const handleConfirm = () => {
    putAction({ Paid: 1 });
    setTimeout(() => {
      refetch();
    }, 1000);
    navigate(`/orderinfo/info/${id}`);
  };

  const onFinish = async (values) => {
    try {
      if (id) {
        const modifiedValues = {
          ...values,
          Amount: Number(data.data.Amount),
          Paid: Number(data.data.Paid),
          Invoiced: Number(data.data.Invoiced),
          OrderTime: data.data.OrderTime,
        };
        await putAction(modifiedValues);
      } else {
        await postAction(values);
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");

      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate(`/orderinfo/info/${id}`);
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      openErrorNotification("Something went wrong");
    }
    setShowModal(false);
  };

  //toast for email
  useEffect(() => {
    if (emailError) {
      openErrorNotification(
        emailError?.response?.data?.message || "Something went wrong"
      );
    } else if (!emailLoading && !emailError && emailData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [emailError, emailData, emailLoading]);

  //toast for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [postError, postData, postLoading]);

  //toast for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");

      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 200);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    form.setFieldsValue({
      OemNo: oemData?.OemNo,
    });
  }, [modifiedAccounts]);

  useEffect(() => {
    if (!id || loading) return;

    const getOemInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            DealerNo: data?.data?.DealerNo,
            OrderName: data.data.OrderName,
            OrderDesc: data.data.OrderDesc,
            Amount: data.data.Amount ? `$${Number(data.data.Amount)}` : "",
            Paid: Number(data.data.Paid),
            Invoiced: Number(data.data.Invoiced),
            OrderTime: data.data.OrderTime
              ? formatUnixTime(data.data.OrderTime)
              : "",
          });
          data?.data?.Paid === 1 ? setPaidStatus(true) : setPaidStatus(false);
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getOemInfoById();
  }, [id, data, loading, form]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
      navigate("/orderlist");
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Order",
      href: "/orderlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/orderinfo/info/${id}`
          : `/orderinfo/edit/${id}`
        : "/orderinfo/add",
    },
  ];

  let buttonsData;
  paidStatus
    ? (buttonsData = [
        {
          label: "Email",
          icon: <MailOutlined />,
          onClick: () => setShowEmailModal(true),
        },
        {
          label: "List",
          icon: <UnorderedListOutlined />,
          onClick: () => {
            navigate("/orderlist");
          },
        },
      ].filter(Boolean))
    : (buttonsData =
        type === "info"
          ? [
              [1, 2, 3, 7, 8, 9].includes(loggedInUser.role_value) && {
                label: "Confirm",
                icon: <DollarCircleOutlined />,
                onClick: () => {
                  setShowCommonModal(true);
                },
              },

              {
                label: "Edit",
                icon: <EditOutlined />,
                onClick: () => {
                  navigate(`/orderinfo/edit/${id}`);
                },
              },
              {
                label: "Delete",
                icon: <DeleteOutlined />,
                onClick: () => {
                  setShowModal(true);
                },
              },
              {
                label: "List",
                icon: <UnorderedListOutlined />,
                onClick: () => {
                  navigate("/orderlist");
                },
              },
            ].filter(Boolean)
          : [
              {
                label: "Apply",
                icon: <CheckCircleOutlined />,
                onClick: () => form.submit(),
              },
              {
                label: "Cancel",
                icon: <CloseOutlined />,
                onClick: onReset,
              },
            ]);

  return (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
        <CommonButton buttonsData={buttonsData} />
      </CommonBreadcrumb>

      <Form
        validateTrigger={"onChange"}
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout={"vertical"}
        autoComplete={"off"}
        className=""
      >
        <div className="mt-6 border bg-white rounded-[4px] overflow-hidden">
          <div className="flex items-center bg-gray-200 p-2">
            <OrderIcon />
            <span className="text-[16px] text-[#414141] font-semibold ml-2">
              Order
            </span>
          </div>
          <div className="p-2">
            <Row gutter={[16, 0]}>
              {![4, 5, 6, 7, 8, 9].includes(loggedInUser.role_value) && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="OEM"
                    name="OemNo"
                    options={modifiedOems}
                    placeholder="Please select"
                    disabled={true}
                  />
                </Col>
              )}
              {![7, 8, 9].includes(loggedInUser.role_value) && (
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CustomSelect
                    label="Dealer"
                    name="DealerNo"
                    options={modifiedAccounts}
                    placeholder="Please select"
                    disabled={data?.data?.Paid == 1 || type === "info"}
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CustomInput
                  label="Name"
                  name="OrderName"
                  disabled={data?.data?.Paid === 1 || type === "info"}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CustomInput
                  label="Description"
                  name="OrderDesc"
                  disabled={data?.data?.Paid === 1 || type === "info"}
                />
              </Col>
              {type === "info" && (
                <>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput label="Amount" name="Amount" disabled={true} />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Confirmed"
                      name="Paid"
                      options={[
                        { label: "No", value: 0 },
                        { label: "Yes", value: 1 },
                      ]}
                      placeholder="Please select"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomSelect
                      label="Invoiced"
                      name="Invoiced"
                      options={[
                        { label: "No", value: 0 },
                        { label: "Yes", value: 1 },
                      ]}
                      placeholder="Please select"
                      disabled={true}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <CustomInput
                      label="Order Time"
                      name="OrderTime"
                      disabled={true}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>
      </Form>
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
      {type === "info" && (
        <div className="mt-4 border bg-white rounded-[4px]  p-2">
          <OrderInfo
            customerData={customerData?.data}
            paidStatus={paidStatus}
            id={id}
            trigger={refetch}
          />
        </div>
      )}
      <ConfirmModal
        showModal={showCommonModal}
        setShowModal={setShowCommonModal}
        onDeleteConfirm={handleConfirm}
        content="Do you  want to proceed?"
      />
      <ConfirmModal
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        onDeleteConfirm={handleEmail}
        content="Do you want to send Email?"
      />
    </div>
  );
};
