import React, { useState } from "react";
import SicunetSmallIcon from "../../../assets/images/vectors/SicunetSmallIcon";
import PlusIcon from "../../../assets/images/vectors/PlusIcon";
import ArrowLeftIcon from "../../../assets/images/vectors/ArrowLeftIcon";
import dashBgOne from "../../../assets/images/dashBgOne.png";
import dashBgTwo from "../../../assets/images/dashBgTwo.png";
import dashBgThree from "../../../assets/images/dashBgThree.png";

const SideCards = () => {
  const [model, setModel] = useState();
  return (
    <div className="flex flex-row mt-2 rounded-md mb-4">
      <div
        className="w-full rounded-md py-10 px-6 bg-cover bg-center bg-no-repeat h-[250px] mr-3"
        style={{ backgroundImage: `url(${dashBgTwo})` }}
      >
        <div className="flex justify-between items-center">
          <SicunetSmallIcon />
          <p className="bg-[#2270B8] text-white text-[10px] px-2 py-1 rounded-md">
            New
          </p>
        </div>
        <p className="text-white text-lg font-medium pt-4">Go Premium</p>
        <p className="text-white text-[10px] py-4">
          Discover features and tools that enhance your experience, making
          access more secure and seamless.
        </p>
        <button className="text-[10px] text-[#2270B8] px-3 py-2 bg-white rounded-lg">
          Get Access
        </button>
      </div>

      <div
        className="w-full rounded-md py-10 mr-3 text-white flex flex-col items-center bg-cover bg-center bg-no-repeat h-[250px]"
        style={{ backgroundImage: `url(${dashBgOne})` }}
      >
        <p className={`mb-8 text-2xl ${model ? "hidden" : ""}`}>Our Model's</p>
        <div className={`flex flex-col space-y-5 ${model ? "hidden" : ""}`}>
          <div
            className="flex items-center cursor-pointer"
            onClick={() => setModel("Advantage")}
          >
            <p className="mr-1">Advantage</p>
            <PlusIcon />
          </div>

          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setModel("Professional")}
          >
            <p className="mr-3">Professional</p>
            <p className="bg-white text-[#2270B8] text-[8px] px-2 py-1 rounded-md">
              Pro
            </p>
          </div>
          <p className="cursor-pointer" onClick={() => setModel("Corporate")}>
            Corporate
          </p>
        </div>
        <div
          className={`flex flex-col items-center text-center px-4 ${
            model ? "" : "hidden"
          }`}
        >
          <p className="mb-12 text-2xl">{model}</p>
          <p className="mb-8">
            Discover features and tools that enhance your experience, making
            access more secure and seamless.
          </p>
          <ArrowLeftIcon
            className="cursor-pointer"
            onClick={() => setModel(null)}
          />
        </div>
      </div>

      <div
        className="w-full rounded-md py-10 text-white flex flex-col items-center bg-cover bg-center bg-no-repeat h-[250px]"
        style={{ backgroundImage: `url(${dashBgThree})` }}
      >
        <p className="mb-6 text-xl">Our Package's</p>
        <div className="flex flex-col space-y-2">
          <div className="flex justify-between items-center">
            <p className="mr-3">Basic</p>
            <p className="bg-[#2270B8] text-white text-[8px] px-2 py-1 rounded-md">
              New
            </p>
          </div>

          <p>Standard</p>
          <p>Premuim</p>
          <p>Light</p>
        </div>
      </div>
    </div>
  );
};

export default SideCards;
