// import { useEffect, useState } from "react";
// import axios from "axios";

// const useOemData = () => {
//   const [oemNo, setOemNo] = useState(0);
//   const [oemData, setOemData] = useState();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const getOemInfoUrl = `${process.env.REACT_APP_API_URL}/api/oem/OemNo`;
//   const domain = window.location.hostname;

//   // Fetch OEM Info Data using axios
//   useEffect(() => {
//     const fetchOemInfoData = async () => {
//       try {
//         setLoading(true);
//         // const token = JSON.parse(sessionStorage.getItem("userData"));
//         // const { access_token } = token;
//         const response = await axios.post(
//           getOemInfoUrl,
//           { domain },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               // Authorization: `Bearer ${access_token}`,
//             },
//           }
//         );
//         const oemInfoData = response.data;
//         if (oemInfoData?.OemNo) {
//           setOemNo(oemInfoData.OemNo);
//         }
//       } catch (err) {
//         console.error("Error fetching OEM Info Data:", err);
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOemInfoData();
//   }, []);

//   // Fetch OEM Data based on the fetched OEM No
//   useEffect(() => {
//     const fetchOemData = () => {
//       if (oemNo) {
//         try {
//           const data = require(`../../oem/${oemNo}/data.json`);
//           setOemData(data);
//         } catch (error) {
//           console.error("Error fetching OEM data:", error);
//           setOemData({
//             name: "Generic",
//             description: "Generic",
//             color: "#b90704",
//           });
//         }
//       }
//     };

//     fetchOemData();
//   }, [oemNo, loading]);

//   return { oemNo, oemData, loading };
// };

// export default useOemData;

//Optimized hook to prevent multiple calls if it breaks use the above function
import { useEffect, useState, useRef } from "react";
import axios from "axios";

const useOemData = () => {
  const [oemNo, setOemNo] = useState(0);
  const [oemData, setOemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOemInfo = useRef(false);
  const hasFetchedOemData = useRef(false);

  const getOemInfoUrl = `${process.env.REACT_APP_API_URL}/api/oem/OemNo`;
  const domain = window.location.hostname;

  // Fetch OEM Info Data using axios
  useEffect(() => {
    if (hasFetchedOemInfo.current) return; // Prevent multiple calls
    hasFetchedOemInfo.current = true; // Mark as fetched

    const fetchOemInfoData = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          getOemInfoUrl,
          { domain },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        const oemInfoData = response.data;
        if (oemInfoData?.OemNo) {
          setOemNo(oemInfoData.OemNo);
        }
      } catch (err) {
        console.error("Error fetching OEM Info Data:", err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOemInfoData();
  }, []);

  // Fetch OEM Data based on the fetched OEM No
  useEffect(() => {
    if (!oemNo || hasFetchedOemData.current) return; // Prevent multiple calls
    hasFetchedOemData.current = true; // Mark as fetched

    const fetchOemData = () => {
      try {
        const data = require(`../../oem/${oemNo}/data.json`);
        setOemData(data);
      } catch (error) {
        console.error("Error fetching OEM data:", error);
        setOemData({
          name: "Generic",
          description: "Generic",
          color: "#b90704",
        });
      }
    };

    fetchOemData();
  }, [oemNo]);

  return { oemNo, oemData, loading, error };
};

export default useOemData;
