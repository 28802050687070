import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const LicenseColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  return [
    {
      title: "No",
      dataIndex: "OptionNo",
      key: "OptionNo",
      sorter: (a, b) => a?.OptionNo - b?.OptionNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Name",
      dataIndex: "OptionName",
      key: "OptionName",
      sorter: (a, b) => a?.OptionName?.length - b?.OptionName?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionName"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionName",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Description",
      dataIndex: "OptionDesc",
      key: "OptionDesc",
      sorter: (a, b) => a?.OptionDesc?.length - b?.OptionDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OptionDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OptionDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",
      render: (text, record, rowIndex) => hoveredRow === rowIndex ? (
        <div className="absolute top-2 right-5">
        <div className=" duration-75 bottom-0 flex gap-2 items-center justify-center z-50">
          <div className="hover:bg-gray-400 px-[5px] py-1 rounded-[4px] mr-1 ">
              <Link
                to={`/licenseoption/edit/${record?.OptionNo}`}
                onClick={(e) => e.stopPropagation()}
                className=" hover:text-[#636262ee]"
              >
                <EditOutlined />
              </Link>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
                setSelectedRowKeys([record?.OptionNo]);
              }}
              className="cursor-pointer hover:bg-gray-400 px-1 py-1 rounded-[4px] "
            >
              <DeleteOutlined />
            </div>
          </div>
        </div>
      ) : null,
    },
  ];
};
