import React, { useState } from "react";
import { Modal, Button } from "antd";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";

const CommonModal = ({
  title,
  icon,
  content,
  isVisible,
  onClose,
  onConfirm,
  width
}) => {
  console.log(icon);

  return (
    <Modal
      title={
        <div className="flex items-center bg-gray-200 p-2.5 rounded-md">
          {icon &&
            React.createElement(icon, { className: "text-[16px] text-black" })}
          <span className="text-[.910rem] font-['Poppins'] text-black  ml-2">
            {title}
          </span>
        </div>
      }
      visible={isVisible}
      onCancel={onClose}
      onOk={onConfirm}
      closable={false}
      footer={[
        <div className="flex justify-center mt-4 gap-4">
          {" "}
          {/* Center align */}
          <Button
            key="submit"
            type="primary"
            className="mr-2 w-[104px] h-[30px] font-medium"
            onClick={onConfirm}
          >
            <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
            Apply
          </Button>
          <Button onClick={onClose} className="reset-button">
            <HiArrowPath className="!text-[14px] font-medium" />
            Cancel
          </Button>
        </div>,
      ]}
      width={width}
      centered
    >
      {content}
    </Modal>
  );
};

export default CommonModal;
