import { Navigate, useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import DeviceIcon from "../../assets/images/vectors/SideBarIcons/DeviceIcon";
import DeviceLogIcon from "../../assets/images/vectors/SideBarIcons/DeviceLogIcon";
import OrderIcon from "../../assets/images/vectors/SideBarIcons/OrderIcon";
import OrderItemIcon from "../../assets/images/vectors/SideBarIcons/OrderItemIcon";
import LicenseIcon from "../../assets/images/vectors/SideBarIcons/LicenseIcon";
import LicenseOpIcon from "../../assets/images/vectors/SideBarIcons/LicenseOpIcon";
import AccountIcon from "../../assets/images/vectors/SideBarIcons/AccountIcon";
import DealerIcon from "../../assets/images/vectors/SideBarIcons/DealerIcon";
import OemIcon from "../../assets/images/vectors/SideBarIcons/OemIcon";
import OemInfoIcon from "../../assets/images/vectors/SideBarIcons/OemInfoIcon";
import PaymentIcon from "../../assets/images/vectors/SideBarIcons/PaymentIcon";
import CustomerIcon from "../../assets/images/vectors/SideBarIcons/CustomerIcon";
import { allProtectedRouteList } from "../constants/protectedRoute";

//SideBar items for System Admin & System Manager role
const systemAdminSiderbarItems = [
  {
    label: "Device",
    key: "/devicelist",
    icon: <DeviceIcon />,
  },
  {
    label: "Device Log",
    key: "/deviceloglist",
    icon: <DeviceLogIcon />,
  },
  {
    label: "Order",
    key: "/orderlist",
    icon: <OrderIcon />,
  },
  {
    label: "Order Item",
    key: "/orderitemlist",
    icon: <OrderItemIcon />,
  },
  {
    label: "Invoice",
    key: "/paymentlist",
    icon: <PaymentIcon />,
  },
  {
    label: "License",
    key: "/licenselist",
    icon: <LicenseIcon />,
  },
  {
    label: "License Option",
    key: "/licenseoptionlist",
    icon: <LicenseOpIcon />,
  },
  {
    label: "Account",
    key: "/accountlist",
    icon: <AccountIcon />,
  },
  {
    label: "Customer",
    key: "/customerlist",
    icon: <CustomerIcon />,
  },
  {
    label: "Dealer",
    key: "/dealerlist",
    icon: <DealerIcon />,
  },
  {
    label: "OEM",
    key: "/oemlist",
    icon: <OemIcon />,
  },
  {
    label: "OEM Info",
    key: "/oeminfolist",
    icon: <OemInfoIcon />,
  },
  //   {
  //     label: "Users",
  //     key: "/users",
  //     icon: <TeamOutlined />,
  //     children: [
  //       {
  //         label: "Employees",
  //         key: "/employee",
  //         icon: <UserOutlined />,
  //       },
  //     ],
  //   },
];
//SideBar items for System User role
const systemUserSidebarItems = systemAdminSiderbarItems?.filter(
  (item) =>
    !["Account", "Customer", "Dealer", "OEM", "OEM Info"].includes(item?.label)
);
//SideBar items for OEM Admin & OEM Manager role
const oemAdminSidebarItems = systemAdminSiderbarItems?.filter(
  (item) =>
    ![
      "Device",
      "Device Log",
      "License",
      "License Option",
      "OEM",
      "OEM Info",
    ].includes(item?.label)
);
//SideBar items for OEM User role
const oemUserSidebarItems = systemAdminSiderbarItems?.filter((item) =>
  ["Order", "Order Item", "Payment"].includes(item?.label)
);
//SideBar items for Dealer Admin & Dealer Manager role
const dealerAdminSidebarItems = systemAdminSiderbarItems?.filter((item) =>
  ["Order", "Order Item", "Payment", "Account", "Customer"].includes(
    item?.label
  )
);
//SideBar items for Dealer User role
const dealerUserSidebarItems = systemAdminSiderbarItems?.filter((item) =>
  ["Order", "Order Item", "Payment"].includes(item?.label)
);
//SideBar items for Customer Admin & Customer Manager role
const customerAdminSidebarItems = systemAdminSiderbarItems?.filter((item) =>
  ["Order Item", "Account"].includes(item?.label)
);
//SideBar items for Customer User role
const customerUserSidebarItems = systemAdminSiderbarItems?.filter((item) =>
  ["Order Item"].includes(item?.label)
);

//Select Sidebar based on role
const roleSidebarMenu = {
  1: systemAdminSiderbarItems,
  2: systemAdminSiderbarItems,
  3: systemUserSidebarItems,
  4: oemAdminSidebarItems,
  5: oemAdminSidebarItems,
  6: oemUserSidebarItems,
  7: dealerAdminSidebarItems,
  8: dealerAdminSidebarItems,
  9: dealerUserSidebarItems,
  10: customerAdminSidebarItems,
  11: customerAdminSidebarItems,
  12: customerUserSidebarItems,
};

export const PrivateRoute = ({ children }) => {
  // const user = useAuth();
  const user = JSON.parse(sessionStorage.getItem("userData"));
  // console.log(user);
  const { pathname } = useLocation();

  // check current route is permitted or not
  const isPermittedRoute = allProtectedRouteList[user?.role_value]?.some(
    (item) => pathname?.includes(item)
  );

  // console.log({ user, isPermittedRoute });

  return user && isPermittedRoute ? (
    <SideBar items={roleSidebarMenu[user?.role_value]}>{children} </SideBar>
  ) : (
    <Navigate to={"/"} />
  );
};
