import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Spin } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../utils/toast";
import AuthLayout from "./AuthLayout";
// import "../styles/login.css";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import usePostLogin from "../hooks/usePostLogin";
import UserIcon from "../../assets/images/vectors/UserIcon";
import KeyIcon from "../../assets/images/vectors/KeyIcon";
import { LoadingOutlined } from "@ant-design/icons";
import LoginProfileIcon from "../../assets/images/vectors/LoginProfileIcon";
import PasswordIcon from "../../assets/images/vectors/PasswordIcon";
import { passwordValidator } from "../utils/passwordValidator";

const Signup = ({ setRegisterModal, token }) => {
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/signup`;
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  localStorage.setItem("oemNo", oemNo);
  oemData && localStorage.setItem("oemData", JSON.stringify(oemData));
  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const modValues = {
        UserId: values.UserId,
        password: values.password,
        token,
      };
      await postAction(modValues);
    } catch (error) {
      openErrorNotification(
        "Login failed. Please check your credentials and try again."
      );
    }
  };

  useEffect(() => {
    if (postData) {
      openConfirmNotification("Success");
      setRegisterModal(false);
    }
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [postData, postError]);

  return (
    <>
      <div className="flex justify-center py-1 items-center w-full bg-white ">
        <div className="w-full  px-2 py-4">
          {/* Header Section */}
          <div className="mb-6 py-1 text-center">
            <h3 className="text-2xl font-semiboldtext-black font-[Poppins] mb-2">
              Account Register
            </h3>
            <h3 className="text-sm text-black w-full font-[Poppins]">
              Enter the Username and Password to complete Account Register.
              {/* <span>
              Complete your account setup by setting
              <br /> your username and password.
            </span>
            <br />
            <span>Fill out the form below to finalize your registration.</span> */}
            </h3>
          </div>
          <Form
            autoComplete="off"
            requiredMark={false}
            layout="vertical"
            onFinish={onSubmitHandler}
          >
            <div className="flex flex-col justify-center px-6 gap-y-3">
              <Form.Item
                name="UserId"
                className="my-input"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username!",
                  },
                ]}
              >
                <Input
                  className="h-[40px] !bg-[#EEEEEF] "
                  placeholder="Username"
                  prefix={<LoginProfileIcon className="mr-2" />}
                />
              </Form.Item>

              <Form.Item
                name="password"
                className="my-input"
                rules={[
                  // { required: true, message: "Password is required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject("Password is required");
                      }
                      if (!passwordValidator(value)) {
                        return Promise.reject(
                          <div className=" max-w-[292.92px]">
                            Password must be at least 8 characters, include an
                            alphabet, a number and a special character
                          </div>
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="h-[40px] !bg-[#EEEEEF]"
                  placeholder="Password"
                  autoComplete="new-password"
                  prefix={<PasswordIcon className="mr-2" />}
                />
              </Form.Item>

              <Form.Item
                name="ConfirmPassword"
                className="my-input"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="h-[40px] !bg-[#EEEEEF] "
                  placeholder=" Confirm password"
                  autoComplete="new-password"
                  prefix={<PasswordIcon className="mr-1" />}
                />
              </Form.Item>
            </div>

            <Form.Item className="px-0.5">
              <div className="flex justify-between items-center gap-3 px-[24px] mt-3 ">
                <Button
                  htmlType="submit"
                  className="flex-1 h-[35px] text-[14px] !font-medium font-medium bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[14px] !rounded !py-4 h-[35px] "
                >
                  {postLoading ? (
                    <span>
                      <Spin
                        indicator={<LoadingOutlined spin />}
                        size="default"
                        className="mr-2"
                      />
                      Apply
                    </span>
                  ) : (
                    "Apply"
                  )}
                </Button>

                <Button
                  className="flex-1 h-[35px] text-[14px] bg-[#E9E9E9] text-customColor hover:!bg-customColor hover:!text-white border-none font-medium text-[14px] !rounded !py-4 h-[35px]"
                  onClick={() => setRegisterModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Signup;
