import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SortIcon from "../../../global/components/SortIcon";

export const AccountColumns = (
  hoveredRow,
  setShowModal,
  setSelectedRowKeys,
  setParams,
  params
) => {
  const logData = JSON.parse(sessionStorage.getItem("userData"));
  return [
    {
      title: "No",
      dataIndex: "AccountNo",
      key: "AccountNo",
      sorter: (a, b) => a?.AccountNo - b?.AccountNo,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Username",
      dataIndex: "UserId",
      key: "UserId",
      sorter: (a, b) => a?.UserId - b?.UserId,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="UserId"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "UserId",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },

    {
      title: "Description",
      dataIndex: "AccountDesc",
      key: "AccountDesc",
      sorter: (a, b) => a?.AccountDesc?.length - b?.AccountDesc?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountDesc"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountDesc",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      sorter: (a, b) => a?.Email?.length - b?.Email?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="Email"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "Email",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Account Type",
      dataIndex: "AccountType",
      key: "AccountType",
      sorter: (a, b) => a?.AccountType?.length - b?.AccountType?.length,
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="AccountType"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "AccountType",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "OEM",
      dataIndex: "OemNo",
      hidden: [4, 5, 7, 8, 10, 11].includes(logData.role_value),
      key: "OemNo",
      sorter: (a, b) => a?.OemNo?.length - b?.OemNo?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="OemNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "OemNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Dealer",
      dataIndex: "DealerNo",
      hidden: [7, 8, 10, 11].includes(logData.role_value),
      key: "DealerNo",
      sorter: (a, b) => a?.DealerNo?.length - b?.DealerNo?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="DealerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "DealerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "Customer",
      dataIndex: "CustomerNo",
      hidden: [10, 11].includes(logData.role_value),
      key: "CustomerNo",
      sorter: (a, b) => a?.CustomerNo?.length - b?.CustomerNo?.length,
      render: (text) => {
        return text ? text : "N/A";
      },
      sortIcon: () => (
        <SortIcon
          sortOrder={params.order}
          columnKey="CustomerNo"
          sortBy={params.sort_by}
        />
      ),
      onHeaderCell: () => ({
        onClick: () =>
          setParams((prev) => ({
            ...prev,
            sort_by: "CustomerNo",
            order: prev.order === "desc" ? "asc" : "desc",
          })),
      }),
    },
    {
      title: "",
      fixed: "right",
      width: 0,
      align: "center",

      render: (text, record, rowIndex) =>
        hoveredRow === rowIndex ? (
          <div className="absolute top-2 right-5">
            <div className=" duration-75 bottom-0 flex gap-2 items-center justify-center z-50">
              <div className="hover:bg-gray-400 px-[5px] py-1 rounded-[4px] mr-1 ">
                <Link
                  to={`/account/edit/${record?.AccountNo}`}
                  onClick={(e) => e.stopPropagation()}
                  className=" hover:text-[#636262ee]"
                >
                  <EditOutlined />
                </Link>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                  setSelectedRowKeys([record?.AccountNo]);
                }}
                className="cursor-pointer hover:bg-gray-400 px-1 py-1 rounded-[4px] "
              >
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ) : null,
    },
  ];
};
