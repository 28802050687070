import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "antd";
import useGet from "../../../global/hooks/useGet";
import usePost from "../../../global/hooks/usePost";
import usePut from "../../../global/hooks/usePut";
import CustomInput from "../../../global/components/inputWidgets/CustomInput";
import CustomSelect from "../../../global/components/inputWidgets/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import CommonButton from "../../../global/components/CommonButton";
import { getModifiedResponse } from "../../../global/utils/responseModifier";
import {
  CheckCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import useDelete from "../../../global/hooks/useDelete";
import ConfirmModal from "../../../global/components/ConfirmModal";
import AccountIcon from "../../../assets/images/vectors/SideBarIcons/AccountIcon";

export const AccountForm = ({ type }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
  const [selectedOemNo, setSelectedOemNo] = useState(loggedInUser?.oem_no);
  const [showModal, setShowModal] = useState(false);
  const [accountType, setAccountType] = useState(loggedInUser?.role_value);
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const url = id && `${process.env.REACT_APP_API_URL}/api/account/${id}`;
  const dealerOptionsUrl =
    selectedOemNo &&
    `${process.env.REACT_APP_API_URL}/api/getDealersByOem/${selectedOemNo}`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/account/create`;
  const putUrl = `${process.env.REACT_APP_API_URL}/api/account/update/${id}`;
  const deleteUrl = `${process.env.REACT_APP_API_URL}/api/account/delete/${id}`;
  const optionUrl = `${process.env.REACT_APP_API_URL}/api/account/getoptions`;
  const dealerNo = Form.useWatch("DealerNo", form);
  const getCustomersUrl =
    dealerNo &&
    `${process.env.REACT_APP_API_URL}/api/getCustomersByDealer/${dealerNo}`;
  const {
    data: optionsData,
    loading: optionDataLoading,
    error: optionDataError,
  } = useGet(optionUrl);
  const { data: customerData } = useGet(getCustomersUrl);
  const modifiedType = getModifiedResponse(optionsData, "roles");
  const modifiedDealer = getModifiedResponse(optionsData, "dealers");
  const modifiedCustomer = getModifiedResponse(optionsData, "customers");
  const modifiedOems = getModifiedResponse(optionsData, "oems");

  const { data, loading, error, refetch } = useGet(url);
  const {
    data: dealerOp,
    loading: delaerOpLoading,
    error: dealerOpError,
    refetch: delaerOpRefetch,
  } = useGet(dealerOptionsUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePost(postUrl);

  const {
    data: putData,
    loading: putLoading,
    error: putError,
    putData: putAction,
  } = usePut(putUrl);
  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
  } = useDelete(deleteUrl);

  const onFinish = (values) => {
    try {
      if (id) {
        if (loggedInUser.role_value === 10 || loggedInUser.role_value === 11) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
            CustomerNo: loggedInUser.customer_no,
          };
          putAction(modifiedValues);
        } else if (
          loggedInUser.role_value === 7 ||
          loggedInUser.role_value === 8
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
          };
          putAction(modifiedValues);
        } else if (
          loggedInUser.role_value === 4 ||
          loggedInUser.role_value === 5
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          putAction(modifiedValues);
        } else {
          putAction(values);
        }
      } else {
        if (loggedInUser.role_value === 10 || loggedInUser.role_value === 11) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
            CustomerNo: loggedInUser.customer_no,
          };
          postAction(modifiedValues);
        } else if (
          loggedInUser.role_value === 7 ||
          loggedInUser.role_value === 8
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
            DealerNo: loggedInUser.dealer_no,
          };
          postAction(modifiedValues);
        } else if (
          loggedInUser.role_value === 4 ||
          loggedInUser.role_value === 5
        ) {
          const modifiedValues = {
            ...values,
            OemNo: loggedInUser.oem_no,
          };
          postAction(modifiedValues);
        } else {
          postAction(values);
        }
      }
    } catch (error) {
      openErrorNotification("Something went wrong");
      console.log(error);
    }
  };

  const onReset = () => {
    // form.resetFields();
    navigate("/accountlist");
  };

  const onDeleteConfirm = async () => {
    try {
      await deleteData();
    } catch (error) {
      openErrorNotification("Failed!");
    }
    setShowModal(false);
  };

  useEffect(() => {
    delaerOpRefetch();
  }, [selectedOemNo]);

  useEffect(() => {
    if (!id) {
      form.setFieldsValue({
        AccountType: modifiedType[0]?.value,
      });
    }
  }, [optionsData]);

  useEffect(() => {
    if (!id || loading) return;
    const getAccountInfoById = () => {
      try {
        if (data?.data) {
          form.setFieldsValue({
            UserId: data?.data?.UserId ? String(data?.data?.UserId) : "",
            AccountName: data.data.AccountName
              ? String(data.data.AccountName)
              : "",
            AccountDesc: data.data.AccountDesc
              ? String(data.data.AccountDesc)
              : "",
            Email: data.data.Email ? String(data.data.Email) : "",
            AccountType: data.data.AccountType ? data.data.AccountType : "",
            OemNo: data.data.OemNo ? data.data.OemNo : "",
            DealerNo: data.data.DealerNo ? data.data.DealerNo : "",
            CustomerNo: data.data.CustomerNo ? data.data.CustomerNo : "",
          });
          setAccountType(data.data.AccountType);
          setSelectedOemNo(data.data.OemNo);
        } else {
          console.warn("No data found for the given ID");
        }
      } catch (error) {
        if (!loading) {
          console.log({ error });
          openErrorNotification("Error fetching data!");
        }
      }
    };

    getAccountInfoById();
  }, [id, data, loading, form]);

  //toat for create
  useEffect(() => {
    if (postError) {
      openErrorNotification(
        postError?.response?.data?.message || "Something went wrong"
      );
    } else if (!postLoading && !postError && postData) {
      openConfirmNotification("Success");

      setTimeout(() => {
        navigate(-1);
      }, 400);
    }
  }, [postError, postData, postLoading]);

  //toat for update
  useEffect(() => {
    if (putError) {
      openErrorNotification(
        putError?.response?.data?.message || "Something went wrong"
      );
    } else if (!putLoading && !putError && putData) {
      openConfirmNotification("Success");

      refetch();
      setTimeout(() => {
        navigate(-1);
      }, 400);
    }
  }, [putData, putLoading, putError]);

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
      navigate("/accountlist");
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [deletedData, deletedDataError]);

  const breadcrumbItems = [
    {
      label: "Account",
      href: "/accountlist",
    },
    {
      label: id ? (type === "info" ? "Information" : "Edit") : "Add",
      href: id
        ? type === "info"
          ? `/account/info/${id}`
          : `/account/edit/${id}`
        : "/account/add",
    },
  ];

  const buttonsData =
    type === "info"
      ? [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`/account/edit/${id}`);
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              setShowModal(true);
            },
          },
          {
            label: "List",
            icon: <UnorderedListOutlined />,
            onClick: () => {
              navigate("/accountlist");
            },
          },
        ]
      : [
          {
            label: "Apply",
            icon: <CheckCircleOutlined />,
            onClick: () => form.submit(),
          },
          {
            label: "Cancel",
            icon: <CloseOutlined />,
            onClick: onReset,
          },
        ];

  return (
    <div>
      {
        <Form
          validateTrigger={"onChange"}
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout={"vertical"}
          autoComplete={"off"}
        >
          <div>
            <CommonBreadcrumb breadcrumbItems={breadcrumbItems}>
              <CommonButton buttonsData={buttonsData} />
            </CommonBreadcrumb>
            {
              <div className="mt-4 border bg-white rounded-[4px] overflow-hidden">
                <div className="flex items-center bg-gray-200 p-2.5">
                  <AccountIcon className="text-[18px] text-black " />
                  <span className="text-[.935rem] text-black font-['Poppins'] ml-2">
                    Account
                  </span>
                </div>
                <div className="px-2 pt-0 pb-3">
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Username"
                        name="UserId"
                        disabled={type === "info"}
                        autoComplete="off"
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Password"
                        name="password"
                        type="password"
                        disabled={type === "info"}
                        autoComplete="new-password"
                        required={!id}
                      />
                    </Col>
                    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Name"
                        name="AccountName"
                        disabled={type === "info"}
                        required
                      />
                    </Col> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Description"
                        name="AccountDesc"
                        disabled={type === "info"}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomInput
                        label="Email"
                        name="Email"
                        type="email"
                        isEmail={true}
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <CustomSelect
                        label="Account Type"
                        name="AccountType"
                        options={modifiedType}
                        placeholder="Please select"
                        onChange={(value) => {
                          setAccountType(value);
                          form.setFieldsValue({
                            OemNo: "",
                            DealerNo: "",
                          });
                        }}
                        disabled={type === "info"}
                        required
                      />
                    </Col>
                    {![1, 2, 3].includes(accountType) &&
                      [1, 2].includes(loggedInUser.role_value) && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="OEM"
                            name="OemNo"
                            options={modifiedOems}
                            placeholder="Please select"
                            onChange={(value) => {
                              setSelectedOemNo(value);
                              form.setFieldsValue({
                                DealerNo: "",
                              });
                            }}
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      )}
                    {[7, 8, 9, 10, 11, 12].includes(accountType) &&
                      [1, 2, 4, 5].includes(loggedInUser.role_value) && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Dealer"
                            name="DealerNo"
                            options={
                              dealerOp?.data?.map((item) => ({
                                label: item.name,
                                value: item.value,
                              })) || []
                            }
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      )}
                    {[10, 11, 12].includes(accountType) &&
                      [1, 2, 6, 7].includes(loggedInUser.role_value) && (
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <CustomSelect
                            label="Customer"
                            name="CustomerNo"
                            options={
                              customerData?.data?.map((item) => ({
                                label: item.name,
                                value: item.value,
                              })) || []
                            }
                            placeholder="Please select"
                            disabled={type === "info"}
                            required
                          />
                        </Col>
                      )}
                  </Row>
                </div>
              </div>
            }
          </div>
        </Form>
      }
      <ConfirmModal
        showModal={showModal}
        setShowModal={setShowModal}
        onDeleteConfirm={onDeleteConfirm}
      />
    </div>
  );
};
