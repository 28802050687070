// import { Form, Input } from "antd";
// import React, { useState } from "react";
// import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

// const CustomInput = ({
//   label,
//   name,
//   type,
//   defaultValue,
//   errorMessage,
//   required,
//   isEmail,
//   disable,
//   onChange,
//   ...rest
// }) => {
//   const [show, setShow] = useState(false);

//   // Custom validation rule for email input
//   const emailValidationRule = isEmail
//     ? {
//         type: "email",
//         message: "Please enter a valid email address",
//       }
//     : {};

//   // Handle onChange for number input to prevent negative values
//   const handleNumberChange = (e) => {
//     const { value } = e.target;
//     // Allow only numbers that are zero or greater
//     if (value === "" || Number(value) >= 0) {
//       onChange?.(e); // Call the passed onChange function if present
//     }
//   };

//   return (
//     <Form.Item
//       label={
//         required ? (
//           <span>
//             {label} <span style={{ color: "red" }}>*</span>
//           </span>
//         ) : (
//           label
//         )
//       }
//       className={"mb-2"}
//       name={name}
//       initialValue={defaultValue}
//       rules={[
//         { required: required, message: `${label} is required` },
//         emailValidationRule,
//         ...(type === "password"
//           ? [
//               {
//                 pattern:
//                   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
//                 message:
//                   "Password must be at least 8 characters, include an alphabet, a number and a special character.",
//               },
//             ]
//           : []),
//       ]}
//       validateStatus={errorMessage ? "error" : undefined}
//       help={errorMessage}
//       labelCol={{ span: 24 }}
//       wrapperCol={{ span: 24 }}
//       required={false}
//     >
//       {type === "password" ? (
//         <Input.Password
//           type={show ? "text" : "password"}
//           autoComplete="off"
//           spellCheck="false"
//           {...rest}
//           iconRender={(visible) =>
//             visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//           }
//         />
//       ) : type === "number" ? (
//         <Input
//           disabled={disable}
//           type={type}
//           defaultValue={defaultValue}
//           onChange={handleNumberChange} // Prevent negative numbers
//           min={0} // Ensure no negative numbers
//           style={{ color: disable ? "#000000" : "initial" }}
//           {...rest}
//         />
//       ) : (
//         <Input
//           disabled={disable}
//           type={type}
//           onChange={onChange}
//           className="rounded-[4px]"
//           style={{ color: disable ? "#000000" : "initial" }}
//           {...rest}
//         />
//       )}
//     </Form.Item>
//   );
// };

// export default CustomInput;
import { Form, Input } from "antd";
import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const CustomInput = ({
  label,
  name,
  type,
  defaultValue,
  errorMessage,
  required,
  isEmail,
  disable,
  rules = [],
  onChange,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const form = Form.useFormInstance(); // Get form instance

  // Handle password confirmation validation
  const handleChange = (e) => {
    const { value } = e.target;

    if (name === "confirmPassword") {
      const newPassword = form.getFieldValue("password");
      if (value && newPassword !== value) {
        form.setFields([
          {
            name: "confirmPassword",
            errors: ["Passwords do not match!"], // Set validation error manually
          },
        ]);
      } else {
        form.setFields([
          {
            name: "confirmPassword",
            errors: [], // Clear errors if passwords match
          },
        ]);
      }
    }

    onChange?.(e); // Call the passed onChange function
  };

  // Custom validation rule for email input
  const emailValidationRule = isEmail
    ? {
        type: "email",
        message: "Please enter a valid email address",
      }
    : null; // Don't include empty objects in rules

  // Default rules (can be overridden by parent)
  const defaultRules = [
    {
      required: required,
      message: `${label} is required`,
    },
    ...(emailValidationRule ? [emailValidationRule] : []),
    ...(name === "password"
      ? [
          {
            pattern:
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            message:
              "Password must be at least 8 characters, include an alphabet, a number, and a special character.",
          },
        ]
      : []),
  ];

  // Handle onChange for number input to prevent negative values
  const handleNumberChange = (e) => {
    const { value } = e.target;
    if (value === "" || Number(value) >= 0) {
      onChange?.(e);
    }
  };

  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={defaultValue}
      rules={rules.length ? rules : defaultRules} // Use custom rules if provided
      validateStatus={errorMessage ? "error" : undefined}
      help={errorMessage}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      {type === "password" ? (
        <Input.Password
          type={show ? "text" : "password"}
          autoComplete="off"
          spellCheck="false"
          onChange={handleChange}
          iconRender={(visible) =>
            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
          }
          {...rest}
        />
      ) : type === "number" ? (
        <Input
          disabled={disable}
          type={type}
          defaultValue={defaultValue}
          onChange={handleNumberChange}
          min={0}
          style={{ color: disable ? "#000000" : "initial" }}
          {...rest}
        />
      ) : (
        <Input
          disabled={disable}
          type={type}
          onChange={onChange}
          className="rounded-[4px]"
          style={{ color: disable ? "#000000" : "initial" }}
          {...rest}
        />
      )}
    </Form.Item>
  );
};

export default CustomInput;
