import { useEffect, useState } from "react";
import { GlobalTable } from "../../../global/components/GlobalTable";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import { Spinner } from "../../../global/components/Spinner";
import useGet from "../../../global/hooks/useGet";
import { OemInfoColumns } from "./Columns";
import ConfirmModal from "../../../global/components/ConfirmModal";
import useDelete from "../../../global/hooks/useDelete";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../../../global/utils/toast";
import CommonBreadcrumb from "../../../global/components/CommonBreadcrumb";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiArrowPath } from "react-icons/hi2";

const initialFilterState = { OemInfoNo: "", OemInfoName: "" };

export const OemInfo = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filter, setFilter] = useState(initialFilterState);
  const [filterNoValueText, setFilterNoValueText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    sort_by: "OemInfoNo",
    order: "desc",
  });
  const [pageA, setPageA] = useState();
  const [pageSizeA, setPageSizeA] = useState(8);

  const navigate = useNavigate();

  const breadcrumbItems = [
    {
      label: "OEM Info",
      href: "/oeminfolist",
    },
  ];

  // to get table data from API server
  const query = new URLSearchParams(params).toString();
  const url = query
    ? `${process.env.REACT_APP_API_URL}/api/oeminfo/all?${query}`
    : `${process.env.REACT_APP_API_URL}/api/oeminfo/all`;
  const { data, loading, error, refetch } = useGet(url);

  let deleteUrl;
  selectedRowKeys.length > 1
    ? (deleteUrl = `${process.env.REACT_APP_API_URL}/api/oeminfos/delete`)
    : (deleteUrl = `${process.env.REACT_APP_API_URL}/api/oeminfo/delete/${selectedRowKeys}`);

  const {
    data: deletedData,
    loading: deletedDataLoading,
    error: deletedDataError,
    deleteData,
    deleteMultiple,
  } = useDelete(deleteUrl);

  const paginationConfig = {
    current: pagination.current, //current page
    pageSize: pagination.pageSize, // items per page
    total: data?.count, // total items
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50],
    showTotal: (total, range) => `Total ${total} items ${range[0]}-${range[1]}`,
    onChange: (page, pageSize) => {
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
      });

      const updatedParams = {
        ...params,
        limit: pageSize,
        page: page,
      };
      setParams(updatedParams);
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // navigate(`/oemlist/${newSelectedRowKeys}`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    fixed: true,
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        navigate(`/oeminfo/info/${record?.OemInfoNo}`);
      },
    };
  };

  const handleFilterChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setFilter({ ...filter, [inputName]: inputValue });
  };

  const handleFilter = (e) => {
    const filterDict = {};
    for (const key in filter) {
      if (
        filter[key] !== null &&
        filter[key] !== "" &&
        filter[key] !== undefined
      ) {
        filterDict[key] = filter[key];
      }
    }
    const isEmpty = JSON.stringify(filterDict) === "{}";

    if (isEmpty) {
      setFilterNoValueText("No value exists!");
    } else {
      const params = {
        limit: 10,
        sort_by: "OemInfoNo",
        order: "desc",
        ...filterDict,
      };
      setParams(params);
    }
  };

  // reset
  const handleReset = (e) => {
    setFilter(initialFilterState);

    const params = {
      limit: 10,
      order: "desc",
    };
    setParams(params);
  };

  const onDeleteConfirm = async () => {
    try {
      if (selectedRowKeys.length === 1) {
        await deleteData();
      } else {
        await deleteMultiple({ ids: selectedRowKeys });
      }
    } catch (error) {
      openErrorNotification("Something went wrong.");
    }
    setShowModal(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (deletedData && !deletedDataError) {
      openConfirmNotification("Success");
      refetch();
    } else if (deletedDataError) {
      openErrorNotification(
        deletedDataError?.response?.data?.message || "Something went wrong"
      );
    }
  }, [deletedData, deletedDataError]);

  useEffect(() => {
    if (data && !loading) {
      const newData = data?.data?.map((el) => ({
        ...el,
        key: el?.OemInfoNo,
      }));
      setListData(newData);
    }
  }, [data, loading]);

  return (
    <div>
      <CommonBreadcrumb breadcrumbItems={breadcrumbItems} />
      <div className="mt-4 p-4 bg-white rounded-[4px] border border-gray-200">
        <div className="mb-4">
          <div className="mt-2">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  name="OemInfoNo"
                  placeholder="No"
                  value={filter.OemInfoNo}
                  onChange={handleFilterChange}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                <Input
                  className="input_text_3 mr10"
                  placeholder="Name"
                  value={filter.OemInfoName}
                  onChange={handleFilterChange}
                  name="OemInfoName"
                />
              </Col>
            </Row>
          </div>
          <div className="w-full overflow-x-auto">
            <div className="flex justify-between mt-4 flex-nowrap min-w-[610px]">
              <div>
                <Button
                  type="primary"
                  className="mr-2 w-[104px] h-[30px] font-medium"
                  onClick={handleFilter}
                >
                  <IoIosCheckmarkCircleOutline className="!text-[14px] font-medium" />
                  Apply
                </Button>
                <Button className="reset-button" onClick={handleReset}>
                  <HiArrowPath className="!text-[14px] font-medium" />
                  Reset
                </Button>
              </div>
              <div>
                <Button
                  type="primary"
                  className="mr-2 w-[32px] h-[32px]"
                  onClick={() => navigate("/oeminfo/add")}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  type="primary"
                  disabled={selectedRowKeys?.length === 0 ? true : false}
                  className="w-[32px] h-[32px]"
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          onDeleteConfirm={onDeleteConfirm}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        <GlobalTable
          columns={OemInfoColumns(
            hoveredRow,
            setShowModal,
            setSelectedRowKeys,
            setParams,
            params
          )}
          dataSource={listData}
          rowSelection={rowSelection}
          pagination={paginationConfig}
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: () => setHoveredRow(rowIndex),
              onMouseLeave: () => setHoveredRow(null),
              ...onRow?.(record, rowIndex),
            };
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};
