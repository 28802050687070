import React from "react";
const SentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "white"}
      d="M.344 12V7.344l10-1.344-10-1.344V0l14 6-14 6Z"
    />
  </svg>
);
export default SentIcon;
