import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Typography, Spin } from "antd";
import { faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import {
  openConfirmNotification,
  openErrorNotification,
  showToast,
} from "../utils/toast";
import AuthLayout from "./AuthLayout";
import usePostLogin from "../hooks/usePostLogin";
import useOemData from "../hooks/useOemData";
import { Spinner } from "../components/Spinner";
import KeyIcon from "../../assets/images/vectors/KeyIcon";
import EmailIcon from "../../assets/images/vectors/EmailIcon";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";

export const CustomerLogin = () => {
  const [isFocused, setIsFocused] = useState(false);
  const { oemNo, oemData, loading: oemDataLoading } = useOemData();
  const location = useLocation();
  // Extract query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const mac = queryParams.get("mac");
  const product = queryParams.get("product");
  const [loading, setLoading] = useState(false); // Track loader

  const [form] = Form.useForm();
  const securityCodeUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuscode/`;
  const postUrl = `${process.env.REACT_APP_API_URL}/api/auth/cuslogin/`;
  const {
    data: codeData,
    loading: codeLoading,
    error: codeError,
    postData: sendCode,
  } = usePostLogin(securityCodeUrl);

  const {
    data: postData,
    loading: postLoading,
    error: postError,
    postData: postAction,
  } = usePostLogin(postUrl);

  const navigate = useNavigate();

  // Handle requesting the security code
  // Handle requesting the security code

  const handleRequestCode = async () => {
    const email = form.getFieldValue("email");

    if (!email) {
      openErrorNotification("Enter your email before requesting the code!");
      return;
    }

    setLoading(true); // Start loader

    try {
      await sendCode({ email }); // Send request
    } catch (error) {
      openErrorNotification(
        error?.response?.data?.message || "Something went wrong"
      );
    } finally {
      setLoading(false); // Stop loader
    }
  };

  //toast for send code
  useEffect(() => {
    if (codeError) {
      openErrorNotification(
        codeError?.response?.data?.message || "Something went wrong"
      );
    } else if (!codeLoading && !codeError && codeData) {
      openConfirmNotification("Success");
    }
  }, [codeError, codeData, codeLoading]);

  useEffect(() => {
    if (postData) {
      sessionStorage.setItem("userData", JSON.stringify(postData));
      openConfirmNotification("Success");
      if (location.pathname.includes("upgrade")) {
        navigate("/setUpgrade");
      } else {
        navigate("/setInitial");
      }
    }
    if (postError) {
      openErrorNotification(" Email or  security code is incorrect.");
    }
  }, [postData, postError]);

  // on submit handler.
  const onSubmitHandler = async (values) => {
    try {
      const customerInfo = {
        email: values.email,
        mac,
        product,
      };
      sessionStorage.setItem("customerInfo", JSON.stringify(customerInfo));
      await postAction(values);
    } catch (error) {
      openErrorNotification(
        "failed to login. Please check your email and try again.",
        faWarning
      );
    }
  };

  return (
    <AuthLayout>
      <div className="rounded rounded-lg  py-12 px-8 flex flex-col items-center !bg-white">
        <div className="w-full max-w-md flex justify-center ">
          <img
            // src={`oem/${oemNo}/images/mainLogo.png`}
            // src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
            src={require(`../../oem/${oemNo}/images/mainLogo.png`)}
            alt={oemData?.name}
            className="w-[200px]  mx-auto"
          />
        </div>

        <p className="my-3 text-sm text-center ">
          Please enter the email associated <br /> with your account.
        </p>
        <Form
          form={form}
          layout={"vertical"}
          size={"middle"}
          autoComplete={"off"}
          validateTrigger={"onChange"}
          requiredMark={false}
          onFinish={onSubmitHandler}
        >
          <div className="flex flex-col gap-y-3">
            <Form.Item
              // label="Email"
              name="email"
              className="my-input"
              rules={[
                {
                  required: true,
                  message: " Email is required",
                },
                {
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input
                className={`h-[40px] !bg-white`}
                placeholder="Email"
                prefix={<EmailIcon />}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{ width: "292.92px" }}
              />
            </Form.Item>
            {/* <p
              className={`absolute top-10 right-2 text-xs text-customColor hover:text-black cursor-pointer ${
                isFocused ? "z-10" : "z-10"
              } `}
              onClick={handleRequestCode}
            >
              Send Code
            </p> */}

            <Form.Item
              // label="Security Code"
              name="security_code"
              className="my-input text-sm"
              rules={[
                {
                  required: true,
                  message: "Security Code is required",
                },
              ]}
              //   style={{ marginBottom: "6px" }}
            >
              <div className="relative w-[292.92px]">
                <Input
                  type="password"
                  className="h-[40px] !bg-white pr-16" // Add padding-right for spacing
                  placeholder="Security Code"
                  prefix={<KeyIcon />}
                />
                <span
                  className="absolute right-2 top-1/2 -translate-y-1/2 text-xs font-semibold text-customColor cursor-pointer bg-white px-2 z-10"
                  onClick={handleRequestCode}
                >
                  Send Code
                </span>
              </div>
            </Form.Item>
          </div>
          <Form.Item className="px-[1px] mt-4">
            <Button
              htmlType="submit"
              className="w-full bg-customColor text-white hover:!bg-white border-none hover:!text-customColor font-medium text-[15px] !rounded !py-4 h-[35px] "
            >
              {postLoading ? (
                <span>
                  <Spin
                    indicator={<LoadingOutlined spin />}
                    size="default"
                    className="mr-2"
                  />
                  Login
                </span>
              ) : (
                "Login"
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {loading ? (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex items-center justify-center">
          <div className="bg-white shadow-lg rounded-lg px-4 py-2 flex items-center">
            <Spin indicator={<LoadingOutlined spin />} size="default" />
            <span className="ml-2 text-gray-700 font-['Poppins'] font-medium">
              Loading
            </span>
          </div>
        </div>
      ) : null}
    </AuthLayout>
  );
};
